<template>
  <div
    :class="{'blink': blink}"
    class="text-center whatsapp-note"
  >
    <div
      :class="{ 'gray': gray}"
      class="py-2 px-3"
    >
      <slot />
    </div>
  </div>
</template>
<script>
export default {

  props: {

    gray: {
      type: Boolean,
      default: false,
    },
    blink: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
<style lang="scss" scoped>
.whatsapp-note {
  &.failed {
    opacity: 0.5;
  }

  div {
    background-color: #FFE9A9;

    &.gray {
      background-color: #FFFFFF;
    }

    display: inline-block;
    border-radius: $whatsapp-border-radius;
    font-weight: 200;
    font-size: 13px;
    word-wrap: break-word;
    white-space: pre-line;
    max-width: 100%;
  }
  &.blink {
    animation: blink 2s ease-in-out !important;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
</style>
