<template>
  <div
    class="d-flex align-items-center"
  >
    <div class="card mb-0 card-next-action">
      <div class="position-relative">
        <div class="card-body d-flex align-items-center body-next-action">
          <action-component
            :icon-class="iconClass"
            :name="name"
            icon-color="#3057F2"
          />
          <button
            class="btn btn-md col-auto tx-text-gray"
            @click="deleteAction"
          >
            <span class="fa-regular fa-trash" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import ActionComponent from "../action-component.vue";

export default {
  name: "CardDefaultNextAction",

  components: { ActionComponent},

  props: {
    action: {
      type: Object,
      required: true
    },
  },

  computed: {

    iconClass() {
      switch (this.action.type) {
        case "back" :
          return "fa-regular fa-backward"
        case "restart" :
          return "fa-regular fa-arrow-rotate-left"
        case "finish" :
          return "fa-regular fa-close"
        default :
          return ""
      }
    },

    name() {
      switch (this.action.type) {
        case "back" :
          return this.$t('back_capitalize')
        case "restart" :
          return this.$t('restart_chatbot')
        case "finish" :
          return this.$t('finalize_chatbot')
        default :
          return ""
      }
    }
  },


  methods: {
    ...mapActions("chatbot/actions", ["updateAction"]),
    ...mapMutations("chatbot/actions", ["removeAction"]),

    deleteAction() {
      this.updateAction({
        actionId: this.action.parentId,
        payload: {
          default_next_action: "",
        }
      }).then(() =>{
        this.removeAction(this.action.id)
      })

    }
  }
};
</script>
<style scoped>
.card-next-action{
  min-width: 296px;
}
.body-next-action{
  padding: 1rem;
}
</style>