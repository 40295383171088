export default {
  getErrors: state => state.errors,
  getLoadingFlags: state => state.loadingFlags,
  getLoadPercentage: state => (state.firstMessagesLoad.loaded / state.firstMessagesLoad.total) * 100,
  getFirstMessagesAreLoaded: state => state.firstMessagesAreLoaded,

  getCurrentChat: state => state.currentChat,
  getChatChannelTypeSelected: state => state.chatChannelTypeSelected,

  getFilters: state => state.filters,
  getGroupParticipants: state => state.groupParticipants,
  getOpenChat: state => state.openChat,

  // Queue
  getQueue: state => state.queue,
  getQueuePagination: state => state.queuePagination,
  getQueueParams: state => {
    return Object.assign(
      {
        page: state.queuePagination.current_page,
        per_page: state.queuePagination.per_page
      },
      state.filters
    );
  },

  // In Progress
  getInProgress: state => state.inProgress,
  getInProgressPagination: state => state.inProgressPagination,
  getInProgressParams: state => {
    return Object.assign(
      {
        page: state.inProgressPagination.current_page,
        per_page: state.inProgressPagination.per_page
      },
      state.filters
    );
  },
  getUnreadConversations: state => state.unreadConversations,
  getUnreadGroupsConversations: state => state.unreadGroupsConversations,

  getHasNotifiedInProgress: state => state.hasNotifiedInProgress,
  getHasNotifiedQueue: state => state.hasNotifiedQueue,

  // Snooze
  getInSnooze: state => state.inSnooze,
  getInSnoozePagination: state => state.inSnoozePagination,
  getInSnoozeParams: state => {
    return Object.assign(
      {
        page: state.inSnoozePagination.current_page,
        per_page: state.inSnoozePagination.per_page
      },
      state.filters
    );
  },

  // Groups
  getGroups: state => state.groups,
  getGroupsPagination: state => state.groupsPagination,
  getGroupsParams: state => {
    return Object.assign(
      {
        page: state.groupsPagination.current_page,
        per_page: state.groupsPagination.per_page
      },
      state.filters
    );
  },

  getGroupAgents: state => state.groupAgents,
  getGroupAgentsParams: state => state.filters,

  getFinished: state => state.finished,
  getFinishedPagination: state => state.finishedPagination,
  getFinishedParams: state => {
    return Object.assign(
      {
        page: state.finishedPagination.current_page,
        per_page: state.finishedPagination.per_page
      },
      state.filters
    );
  },

  getInChatbot: state => state.inChatbot,
  getInChatbotPagination: state => state.inChatbotPagination,
  getInChatbotParams: state => {
    return Object.assign(
      {
        page: state.inChatbotPagination.current_page,
        per_page: state.inChatbotPagination.per_page
      },
      state.filters
    );
  },

  //spy
  getTransferedByManager: state => state.transferedByManager,
  getSnoozedByManager: state => state.snoozedByManager,
  getFinalizedByManager: state => state.finalizedByManager,

  getEmptyChatContact: state => state.emptyChatContact,

  getLastVisitedWhatsAppRoute: state => state.lastVisitedWhatsAppRoute,
  getTalksTab: state => state.talksTab,

  getOpenTransferForm: state => state.openTransferForm,

  getMessageFound: state => state.messageFound,
  getSearchedMessages: state => state.searchedMessages,
  getSearchedMessagesPagination: state => state.searchedMessagesPagination,

  getAllProtocols: state => state.allProtocols,
  getProtocol: state => state.protocol,
  getProtocolFound: state => state.protocolFound,
  getLoadMessagesContext: state => state.loadMessagesContext,
  getAllowedChatsToForward: state => state.allowedChatsToForward,
};
