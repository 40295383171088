export default {
  getLoadingFlags: state => state.loadingFlags,
  getLogoutCause: state => state.logoutCause,
  /**
   * Here the negation operator specifies when the external sip
   * is active or not based on the state of web extenstion. If
   * the web extension is set to false means that the sip is active
   * otherwise if the web extension is set to true the external
   * sip is deactivated.
   */
  isExternalSipActive: state => state.user && !state.user.settings?.web_extension,
  getErrorMessage: state => state.errorMessage,
  getErrors: state => state.errors,
  getUser: state => state.user,
  isBrazil: state => state.user?.company?.["company-data"]?.data?.country?.country_code === 55,
  getNextDataUpdate: state => state.nextDataUpdate,
  getIsRecentlyLogged: state => state.isRecentlyLogged,
  isAuthenticated: state => state.user != null && state.user.api_token !== undefined,
  isAgent: state => state.user?.role?.name == "agent",
  isManager: state => state.user?.role?.name == "manager",
  isSupervisor: state => state.user?.role?.name == "supervisor",
  isWhatsappAllowed: state => state.user?.company?.whatsapp_licenses > 0,
  isAdmin: state => state.user?.role?.name == "admin",
  isPartner: state => state.user?.role?.name == "partner",
  isSupervisorOrManager: state => state.user?.role && (state.user.role.name == "supervisor" || state.user.role.name == "manager"),
  isWebphoneAllowed: state => state.user && (state.user.role.name != "admin" && !state.impersonate),
  getImpersonateUserBackup: state => state.impersonateUserBackup,
  inImpersonate: state => state.impersonate,
  getParams: (state) =>
    Object.assign(state.filters, { include: state.includes.join(",") }),
  onlyHaveWhatsapp: state => state.user && state.user.company.whatsapp_licenses > 0 && state.user.company.max_agents_login === 0 && state.user.company.webphone_licenses === 0,
  hasAccessToWhatsapp(state) {
    const { user } = state;
    if (!user) return false;
    const whatsappTeams = user.teams.filter((team) => team.whatsapp)
    return user.teams.length > 0 && whatsappTeams.length > 0 && whatsappTeams.some(team => team.instances.length > 0)
  },
  getUserConnectedInstances: state => {
    const instances = state.user.teams.flatMap(team => team.instances);
    const connectedInstances = instances.filter(instance => instance.status === "connected");
    return connectedInstances;
  },
  getAdminCurrentCompany: (state, getters, rootState, rootGetters) => {
    state.adminCurrentCompany = rootGetters
  },
  getUserLogin: state => state.userLogin,
  hasTwoFa: state => state.twoFa,
  isRingGroupPermissionEnabled: state => !!state.user?.permissions.find((permission) => permission.name === "ring.group.access"),
  showPaymentModal: state => state.showPaymentModal,
  getLanguage: state => state.user?.settings?.language?.substring(0, 2),
}
