<template>
  <div class="main-content background-loading d-flex justify-content-center text-center align-items-center">
    <div class="container-fluid align-middle d-flex justify-content-center text-center align-items-center">
      <div
        class="col-12 d-flex flex-column justify-content-center align-items-center central-div text-center"
      >
        <Vue3Lottie
          :animation-data="this.PlaneJson"
        />
        <div class="loading-text">
          <h1 class="fs-2 fw-semibold">
            {{ $t('loading_chats') }}
          </h1>
          <span class="fs-5 fw-normal">{{ $t('loading_chats_description') }}</span>
          <div
            class="progress w-100 mt-4 bg-gray-blue-400"
            role="progressbar"
            :aria-valuenow="percentage"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar"
              :style="`width: ${percentage}%`"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie'
import PlaneJson from '@/assets/lottie/plane.json'
export default {
  props: {
    percentage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
    PlaneJson,
    }
  },
  components: {
    Vue3Lottie,
  },
}
</script>
<style lang="scss" scoped>
.background-loading {
    background-color: #EAF1FB;
    background-image: url("~@/assets/img/whatsapp-background.svg")  !important;
    background-size: 10%;
    background-repeat: repeat;
    background-blend-mode: color-burn;
    height: 100vh;
}
.loading-text{
  position: relative !important;
  top: -10% !important;
}
</style>
