<template>
  <agent-side-bar v-if="user && isAgent" />
  <agent-top-bar v-if="user && isAgent" />
  <router-view name="base" />
  <show-message />
  <confirmation-modal />
  <too-many-requests429 />
  <nps-toast v-if="user && !isAdmin" />
  <defaulter-client-modal v-if="(isAgent || isSupervisor) && !isAdmin" />
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ShowMessage from "@components/show-message";
import TooManyRequests429 from "@/pages/error/too-many-requests-429.vue";
import AgentSideBar from "@whatsapp/components/agent-side-bar.vue";
import AgentTopBar from "@dialer/agent/components/top-bar/top-bar.vue";
import intercom from "@/mixins/intercom";
import NpsToast from "@/components/nps-toast.vue";
import ConfirmationModal from "./components/confirmation-modal.vue";
import DefaulterClientModal from "@components/defaulter-client-modal.vue";

export default {
  name: "App",

  data() {
    return {
      pinger: null,
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isAuthenticated: "isAuthenticated",
      isAdmin: "isAdmin",
      isAgent: "isAgent",
      isSupervisor: "isSupervisor",
      isManager: "isManager",
    }),
  },

  components: {
    DefaulterClientModal,
    ShowMessage,
    TooManyRequests429,
    NpsToast,
    ConfirmationModal,
    AgentTopBar,
    AgentSideBar,
  },

  mixins: [
    // Contains the intercom function that implements the customer service
    intercom,
  ],

  created() {
    this.fetchUserIp();
  },

  beforeUnmount() {
    clearInterval(this.pinger);
  },

  methods: {
    ...mapActions("crm/funnels", ["fetchFunnels"]),
    ...mapActions("crm/tour", ["fetchTourSteps"]),
    ...mapActions("auth", ["ping", "me"]),
    ...mapActions("system", ["fetchUserIp"]),
  },

  watch: {
    isAuthenticated: {
      handler(val) {
        clearInterval(this.pinger)
        if (val) {
          this.me();
          if (this.isAgent) {
            this.fetchFunnels();
            this.pinger = setInterval(() => {
              this.ping();
            }, 50000);
          } else if (this.isManager) {
            this.fetchTourSteps();
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.modal-backdrop,
.offcanvas-backdrop {
  background-color: rgba(68, 68, 68, 0.4) !important;
}

.modal {
  z-index: 1056 !important;
}

@import "./assets/scss/_variables.scss";

//dashkit vars
$path-to-fonts: "./assets/fonts";
$path-to-img: "./assets/img";

@import "./assets/scss/Dashkit-2.0.0/theme-3c.scss";
@import "./assets/scss/style.scss";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.intercom-lightweight-app {
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}
</style>
