<template>
  <div
    class="form-control p-0-75"
    :class="{
      'form-control-focused': isFocused && !isInvalid,
      'form-control-invalid': isInvalid,
    }"
    @click="$refs.textarea.focus"
  >
    <textarea
      ref="textarea"
      :id="id"
      class="w-100 border-0 textarea-with-count"
      :placeholder="placeholder"
      type="text"
      rows="1"
      :value="modelValue"
      :maxlength="maxLength"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @input="handleInput"
    />
    <p class="text-end mb-0 tx-text-gray text-small">
      {{ modelValue.length }}/{{ maxLength }}
    </p>
  </div>
</template>

<script>
export default {
  name: "TextareaWithCount",

  emits: ["update:modelValue"],

  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      default: 255
    },
    maxHeight: {
      type: Number,
      default: 264
    },
    placeholder: {
      type: String,
      default: "",
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },

  data(){
    return {
      isFocused: false
    }
  },

  mounted() {
    this.$nextTick(()=>{
      this.resizeTextArea()
    })
  },

  methods: {
    handleInput(event) {
      this.$emit('update:modelValue', event.target.value);
      this.resizeTextArea()
    },

    resizeTextArea() {
      const el = this.$refs.textarea;
      el.style.height = 'auto';

      if (el.scrollHeight > this.maxHeight) {
        el.style.height = `${this.maxHeight}px`;
        el.style.overflowY = 'auto';
      } else {
        el.style.height = `${el.scrollHeight}px`;
        el.style.overflowY = 'hidden';
      }
    }
  }
}
</script>

<style scoped lang="scss">
.p-0-75 {
  padding: 0.75rem;
}

.form-control-focused {
  border: 1px solid #3057f2;
}

.form-control-invalid {
  border: 1px solid #F23F2C;
}

.textarea-with-count {
  resize: none;
  border-radius: 0.675rem;
  outline: 0;

  &::placeholder{
    color: #B1C2D9;
  }
}
</style>
