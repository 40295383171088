import dagre from 'dagre-layout'
import { Graph } from 'graphlib'
import { Position, } from '@vue-flow/core'
import { ref } from 'vue'

/**
 * Composable to run the layout algorithm on the graph.
 * It uses the `dagre-layout` library to calculate the layout of the nodes and edges.
 */
export function useLayout() {


    const graph = ref(new Graph())

    const previousDirection = ref('LR')

    function layout(nodes, edges, direction) {

        const dagreGraph = new Graph()

        graph.value = dagreGraph

        dagreGraph.setDefaultEdgeLabel(() => ({}))

        const isHorizontal = direction === 'LR'
        dagreGraph.setGraph({ rankdir: direction })

        previousDirection.value = direction

        for (const node of nodes) {

            const graphNode = node

            dagreGraph.setNode(node.id, { width: graphNode.dimensions.width || 150, height: graphNode.dimensions.height || 50 })
        }

        for (const edge of edges) {
            dagreGraph.setEdge(edge.source, edge.target)
        }

        dagre.layout(dagreGraph)

        return nodes.map((node) => {
            const nodeWithPosition = dagreGraph.node(node.id)


            return {
                ...node,
                targetPosition: isHorizontal ? Position.Left : Position.Top,
                sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
                position: { x: nodeWithPosition.x, y: nodeWithPosition.y },
                computedPosition:{ x: nodeWithPosition.x, y: nodeWithPosition.y }
            }
        })
    }

    return { graph, layout, previousDirection }
}
