<template>
  <div class="d-flex align-items-center">
    <div
      class="card mb-0"
      @click="addOrEdit"
    >
      <div class="card-body p-0">
        <div
          class="d-flex align-items-center card-message-body"
        >
          <action-component
            icon-class="fa-regular fa-message"
            icon-color="#5CB868"
            :name="$t('send_a_message')"
          />
        </div>
        <hr class="w-100">
        <div
          class="d-flex flex-column justify-content-center card-message-text"
        >
          <card-text
            :text="action.text_content"
          />
        </div>
        <div class="d-flex w-100 justify-content-end px-4 tx-text-gray">
          <p
            class="mb-2"
          >
            {{ $t("step_next") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import CardText from "@chatbot/pages/workflow/components/card-text.vue";

export default {
  name: "CardMessage",

  props: {
    action: {
      type: Object,
      required: true
    }
  },

  components: {
    CardText,
    ActionComponent
  },

  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit",
        "setSelectedAction",
        "setPrevActionId",
        "setOffcanvasNewActionIsOpen"
    ]),
    addOrEdit(){
      if(!this.action?.text_content){
        this.addText()
      }else{
        this.editAction()
      }
    },

    editAction() {
      this.setSelectedAction(this.action)
      this.setActionToEdit(this.action)
      this.setOffcanvasEditActionIsOpen(true)
    },
    addText(){
      this.setPrevActionId(this.action.previous_action_id)
      this.setOffcanvasNewActionIsOpen(true)
      this.setSelectedAction({
        id: this.action.id,
        type: "message",
        position: this.action.position
      });
    }
  }
}
</script>
<style scoped>
.card-message-body{
  padding: 1rem 1rem 0 1rem;
  width: 350px
}
.card-message-text{
  padding: 0 1rem 0 1rem;
  width: 350px;
}
</style>