export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setActions: (state, actions) => state.actions = actions,
  setAction: (state, action) => state.action = action,
  setActionToEdit: (state, action) => state.actionToEdit = action,
  setPrevActionId: (state, actionId) => state.prevActionId = actionId,
  setGroupChannels: (state, groupChannels) => state.groupChannels = groupChannels,
  setOffcanvasEditActionIsOpen: (state, isOpen) => state.offcanvasEditActionIsOpen = isOpen,
  setOffcanvasNewActionIsOpen: (state, isOpen) => state.offcanvasNewActionIsOpen = isOpen,
  setOffcanvasSelectChannelIsOpen: (state, isOpen) => state.offcanvasSelectChannelIsOpen = isOpen,
  changeUpdateCoordinates: (state) => state.updateCoordinates = !state.updateCoordinates,
  setZoom: (state, zoom) => state.zoom = zoom,
  setSelectedAction: (state, action) => state.selectedAction = action,
  setNodesPositions: (state, positions) => state.nodesPositions = positions,

  removeAction: (state, actionId) => state.actions = state.actions.filter(action => action.id !== actionId),
  addAction: (state, action) => state.actions.push(action),
  addNodesPosition: (state, position) => state.nodesPositions.push(position),
}
