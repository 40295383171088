<template>
  <div
    class="tab-pane fade"
    id="reluctance"
    role="tabpanel"
    aria-labelledby="home-tab"
  >
    <div class="col-12 mt-5">
      <div class="card p-0">
        <div
          style="padding: 16px;"
          class="d-flex justify-content-between align-items-center"
        >
          <h4 class="m-0">
            {{ $t("redialing_rules") }}
          </h4>
          <button
            class="btn btn-outline-primary"
            style="padding: 4px 40px;"
            @click="defaultConfig"
          >
            <small>{{ $t("set_default") }}</small>
          </button>
        </div>
        <div
          class="row tx-text-gray justify-content-between m-0"
          style="padding: 11px 43px; background-color: #F0F4FA;"
        >
          <div class="col-4 p-0">
            <small
              style="text-transform: uppercase;"
            >{{ $t("system") }}</small>
          </div>
          <div class="col-8 p-0 d-flex justify-content-between">
            <div class="col-4 p-0">
              <small
                style="text-transform: uppercase;"
              >{{ $t("repetition") }}</small>
            </div>
            <div class="col-4 p-0">
              <small
                style="text-transform: uppercase;"
              >{{ $t("time_in_minutes") }}</small>
            </div>
            <div
              class="text-center col-4 p-0"
            >
              <small
                style="text-transform: uppercase;"
              >{{ $t("finish_number") }}</small>
            </div>
          </div>
        </div>
        <ul
          class="col-12 list-group list-group-flush"
          style="padding: 0 43px;"
        >
          <li
            class="list-group-item row justify-content-between align-items-center d-flex"
            v-for="item in fields.hangup_groups"
            :key="item.retry_strategy_id"
          >
            <div class="m-0 col-4 d-flex">
              <h5
                v-if="item.hangup_cause_group_code"
                class="fw-normal m-0 pe-2 text-truncate"
                :title="$t(`${translateHangupGroups(item.hangup_cause_group_code)}`)"
              >
                {{ $t(`${translateHangupGroups(item.hangup_cause_group_code)}`) }}
              </h5>
              <span
                class="fa-light fa-circle-info tx-blue-300"
                data-bs-html="true"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-title="
                  `<span>` +
                    showTooltip(item.hangup_cause_group_code) +
                    '<span/>'
                "
              />
            </div>

            <div class="col-8 p-0 d-flex justify-content-between">
              <plus-minus-input
                v-model="item.attempts"
                :show-buttons="true"
              />
              <plus-minus-input
                v-model="item.interval"
                :max="1440"
              />
              <div class="form-check col-4 d-flex justify-content-center p-0">
                <input
                  class="form-check-input"
                  style="border-radius: 5px !important;"
                  type="checkbox"
                  v-model="item.conclude_number"
                  id="flexCheckChecked"
                >
              </div>
            </div>
          </li>
        </ul>
        <div
          class="row tx-text-gray justify-content-between m-0"
          style="padding: 11px 43px; background-color: #F0F4FA;"
        >
          <div class="col-4 p-0">
            <small
              style="text-transform: uppercase;"
            >{{ $t("system_qualifications") }}</small>
          </div>
          <div class="col-8 p-0 d-flex justify-content-between">
            <div class="col-5 p-0">
              <small
                style="text-transform: uppercase;"
              >{{ $t("repetition") }}</small>
            </div>
            <div class="col-5 p-0">
              <small
                style="text-transform: uppercase;"
              >{{ $t("time_in_minutes") }}</small>
            </div>
          </div>
        </div>
        <ul
          class="col-12 list-group list-group-flush"
          style="padding: 0 43px;"
        >
          <template
            v-for="item in fields.qualifications"
          >
            <li
              v-if="item.system_qualification_id < 0"
              class="list-group-item row justify-content-between align-items-center d-flex"
              :key="item.retry_strategy_id"
            >
              <div class="m-0 col-4 d-flex">
                <h5
                  class="fw-normal m-0 pe-2 text-truncate"
                  :title="$t(`${getSystemQualification(item.system_qualification_id)}`)"
                >
                  {{ $t(`${getSystemQualification(item.system_qualification_id)}`) }}
                </h5>
                <span
                  class="fa-light fa-circle-info tx-blue-300"
                  data-bs-html="true"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-title="
                    '<div><span>' +
                      showTooltip(item.system_qualification_id) +
                      '<span/><div/>'
                  "
                />
              </div>

              <div class="col-8 p-0 d-flex justify-content-between">
                <plus-minus-input
                  v-model="item.attempts"
                  :show-buttons="true"
                  col-size="col-5"
                />
                <plus-minus-input
                  v-model="item.interval"
                  :max="1440"
                  col-size="col-5 me-3"
                />
              </div>
            </li>
          </template>
        </ul>
        <div
          class="row tx-text-gray justify-content-between m-0"
          style="padding: 11px 43px; background-color: #F0F4FA;"
        >
          <div class="col-4 p-0">
            <small
              style="text-transform: uppercase;"
            >{{ $t("campaign_qualifications") }}</small>
          </div>
          <div class="col-8 p-0 d-flex justify-content-between">
            <div class="col-4 p-0">
              <small
                style="text-transform: uppercase;"
              >{{ $t("repetition") }}</small>
            </div>
            <div class="col-4 p-0">
              <small
                style="text-transform: uppercase;"
              >{{ $t("time_in_minutes") }}</small>
            </div>
            <div
              class="text-center col-4 p-0"
            >
              <small
                style="text-transform: uppercase;"
              >{{ $t("action") }}</small>
            </div>
          </div>
        </div>
        <ul
          class="col-12 list-group list-group-flush"
          style="padding: 0 43px;"
        >
          <template
            v-for="item in fields.qualifications"
          >
            <li
              v-if="item.qualification_id"
              class="list-group-item row justify-content-between align-items-center d-flex"
              :key="item.retry_strategy_id"
            >
              <h5
                class="fw-normal m-0 col-4"
              >
                {{ item.name }}
              </h5>
              <div class="col-8 p-0 d-flex justify-content-between">
                <plus-minus-input
                  v-model="item.attempts"
                  :show-buttons="true"
                  :disable-input="item.behavior !== 3"
                />
                <plus-minus-input
                  v-model="item.interval"
                  :max="1440"
                  :disable-input="item.behavior !== 3"
                />
                <div
                  class="form-check col-4 d-flex justify-content-start align-items-center p-0"
                >
                  <h6
                    class="tx-text-gray fw-normal m-0 text-truncate"
                  >
                    {{ $t(`${translateReadableBehavior(item.behavior)}`) }}
                  </h6>
                </div>
              </div>
            </li>
          </template>
          <template
            v-for="item in noShowQualifications"
            :key="item.name"
          >
            <li
              class="list-group-item row justify-content-between align-items-center d-flex"
            >
              <h5
                class="fw-normal m-0 col-4"
              >
                {{ item.name }}
              </h5>
              <div class="col-8 p-0 d-flex justify-content-between">
                <plus-minus-input
                  :show-buttons="true"
                  :disable-input="item.behavior !== 3"
                />
                <plus-minus-input
                  :disable-input="item.behavior !== 3"
                  :max="1440"
                />
                <div
                  class="form-check col-4 d-flex justify-content-start align-items-center p-0"
                >
                  <h6
                    class="tx-text-gray fw-normal m-0 text-truncate"
                    :title="$t(`${translateReadableBehavior(item.behavior)}`)"
                  >
                    {{ $t(`${translateReadableBehavior(item.behavior)}`) }}
                  </h6>
                </div>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="col-lg-12">
      <app-button
        color="btn-primary text-capitalize"
        class="w-100"
        @click="editCampaign"
        :loading="loading"
      >
        {{ $t("save") }}
      </app-button>
    </div>

    <div class="col-lg-12 mt-3">
      <app-button
        color="btn-secondary text-capitalize"
        class="w-100"
        @click="tabAdvanced.show()"
      >
        {{ $t("back") }}
      </app-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PlusMinusInput from "./plus-minus-input.vue";
import AppButton from "@components/app-button";
import { Tooltip } from "bootstrap";

export default {
  emits: ["submit"],

  props: {
    tabAdvanced: {
      type: Object,
      default: null,
    },
  },

  components: {
    PlusMinusInput,
    AppButton,
  },

  data() {
    return {
      qualificationTooltips: null,
      noShowQualifications: [],
      fields: {
        hangup_groups: {
          not_answered: {
            hangup_cause_group_code: null,
            retry_strategy_id: null,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
          abandoned: {
            hangup_cause_group_code: null,
            retry_strategy_id: null,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
          amd: {
            hangup_cause_group_code: null,
            retry_strategy_id: null,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
          busy: {
            hangup_cause_group_code: null,
            retry_strategy_id: null,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
          invalid_number: {
            hangup_cause_group_code: null,
            retry_strategy_id: null,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
          call_denied: {
            hangup_cause_group_code: null,
            retry_strategy_id: null,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
          external_congestion: {
            hangup_cause_group_code: null,
            retry_strategy_id: null,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
          impossible_to_complete: {
            hangup_cause_group_code: null,
            retry_strategy_id: null,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
        },
        qualifications: [
          {
            retry_strategy_id: null,
            system_qualification_id: -2,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
          {
            retry_strategy_id: null,
            system_qualification_id: -3,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
          {
            retry_strategy_id: null,
            system_qualification_id: -4,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
          {
            retry_strategy_id: null,
            system_qualification_id: -5,
            attempts: null,
            interval: null,
            conclude_number: true,
          },
        ],
      },
    }
  },

  mounted() {
    this.fetchStrategy(this.campaign.id).then(() => {
      const tooltips = document.querySelectorAll("[data-bs-toggle=\"tooltip\"]");
      this.qualificationTooltips = [...tooltips].map(tooltip => new Tooltip(tooltip));
    })
  },

  computed: {
    ...mapGetters("manager/campaign", {
      campaign: "getCampaign",
      loading: "isLoading",
    }),

    ...mapGetters("manager/campaign/reluctance", {
      strategy: "getStrategy",
    }),
  },

  watch: {
    strategy(data) {
      if (Object.keys(data).length != 0) {
        Object.values(data.retry_strategies.hangup_causes_strategies).forEach(item => {
          Object.keys(this.fields.hangup_groups).find((hangup) => {
            if (hangup == item.hangup_cause_group_code) {
              this.fields.hangup_groups[item.hangup_cause_group_code].retry_strategy_id = item.id;
              this.fields.hangup_groups[item.hangup_cause_group_code].hangup_cause_group_code = item.hangup_cause_group_code;
              this.fields.hangup_groups[item.hangup_cause_group_code].attempts = item.attempts;
              this.fields.hangup_groups[item.hangup_cause_group_code].interval = item.interval;
              this.fields.hangup_groups[item.hangup_cause_group_code].conclude_number = item.conclude_number
            }
          })
        });

        Object.values(data.retry_strategies.system_qualifications_strategies).forEach(item => {
          this.fields.qualifications.forEach((qualification) => {
            if (qualification.system_qualification_id == item.system_qualification_id) {
              qualification.retry_strategy_id = item.id;
              qualification.attempts = item.attempts;
              qualification.interval = item.interval
              qualification.conclude_number = true
            }
          })
        });

        Object.values(data.retry_strategies.campaign_qualifications_strategies).forEach(item => {
          this.campaign.qualifications.find((qualification) => {
            if (qualification.id === item.qualification_id) {
              const retry_qualification = {
                retry_strategy_id: item.id,
                qualification_id: item.qualification_id,
                attempts: item.attempts,
                interval: item.interval,
                behavior: qualification.behavior,
                name: item.qualification_name,
                conclude_number: true,

              };
              this.fields.qualifications.push(retry_qualification)
            }
          })
        });

        this.campaign.qualifications.find((qualification) => {
          if (qualification.behavior === 2 || qualification.behavior === 1) {
            const retry_qualification = {
              behavior: qualification.behavior,
              name: qualification.name,
            };
            this.noShowQualifications.push(retry_qualification)
          }
        })
      }
      this.fields
    },
  },

  methods: {
    ...mapActions("manager/campaign/reluctance", [
      "fetchStrategy",
      "updateStrategy",
    ]),

    translateReadableBehavior(behavior) {
      let translated_behavior = "";
      if (behavior == 1)
        translated_behavior = "not_dial_phone_again";

      if (behavior == 2)
        translated_behavior = "not_dial_client_again";

      if (behavior == 3) translated_behavior = "repeat";

      return translated_behavior;
    },

    translateHangupGroups(hangup_cause_group_code) {
      if (hangup_cause_group_code == "amd") return "amd_reluctance";

      return hangup_cause_group_code;
    },

    getSystemQualification(id) {
      let system_qualification = "";

      if (id == -2) system_qualification = "unqualified";

      if (id == -3) system_qualification = "voicemail";

      if (id == -4) system_qualification = "silent";

      if (id == -5) system_qualification = "exceeded_qualification_time_limit";

      return system_qualification;
    },

    defaultConfig() {
      Object.values(this.fields.hangup_groups).forEach(item => {
        item.attempts = 3;
        item.interval = 0;
        item.conclude_number = true;
      });
      Object.values(this.fields.qualifications).forEach(item => {
        item.attempts = 3;
        item.interval = 0;
      });
    },

    showTooltip(value) {
      switch (value) {
        case "not_answered":
          return `${this.$t("tooltips.not_answered")}`;
        case "abandoned":
          return `${this.$t("tooltips.abandoned")}`;
        case "amd":
          return `${this.$t("tooltips.amd")}`;
        case "busy":
          return `${this.$t("tooltips.busy")}`;
        case "invalid_number":
          return `${this.$t("tooltips.invalid_number")}`;
        case "call_denied":
          return `${this.$t("tooltips.call_denied")}`;
        case "external_congestion":
          return `${this.$t("tooltips.external_congestion")}`;
        case "impossible_to_complete":
          return `${this.$t("tooltips.impossible_to_complete")}`;
        case -2:
          return `${this.$t("tooltips.agent_left")}`;
        case -3:
          return `${this.$t("tooltips.manual_qualification")}`;
        case -4:
          return `${this.$t("tooltips.qualified_by_agent")}`;
        case -5:
          return `${this.$t("tooltips.qualification_time_exceeded")}`;
      }
    },

    editCampaign() {
      this.$emit("submit");
      this.updateStrategy({
        fields: this.fields,
        id: this.$route.params.id,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
