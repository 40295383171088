<template>
  <div class="d-flex justify-content-between align-items-center omnichannel-container">
    <div
      style="gap: 0.5rem 1rem"
      class="d-inline-flex flex-wrap align-items-center"
    >
      <router-link
        v-if="!isAgent"
        to="/manager/whatsapp"
        class="btn btn-rounded-circle btn-white"
      >
        <i class="fa-regular fa-chevron-left" />
      </router-link>
      <h2 class="d-inline-block m-0">
        Omnichannel 3C+
      </h2>
    </div>
    <app-button
      v-if="!showContactsPanel"
      :loading="loadingFlags.createNewChat"
      class="lh-base"
      data-test="newChat-button"
      @click="openNewChat"
    >
      <i class="fa-regular fa-plus" />
    </app-button>
  </div>
  <whatsapp-filters
    v-if="isSupervisorOrManager"
    @sort-search="setSortSearch"
    @filter-by="setFilterBy"
  />
  <template v-if="!isAgent && !showContactsPanel && showManagerTopBar">
    <manager-top-bar @filter-agent="filterChats($event)" />
  </template>
  <panel-contacts
    v-if="showContactsPanel"
    @close="showContactsPanel = false"
  />
  <template v-else>
    <div
      v-if="showSearchInput || isAgent"
      class="top-bar-whatsapp-talks"
    >
      <div
        style="padding: 0 1rem"
        class="col rounded bg-white border d-flex align-items-center"
      >
        <search-input
          @search="search"
          class="col search-input me-2"
          :placeholder="$t('placeholders.search_capitalize')"
        />
        <div
          v-if="isAgent"
          class="d-flex align-items-center"
        >
          <app-button
            :color="filterOpen? 'btn-primary' : 'btn-gray-blue'"
            class="filter-button"
            data-test="filterChats-button"
            @click="filterOpen = !filterOpen"
          >
            <span
              class="far"
              :class="filterOpen? 'fa-close' : 'fa-filter'"
            />
          </app-button>
        </div>
      </div>
    </div>
    <div
      v-if="filterOpen"
      class="px-4 pb-2"
    >
      <select-contact-tags @select="filterByTags" />
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-between t-tabs nav-bar-whatsapp-talks">
        <div
          :class="{
            'col-auto text-center fw-light t-tab': true,
            active: talksTab === 'my'
          }"
          data-test="inProgressTab-button"
          @click="updateTalksTab('my')"
        >
          <small class="text-truncate"> {{ $t('chats') }} </small>
          <span
            v-if="unreadConversations"
            class="notification chats fw-light me-1 ms-2 position-relative"
          >
            <p
              class="my-0"
              style="min-width: 12px;"
            >
              {{ unreadConversations }}
            </p>
            <span
              v-if="hasNotifiedInProgress"
              class="position-absolute top-0 end-0 rounded-circle bg-red-300"
              style="height: 4px; width: 4px"
            />
          </span>
        </div>

        <div
          :class="{
            'col-auto text-center fw-light t-tab': true,
            active: talksTab === 'groups'
          }"
          data-test="groupsTab-button"
          @click="updateTalksTab('groups')"
        >
          <small class="text-truncate"> {{ $t('groups') }} </small>
          <span
            v-if="unreadGroupsConversations"
            class="notification chats fw-light me-1 ms-2"
          >
            <p
              class="my-0"
              style="min-width: 12px;"
            >
              {{ unreadGroupsConversations }}
            </p>
          </span>
        </div>

        <div
          :class="{
            'col-auto text-center fw-light t-tab': true,
            active: talksTab === 'queue'
          }"
          data-test="queueTab-button"
          @click="updateTalksTab('queue')"
        >
          <small class="text-truncate">{{ $t('capitalizes.queue') }}</small>
          <span
            v-if="queuePagination.total>0"
            class="notification chats fw-light me-1 ms-2 position-relative"
          >
            <p
              class="my-0"
              style="min-width: 12px;"
            >
              {{ queuePagination.total }}
            </p>
            <span
              v-if="hasNotifiedQueue"
              class="position-absolute top-0 end-0 rounded-circle bg-red-300"
              style="height: 4px; width: 4px"
            />
          </span>
        </div>
        <div class="dropdown text-center fw-light t-tab col-auto">
          <button
            class="btn btn-sm px-3 shadow-none border-0"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-test="moreTabs-button"
          >
            <i class="fe fe-more-horizontal" />
          </button>
          <div class="dropdown-menu">
            <h5 class="text-medium">
              {{ $t('more_options') }}
            </h5>
            <div>
              <div
                v-if="!isAgent"
                :class="{
                  'dropdown-item': true,
                  active: talksTab === 'inChatbot'
                }"
                @click="updateTalksTab('inChatbot')"
              >
                <span class="tx-text-gray fal fa-robot fw-medium" />
                Chatbot
              </div>
              <div
                :class="{ 'dropdown-item': true, active: talksTab === 'snooze' }"
                data-test="snoozeTab-button"
                @click="updateTalksTab('snooze')"
              >
                <p class="m-0">
                  <span class="tx-text-gray far fa-clock" />
                  {{ $t('list_metrics_on_hold') }}
                </p>
              </div>
              <div
                :class="{
                  'dropdown-item': true,
                  active: talksTab === 'finished'
                }"
                data-test="finishedTab-button"
                @click="updateTalksTab('finished')"
              >
                <p class="m-0">
                  <span class="tx-text-gray far fa-check-circle" />
                  {{ $t('compĺeted') }}
                </p>
              </div>
              <hr class="navbar-divider">
              <div
                :class="{ 'dropdown-item': true }"
                data-test="contactsTab-button"
                @click="showContactsPanel = true"
              >
                <p class="m-0">
                  <span class="tx-text-gray fal fa-user-group fw-medium" />
                  {{ $t('contacts') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="whatsapp-talks position-relative"
      ref="whatsapp-talks"
    >
      <no-connection />
      <syncing-chats />
      <div
        class="row card-container"
        id="chats-tab"
      >
        <div class="col-lg-12 px-0 overflow-hidden">
          <in-progress-tab
            v-if="talksTab === 'my'"
            v-model="scrollPositions.inProgressPosition"
            @open-modal="openNewChat"
          />
          <groups-tab
            v-if="talksTab === 'groups'"
            v-model="scrollPositions.groupsPosition"
          />
          <queue-tab
            v-if="talksTab === 'queue'"
            v-model="scrollPositions.queuePosition"
            @open-modal="openNewChat"
          />
          <in-chatbot-tab
            v-if="talksTab === 'inChatbot'"
            v-model="scrollPositions.inChatbotProgress"
          />
          <snooze-tab
            v-if="talksTab === 'snooze'"
            v-model="scrollPositions.inSnoozePosition"
          />
          <finished-tab
            v-if="talksTab === 'finished'"
            v-model="scrollPositions.finishedPosition"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import _ from "lodash";
import {mapGetters, mapActions} from "vuex";
import {Utils} from "@/utils";

import SearchInput from "@components/search-input";
import PanelContacts from "./panel-contacts.vue";
import ManagerTopBar from "./manager-top-bar.vue";
import NoConnection from "@whatsapp/components/no-connection.vue";
import InProgressTab from "./talks-tabs/in-progress-tab.vue";
import GroupsTab from "./talks-tabs/groups-tab.vue";
import QueueTab from "./talks-tabs/queue-tab.vue";
import SnoozeTab from "./talks-tabs/snooze-tab.vue";
import FinishedTab from "./talks-tabs/finished-tab.vue";
import InChatbotTab from "@whatsapp/components/talks-tabs/in-chatbot-tab.vue";
import AppButton from "@components/app-button.vue";
import SelectContactTags from "@whatsapp/components/tags/select-contact-tags.vue";
import WhatsappFilters from "@whatsapp/components/whatsapp-filters.vue";
import SyncingChats from "@whatsapp/components/syncing-chats.vue";

export default {
  emits: ["openNewChat"],

  data() {
    return {
      showManagerTopBar: false,
      showSearchInput: false,
      originalTitle: "",
      showContactsPanel: false,
      scrollHeightBackup: 0,
      showPaginationObserver: true,
      scrollPositions: {
        inProgressPosition: 0,
        groupsPosition: 0,
        queuePosition: 0,
        inChatbotProgress: 0,
        inSnoozePosition: 0,
        finishedPosition: 0
      },
      filterOpen: false
    };
  },

  created() {
    this.resetChatsPagination();
    this.fetchInProgress()
    this.fetchGroups()
    this.fetchQueue()
  },

  mounted() {
    Notification.requestPermission();
    this.originalTitle = document.querySelector("title").text;
  },

  beforeUnmount() {
    document.querySelector("title").text = this.originalTitle;
    if(this.filters.search) {
      this.addFilters({ search: "" });
    }
  },

  components: {
    SyncingChats,
    SelectContactTags,
    AppButton,
    WhatsappFilters,
    InChatbotTab,
    InProgressTab,
    GroupsTab,
    QueueTab,
    SnoozeTab,
    FinishedTab,
    NoConnection,
    SearchInput,
    PanelContacts,
    ManagerTopBar
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent",
      isSupervisorOrManager: "isSupervisorOrManager"
    }),

    ...mapGetters("whatsapp/chat", {
      unreadConversations: "getUnreadConversations",
      unreadGroupsConversations: "getUnreadGroupsConversations",
      hasNotifiedInProgress: "getHasNotifiedInProgress",
      hasNotifiedQueue: "getHasNotifiedQueue",
      loadingFlags: "getLoadingFlags",
      inProgress: "getInProgress",
      inProgressPagination: "getInProgressPagination",
      groupsPagination: "getGroupsPagination",
      queuePagination: "getQueuePagination",
      inChatbotPagination: "getInChatbotPagination",
      inSnoozePagination: "getInSnoozePagination",
      finishedPagination: "getFinishedPagination",
      talksTab: "getTalksTab",
      filters: "getFilters"
    }),
  },

  watch: {
    inProgress: {
      deep: true,
      handler(data) {
        data = Object.values(data);
        let notification = data.reduce((n, {unread}) => n + unread, 0);
        if (notification > 0) {
          document.querySelector("title").text = "(" + notification + ") " + this.originalTitle;
        }
        else {
          document.querySelector("title").text = this.originalTitle;
        }
      }
    }
  },

  methods: {
    mask: Utils.mask,

    ...mapActions("whatsapp/chat", [
      "resetChatsPagination",
      "incrementInProgressCurrentPage",
      "incrementGroupsCurrentPage",
      "incrementQueueCurrentPage",
      "incrementInChatbotCurrentPage",
      "incrementInSnoozeCurrentPage",
      "incrementFinishedCurrentPage",
      "clearChats",
      "addFilters",
      "updateTalksTab",
      "fetchInProgress",
      "fetchGroups",
      "fetchQueue",
      "fetchInChatbot",
      "fetchInSnooze",
      "fetchFinished",
    ]),

    open(id) {
      this.$router.push({path: "/whatsapp/" + id});
    },

    openNewChat() {
      this.$emit("openNewChat");
    },

    fetchChats(){
      this.resetChatsPagination();
      this.fetchInProgress()
      this.fetchGroups()
      this.fetchQueue()
      switch (this.talksTab) {
        case "inChatbot":
          this.fetchInChatbot()
          break;
        case "snooze":
          this.fetchInSnooze()
          break;
        case "finished":
          this.fetchFinished()
          break;
       }
    },

    filterChats(filters) {
      this.addFilters(filters);
      this.fetchChats();
    },

    search: _.debounce(function (event) {
      this.clearChats();
      this.addFilters({
        search: event
      });
      this.fetchChats();
    }, 500),

    filterByTags(ids) {
      this.clearChats();
      const filters = {
        tags_ids: ids
      }
      this.filterChats(filters)
    },

    setSortSearch(value) {
      if (value === 'search') {
        this.showSearchInput = !this.showSearchInput;
        this.showManagerTopBar = false;
      }

      if (value === 'sort') {
        this.showManagerTopBar = !this.showManagerTopBar;
        this.showSearchInput = false;
      }
    },

    setFilterBy(value) {
      this.clearFilters();
      this.clearChats();

      if (value === 'me') {
        this.filterChats({agent_id: this.user.id})
        return
      }

      this.filterChats({agent_id: null});
    },

    clearFilters() {
      this.addFilters({
        search: null,
        group_channel_id: null,
        team_id: null,
        agent_id: null,
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  margin: $whatsapp-spacing-0 0;
  padding: 0 0.25rem;
}

.omnichannel-container {
  margin: $whatsapp-spacing-2;
}

.search-input {
  background-color: #ffffff;
  border-radius: 10px;
}

.nav-bar-whatsapp-talks {
  padding: 0 $whatsapp-spacing-2;
}

.top-bar-whatsapp-talks {
  padding: $whatsapp-spacing-1 0;
  margin: 0 $whatsapp-spacing-2;
}

.dropdown {
  .btn {
    border-radius: $whatsapp-border-radius !important;

    &:active,
    &:hover {
      background-color: $color-blue-300 !important;
      color: #ffffff !important;
    }
  }

  h5 {
    margin-bottom: 1rem;
  }

  .dropdown-menu {
    width: 235px;
    padding: 1rem 1.25rem;
    box-shadow: 0 0.25rem 1.125rem 0 rgba(34, 54, 77, 0.25);
    border: none;
    border-radius: 0.75rem 0 0.75rem 0.75rem;
  }

  .dropdown-item {
    color: $color-text-solid-black;
    width: auto;
    display: block;
    border-radius: 8px !important;
    padding-left: 0.75rem;
    cursor: pointer;

    &:hover {
      background-color: $color-gray-blue-300 !important;
      color: $color-blue-300 !important;
    }

    &.active {
      background-color: $color-blue-300 !important;
      color: $color-text-light !important;
    }
  }

  .navbar-divider {
    margin: 0.5rem 0;
  }
}

//TODO: improve
.t-tabs {
  border-bottom: 1px solid #c5c5c5;
  background-color: $color-text-light !important;
  height: 33px;

  .t-tab {
    border-bottom: none;
    color: $color-text-200;
    position: relative;

    small {
      font-size: 14px !important;
    }

    top: 1px;
    cursor: pointer;

    &.active {
      color: $color-blue-300;
      font-weight: 500;
      border-bottom: 2px solid $color-blue-300 !important;
    }
  }
}

::-webkit-scrollbar {
  width: 4px !important;
  // height: $whatsapp-border-radius;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: #b0c5c2 !important;
  border-radius: 3px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #6e84a3 !important;
}

.whatsapp-talks {
  flex: 1;
  border-right: 1px solid #dddfe3;
  background-color: white;

  .align-items-center {
    // border-radius: 8px;
    &.active {
      background-color: #edf2f9;
    }
  }

  .user {
    line-height: 30px;
    font-size: 16px;
  }

  .search {
    background-color: #f5f5f5;
    border-radius: 20px;
    font-weight: 200;
  }

  .tabs {
    height: 40.5px;
    background-color: #f5f5f5;
    border-radius: 20px;

    .btn {
      border: none;
      border-radius: 20px;
      color: black;
      background-color: #f5f5f5;

      &.active {
        background-color: #bdd4f2;
      }
    }
  }

  .notification {
    display: inline-block;
    line-height: 18px !important;
    border-radius: 50px;
    font-size: 12px;

    &.chats {
      background-color: #e1e9f4;
      color: #283e5d;
      border-radius: 16px;
      padding: 0.1rem 0.3rem;
    }

    &.messages {
      background-color: #ffc82c;
      color: white;
    }
  }
}

.ll {
  cursor: pointer;

  &:hover {
    background-color: #edf2f9;
  }
}

.filter-button {
  padding: 0 $whatsapp-spacing-1 !important;
  height: $whatsapp-button-size !important;
  width: $whatsapp-button-size !important;
  border-radius: $whatsapp-border-radius !important;
}

.new-chat-button {
  padding: $whatsapp-spacing-1 !important;
  height: $whatsapp-button-size !important;
  width: $whatsapp-button-size !important;
  border-radius: $whatsapp-border-radius !important;
}
</style>
