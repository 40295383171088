import moment from "moment";
import "moment-duration-format";

import { Mask } from "maska";
import { Collapse, Dropdown, Tooltip } from "bootstrap";
import { parsePhoneNumber } from "libphonenumber-js";

export class Utils {
  /**
   *  generate pseudo unique id
   */
  static generateUniqueID() {
    return (
      moment().unix() +
      "_" +
      Math.random()
        .toString(16)
        .slice(2)
    );
  }

  /**
   *
   * @param num
   * @param enableSeconds
   * @returns {string}
   */
  static timeFormat(num, enableSeconds = true) {
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    let seg = num % 60;
    let min = Math.floor(num / 60) % 60;
    let hour = Math.floor(num / 3600);
    let timeFormatted = zeroPad(hour, 2) + ":" + zeroPad(min, 2);
    if (enableSeconds) {
      timeFormatted += ":" + zeroPad(seg, 2);
    }
    return timeFormatted;
  }

  /**
   *
   * @param {*} num
   * @returns
   */
  static formatTimestamp(date) {
    const d = new Date(date);
    return moment.unix(d).format("HH:mm");
  }

  /**
   *
   * @param {*} index
   * @returns
   */
  static getColor(index) {
    return [
      "#998500",
      "#C26000",
      "#A50D0D",
      "#B80F7D",
      "#6E008F",
      "#411C9C",
      "#0062D7",
      "#009484",
      "#049A04",
      "#5F9400",
      "#D6BA00",
      "#F17F0E",
      "#A62EC5",
      "#2497FD",
      "#1ABCAD",
      "#8EBD00",
      "#7036E4",
      "#DE2128",
    ][index % 18];
  }

  /**
   *
   * @param {*} bytes
   * @param {*} decimals
   * @returns
   */
  static formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
  }

  /**
   *
   */
  static formatDate(date, format = "DD/MM/YYYY [às] HH:mm:ss") {
    return moment(date).format(format);
  }

  static timestampToDate(timestamp, format) {
    return moment.unix(timestamp).format(format);
  }

  static formatCurrency(value, locale = "pt-BR", currency = "BRL", options = {}) {
    const formattedValue = value.toLocaleString(locale, {
      style: "currency",
      currency,
      ...options,
    });

    return formattedValue;
  }

  /**
   *  Returns the difference between a requested date and the now date
   */

  static dateDifferenceSoFar(date) {
    const today = moment();
    return today.diff(moment.unix(date).format("YYYY-MM-DD HH:mm:ss"), "hours");
  }

  /**
   *
   * @param {*} val
   * @param {*} mask
   * @returns
   */
  static mask(val, mask) {
    let str = "";
    let p = 0;
    for (let c of mask) {
      if (c == "#") {
        if (val[p]) str += val[p];
        p++;
      } else str += c;
      if (val.length < p) break;
    }
    return str;
  }

  static removeStrongHtmlTag(text) {
    const regex = /(<strong>.*?<\/strong>)/;
    const textToArray = text.split(regex);

    const formattedArray = textToArray.map(text => {
      if (text.includes("<strong>")) {
        return {
          data: text.slice(8, -9),
          isBold: true,
        };
      } else
        return {
          data: text,
          isBold: false,
        };
    });

    return formattedArray;
  }
}

/**
 *
 */
export class Tone {
  /**
   *
   * @param {*} context
   * @param {*} freq1
   * @param {*} freq2
   */
  constructor(context, freq1, freq2) {
    this.context = context;
    this.status = 0;
    this.freq1 = freq1;
    this.freq2 = freq2;
  }

  /**
   *
   */
  setup() {
    this.osc1 = this.context.createOscillator();
    this.osc2 = this.context.createOscillator();
    this.osc1.frequency.value = this.freq1;
    this.osc2.frequency.value = this.freq2;

    this.gainNode = this.context.createGain();
    this.gainNode.gain.value = 0.25;

    this.filter = this.context.createBiquadFilter();
    this.filter.type = "lowpass";
    //this.filter.frequency = 8000;
    this.osc1.connect(this.gainNode);
    this.osc2.connect(this.gainNode);
    this.gainNode.connect(this.filter);
    this.filter.connect(this.context.destination);
  }

  /**
   *
   */
  start() {
    this.setup();
    this.osc1.start(0);
    this.osc2.start(0);
    this.status = 1;
  }

  /**
   *
   */
  stop() {
    this.osc1.stop(0);
    this.osc2.stop(0);
    this.status = 0;
  }
}

/**
 *
 */
export const colors = [
  "#FFDD00",
  "#FCEA79",
  "#D6BA00",
  "#998500",
  "#615400",
  "#F17F0E",
  "#FBD6A4",
  "#FFAF2E",
  "#C26000",
  "#663300",
  "#DE2128",
  "#FFB2B3",
  "#F65157",
  "#A50D0D",
  "#5E0808",
  "#E34AB8",
  "#FBC1E9",
  "#F580D3",
  "#B80F7D",
  "#620E39",
  "#A820CB",
  "#E7A8FA",
  "#C45DE0",
  "#6E008F",
  "#390057",
  "#7036E4",
  "#CCB6FC",
  "#9C6BFF",
  "#411C9C",
  "#2B1269",
  "#2497FD",
  "#B8DDFF",
  "#6CBAFE",
  "#0062D7",
  "#00298B",
  "#1ABCAD",
  "#98F1E8",
  "#52DBCD",
  "#009484",
  "#006B5A",
  "#28CC39",
  "#A4F4A7",
  "#69DE74",
  "#049A04",
  "#006B00",
  "#8EBD00",
  "#C6EF66",
  "#A4D41C",
  "#5F9400",
  "#375200",
  "#111111",
  "#DEDEDE",
  "#A5A5A5",
  "#6C6C6C",
  "#3A3A3A",
];

export function textColor(color) {
  const colors = {
    "#FFDD00": "#1B1B2B",
    "#FCEA79": "#1B1B2B",
    "#D6BA00": "#1B1B2B",
    "#998500": "#fff",
    "#615400": "#fff",
    "#F17F0E": "#fff",
    "#FBD6A4": "#1B1B2B",
    "#FFAF2E": "#1B1B2B",
    "#C26000": "#fff",
    "#663300": "#fff",
    "#DE2128": "#fff",
    "#FFB2B3": "#1B1B2B",
    "#F65157": "#fff",
    "#A50D0D": "#fff",
    "#5E0808": "#fff",
    "#E34AB8": "#fff",
    "#FBC1E9": "#1B1B2B",
    "#F580D3": "#1B1B2B",
    "#B80F7D": "#fff",
    "#620E39": "#fff",
    "#A820CB": "#fff",
    "#E7A8FA": "#1B1B2B",
    "#C45DE0": "#fff",
    "#6E008F": "#fff",
    "#390057": "#fff",
    "#7036E4": "#fff",
    "#CCB6FC": "#1B1B2B",
    "#9C6BFF": "#fff",
    "#411C9C": "#fff",
    "#2B1269": "#fff",
    "#2497FD": "#fff",
    "#B8DDFF": "#1B1B2B",
    "#6CBAFE": "#1B1B2B",
    "#0062D7": "#fff",
    "#00298B": "#fff",
    "#1ABCAD": "#1B1B2B",
    "#98F1E8": "#1B1B2B",
    "#52DBCD": "#1B1B2B",
    "#009484": "#fff",
    "#006B5A": "#fff",
    "#28CC39": "#1B1B2B",
    "#A4F4A7": "#1B1B2B",
    "#69DE74": "#1B1B2B",
    "#049A04": "#fff",
    "#006B00": "#fff",
    "#8EBD00": "#1B1B2B",
    "#C6EF66": "#1B1B2B",
    "#A4D41C": "#1B1B2B",
    "#5F9400": "#fff",
    "#375200": "#fff",
    "#111111": "#fff",
    "#DEDEDE": "#1B1B2B",
    "#A5A5A5": "#1B1B2B",
    "#6C6C6C": "#fff",
    "#3A3A3A": "#fff",
  }

  return colors[color];
}

export const tagBackgrounds = [
  "#E1E9F4",
  "#FFF6BF",
  "#FBDFC2",
  "#FFD7D9",
  "#F8D1ED",
  "#EBCDF3",
  "#DFD0FC",
  "#C8E5FE",
  "#C5EEEA",
  "#C9F2CD",
  "#E2EEBF",
  "#E8E8E8",
]

export const tagColors = {
  "#E1E9F4": "#303E50",
  "#FFF6BF": "#5D4900",
  "#FBDFC2": "#663300",
  "#FFD7D9": "#8F0000",
  "#F8D1ED": "#85134A",
  "#EBCDF3": "#390057",
  "#DFD0FC": "#2B1269",
  "#C8E5FE": "#00298B",
  "#C5EEEA": "#005544",
  "#C9F2CD": "#015901",
  "#E2EEBF": "#375200",
  "#E8E8E8": "#151515",
}


export const timezones = [
  { value: "Africa/Abidjan", text_value: "(GMT -00:00) Africa, Abidjan" },
  { value: "Africa/Accra", text_value: "(GMT -00:00) Africa, Accra" },
  {
    value: "Africa/Addis_Ababa",
    text_value: "(GMT +03:00) Africa, Addis Ababa",
  },
  { value: "Africa/Algiers", text_value: "(GMT +01:00) Africa, Algiers" },
  { value: "Africa/Asmara", text_value: "(GMT +03:00) Africa, Asmara" },
  { value: "Africa/Bamako", text_value: "(GMT -00:00) Africa, Bamako" },
  { value: "Africa/Bangui", text_value: "(GMT +01:00) Africa, Bangui" },
  { value: "Africa/Banjul", text_value: "(GMT -00:00) Africa, Banjul" },
  { value: "Africa/Bissau", text_value: "(GMT -00:00) Africa, Bissau" },
  { value: "Africa/Blantyre", text_value: "(GMT +02:00) Africa, Blantyre" },
  {
    value: "Africa/Brazzaville",
    text_value: "(GMT +01:00) Africa, Brazzaville",
  },
  { value: "Africa/Bujumbura", text_value: "(GMT +02:00) Africa, Bujumbura" },
  { value: "Africa/Cairo", text_value: "(GMT +02:00) Africa, Cairo" },
  { value: "Africa/Casablanca", text_value: "(GMT +01:00) Africa, Casablanca" },
  { value: "Africa/Ceuta", text_value: "(GMT +01:00) Africa, Ceuta" },
  { value: "Africa/Conakry", text_value: "(GMT -00:00) Africa, Conakry" },
  { value: "Africa/Dakar", text_value: "(GMT -00:00) Africa, Dakar" },
  {
    value: "Africa/Dar_es_Salaam",
    text_value: "(GMT +03:00) Africa, Dar es Salaam",
  },
  { value: "Africa/Djibouti", text_value: "(GMT +03:00) Africa, Djibouti" },
  { value: "Africa/Douala", text_value: "(GMT +01:00) Africa, Douala" },
  { value: "Africa/El_Aaiun", text_value: "(GMT +01:00) Africa, El Aaiun" },
  { value: "Africa/Freetown", text_value: "(GMT -00:00) Africa, Freetown" },
  { value: "Africa/Gaborone", text_value: "(GMT +02:00) Africa, Gaborone" },
  { value: "Africa/Harare", text_value: "(GMT +02:00) Africa, Harare" },
  {
    value: "Africa/Johannesburg",
    text_value: "(GMT +02:00) Africa, Johannesburg",
  },
  { value: "Africa/Juba", text_value: "(GMT +03:00) Africa, Juba" },
  { value: "Africa/Kampala", text_value: "(GMT +03:00) Africa, Kampala" },
  { value: "Africa/Khartoum", text_value: "(GMT +02:00) Africa, Khartoum" },
  { value: "Africa/Kigali", text_value: "(GMT +02:00) Africa, Kigali" },
  { value: "Africa/Kinshasa", text_value: "(GMT +01:00) Africa, Kinshasa" },
  { value: "Africa/Lagos", text_value: "(GMT +01:00) Africa, Lagos" },
  { value: "Africa/Libreville", text_value: "(GMT +01:00) Africa, Libreville" },
  { value: "Africa/Lome", text_value: "(GMT -00:00) Africa, Lome" },
  { value: "Africa/Luanda", text_value: "(GMT +01:00) Africa, Luanda" },
  { value: "Africa/Lubumbashi", text_value: "(GMT +02:00) Africa, Lubumbashi" },
  { value: "Africa/Lusaka", text_value: "(GMT +02:00) Africa, Lusaka" },
  { value: "Africa/Malabo", text_value: "(GMT +01:00) Africa, Malabo" },
  { value: "Africa/Maputo", text_value: "(GMT +02:00) Africa, Maputo" },
  { value: "Africa/Maseru", text_value: "(GMT +02:00) Africa, Maseru" },
  { value: "Africa/Mbabane", text_value: "(GMT +02:00) Africa, Mbabane" },
  { value: "Africa/Mogadishu", text_value: "(GMT +03:00) Africa, Mogadishu" },
  { value: "Africa/Monrovia", text_value: "(GMT -00:00) Africa, Monrovia" },
  { value: "Africa/Nairobi", text_value: "(GMT +03:00) Africa, Nairobi" },
  { value: "Africa/Ndjamena", text_value: "(GMT +01:00) Africa, Ndjamena" },
  { value: "Africa/Niamey", text_value: "(GMT +01:00) Africa, Niamey" },
  { value: "Africa/Nouakchott", text_value: "(GMT -00:00) Africa, Nouakchott" },
  {
    value: "Africa/Ouagadougou",
    text_value: "(GMT -00:00) Africa, Ouagadougou",
  },
  { value: "Africa/Porto-Novo", text_value: "(GMT +01:00) Africa, Porto-Novo" },
  { value: "Africa/Sao_Tome", text_value: "(GMT -00:00) Africa, Sao Tome" },
  { value: "Africa/Tripoli", text_value: "(GMT +02:00) Africa, Tripoli" },
  { value: "Africa/Tunis", text_value: "(GMT +01:00) Africa, Tunis" },
  { value: "Africa/Windhoek", text_value: "(GMT +02:00) Africa, Windhoek" },
  { value: "America/Adak", text_value: "(GMT -09:00) America, Adak" },
  { value: "America/Anchorage", text_value: "(GMT -08:00) America, Anchorage" },
  { value: "America/Anguilla", text_value: "(GMT -04:00) America, Anguilla" },
  { value: "America/Antigua", text_value: "(GMT -04:00) America, Antigua" },
  { value: "America/Araguaina", text_value: "(GMT -03:00) America, Araguaina" },
  {
    value: "America/Argentina/Buenos_Aires",
    text_value: "(GMT -03:00) America, Argentina, Buenos Aires",
  },
  {
    value: "America/Argentina/Catamarca",
    text_value: "(GMT -03:00) America, Argentina, Catamarca",
  },
  {
    value: "America/Argentina/Cordoba",
    text_value: "(GMT -03:00) America, Argentina, Cordoba",
  },
  {
    value: "America/Argentina/Jujuy",
    text_value: "(GMT -03:00) America, Argentina, Jujuy",
  },
  {
    value: "America/Argentina/La_Rioja",
    text_value: "(GMT -03:00) America, Argentina, La Rioja",
  },
  {
    value: "America/Argentina/Mendoza",
    text_value: "(GMT -03:00) America, Argentina, Mendoza",
  },
  {
    value: "America/Argentina/Rio_Gallegos",
    text_value: "(GMT -03:00) America, Argentina, Rio Gallegos",
  },
  {
    value: "America/Argentina/Salta",
    text_value: "(GMT -03:00) America, Argentina, Salta",
  },
  {
    value: "America/Argentina/San_Juan",
    text_value: "(GMT -03:00) America, Argentina, San Juan",
  },
  {
    value: "America/Argentina/San_Luis",
    text_value: "(GMT -03:00) America, Argentina, San Luis",
  },
  {
    value: "America/Argentina/Tucuman",
    text_value: "(GMT -03:00) America, Argentina, Tucuman",
  },
  {
    value: "America/Argentina/Ushuaia",
    text_value: "(GMT -03:00) America, Argentina, Ushuaia",
  },
  { value: "America/Aruba", text_value: "(GMT -04:00) America, Aruba" },
  { value: "America/Asuncion", text_value: "(GMT -03:00) America, Asuncion" },
  { value: "America/Atikokan", text_value: "(GMT -05:00) America, Atikokan" },
  { value: "America/Bahia", text_value: "(GMT -03:00) America, Bahia" },
  {
    value: "America/Bahia_Banderas",
    text_value: "(GMT -06:00) America, Bahia Banderas",
  },
  { value: "America/Barbados", text_value: "(GMT -04:00) America, Barbados" },
  { value: "America/Belem", text_value: "(GMT -03:00) America, Belem" },
  { value: "America/Belize", text_value: "(GMT -06:00) America, Belize" },
  {
    value: "America/Blanc-Sablon",
    text_value: "(GMT -04:00) America, Blanc-Sablon",
  },
  { value: "America/Boa_Vista", text_value: "(GMT -04:00) America, Boa Vista" },
  { value: "America/Bogota", text_value: "(GMT -05:00) America, Bogota" },
  { value: "America/Boise", text_value: "(GMT -06:00) America, Boise" },
  {
    value: "America/Cambridge_Bay",
    text_value: "(GMT -06:00) America, Cambridge Bay",
  },
  {
    value: "America/Campo_Grande",
    text_value: "(GMT -04:00) America, Campo Grande",
  },
  { value: "America/Cancun", text_value: "(GMT -05:00) America, Cancun" },
  { value: "America/Caracas", text_value: "(GMT -04:00) America, Caracas" },
  { value: "America/Cayenne", text_value: "(GMT -03:00) America, Cayenne" },
  { value: "America/Cayman", text_value: "(GMT -05:00) America, Cayman" },
  { value: "America/Chicago", text_value: "(GMT -05:00) America, Chicago" },
  { value: "America/Chihuahua", text_value: "(GMT -07:00) America, Chihuahua" },
  {
    value: "America/Costa_Rica",
    text_value: "(GMT -06:00) America, Costa Rica",
  },
  { value: "America/Creston", text_value: "(GMT -07:00) America, Creston" },
  { value: "America/Cuiaba", text_value: "(GMT -04:00) America, Cuiaba" },
  { value: "America/Curacao", text_value: "(GMT -04:00) America, Curacao" },
  {
    value: "America/Danmarkshavn",
    text_value: "(GMT -00:00) America, Danmarkshavn",
  },
  { value: "America/Dawson", text_value: "(GMT -07:00) America, Dawson" },
  {
    value: "America/Dawson_Creek",
    text_value: "(GMT -07:00) America, Dawson Creek",
  },
  { value: "America/Denver", text_value: "(GMT -06:00) America, Denver" },
  { value: "America/Detroit", text_value: "(GMT -04:00) America, Detroit" },
  { value: "America/Dominica", text_value: "(GMT -04:00) America, Dominica" },
  { value: "America/Edmonton", text_value: "(GMT -06:00) America, Edmonton" },
  { value: "America/Eirunepe", text_value: "(GMT -05:00) America, Eirunepe" },
  {
    value: "America/El_Salvador",
    text_value: "(GMT -06:00) America, El Salvador",
  },
  {
    value: "America/Fort_Nelson",
    text_value: "(GMT -07:00) America, Fort Nelson",
  },
  { value: "America/Fortaleza", text_value: "(GMT -03:00) America, Fortaleza" },
  { value: "America/Glace_Bay", text_value: "(GMT -03:00) America, Glace Bay" },
  { value: "America/Goose_Bay", text_value: "(GMT -03:00) America, Goose Bay" },
  {
    value: "America/Grand_Turk",
    text_value: "(GMT -04:00) America, Grand Turk",
  },
  { value: "America/Grenada", text_value: "(GMT -04:00) America, Grenada" },
  {
    value: "America/Guadeloupe",
    text_value: "(GMT -04:00) America, Guadeloupe",
  },
  { value: "America/Guatemala", text_value: "(GMT -06:00) America, Guatemala" },
  { value: "America/Guayaquil", text_value: "(GMT -05:00) America, Guayaquil" },
  { value: "America/Guyana", text_value: "(GMT -04:00) America, Guyana" },
  { value: "America/Halifax", text_value: "(GMT -03:00) America, Halifax" },
  { value: "America/Havana", text_value: "(GMT -04:00) America, Havana" },
  {
    value: "America/Hermosillo",
    text_value: "(GMT -07:00) America, Hermosillo",
  },
  {
    value: "America/Indiana/Indianapolis",
    text_value: "(GMT -04:00) America, Indiana, Indianapolis",
  },
  {
    value: "America/Indiana/Knox",
    text_value: "(GMT -05:00) America, Indiana, Knox",
  },
  {
    value: "America/Indiana/Marengo",
    text_value: "(GMT -04:00) America, Indiana, Marengo",
  },
  {
    value: "America/Indiana/Petersburg",
    text_value: "(GMT -04:00) America, Indiana, Petersburg",
  },
  {
    value: "America/Indiana/Tell_City",
    text_value: "(GMT -05:00) America, Indiana, Tell City",
  },
  {
    value: "America/Indiana/Vevay",
    text_value: "(GMT -04:00) America, Indiana, Vevay",
  },
  {
    value: "America/Indiana/Vincennes",
    text_value: "(GMT -04:00) America, Indiana, Vincennes",
  },
  {
    value: "America/Indiana/Winamac",
    text_value: "(GMT -04:00) America, Indiana, Winamac",
  },
  { value: "America/Inuvik", text_value: "(GMT -06:00) America, Inuvik" },
  { value: "America/Iqaluit", text_value: "(GMT -04:00) America, Iqaluit" },
  { value: "America/Jamaica", text_value: "(GMT -05:00) America, Jamaica" },
  { value: "America/Juneau", text_value: "(GMT -08:00) America, Juneau" },
  {
    value: "America/Kentucky/Louisville",
    text_value: "(GMT -04:00) America, Kentucky, Louisville",
  },
  {
    value: "America/Kentucky/Monticello",
    text_value: "(GMT -04:00) America, Kentucky, Monticello",
  },
  {
    value: "America/Kralendijk",
    text_value: "(GMT -04:00) America, Kralendijk",
  },
  { value: "America/La_Paz", text_value: "(GMT -04:00) America, La Paz" },
  { value: "America/Lima", text_value: "(GMT -05:00) America, Lima" },
  {
    value: "America/Los_Angeles",
    text_value: "(GMT -07:00) America, Los Angeles",
  },
  {
    value: "America/Lower_Princes",
    text_value: "(GMT -04:00) America, Lower Princes",
  },
  { value: "America/Maceio", text_value: "(GMT -03:00) America, Maceio" },
  { value: "America/Managua", text_value: "(GMT -06:00) America, Managua" },
  { value: "America/Manaus", text_value: "(GMT -04:00) America, Manaus" },
  { value: "America/Marigot", text_value: "(GMT -04:00) America, Marigot" },
  {
    value: "America/Martinique",
    text_value: "(GMT -04:00) America, Martinique",
  },
  { value: "America/Matamoros", text_value: "(GMT -05:00) America, Matamoros" },
  { value: "America/Mazatlan", text_value: "(GMT -07:00) America, Mazatlan" },
  { value: "America/Menominee", text_value: "(GMT -05:00) America, Menominee" },
  { value: "America/Merida", text_value: "(GMT -06:00) America, Merida" },
  {
    value: "America/Metlakatla",
    text_value: "(GMT -08:00) America, Metlakatla",
  },
  {
    value: "America/Mexico_City",
    text_value: "(GMT -06:00) America, Mexico City",
  },
  { value: "America/Miquelon", text_value: "(GMT -02:00) America, Miquelon" },
  { value: "America/Moncton", text_value: "(GMT -03:00) America, Moncton" },
  { value: "America/Monterrey", text_value: "(GMT -06:00) America, Monterrey" },
  {
    value: "America/Montevideo",
    text_value: "(GMT -03:00) America, Montevideo",
  },
  {
    value: "America/Montserrat",
    text_value: "(GMT -04:00) America, Montserrat",
  },
  { value: "America/Nassau", text_value: "(GMT -04:00) America, Nassau" },
  { value: "America/New_York", text_value: "(GMT -04:00) America, New York" },
  { value: "America/Nipigon", text_value: "(GMT -04:00) America, Nipigon" },
  { value: "America/Nome", text_value: "(GMT -08:00) America, Nome" },
  { value: "America/Noronha", text_value: "(GMT -02:00) America, Noronha" },
  {
    value: "America/North_Dakota/Beulah",
    text_value: "(GMT -05:00) America, North Dakota, Beulah",
  },
  {
    value: "America/North_Dakota/Center",
    text_value: "(GMT -05:00) America, North Dakota, Center",
  },
  {
    value: "America/North_Dakota/New_Salem",
    text_value: "(GMT -05:00) America, North Dakota, New Salem",
  },
  { value: "America/Nuuk", text_value: "(GMT -03:00) America, Nuuk" },
  { value: "America/Ojinaga", text_value: "(GMT -06:00) America, Ojinaga" },
  { value: "America/Panama", text_value: "(GMT -05:00) America, Panama" },
  {
    value: "America/Pangnirtung",
    text_value: "(GMT -04:00) America, Pangnirtung",
  },
  {
    value: "America/Paramaribo",
    text_value: "(GMT -03:00) America, Paramaribo",
  },
  { value: "America/Phoenix", text_value: "(GMT -07:00) America, Phoenix" },
  {
    value: "America/Port-au-Prince",
    text_value: "(GMT -04:00) America, Port-au-Prince",
  },
  {
    value: "America/Port_of_Spain",
    text_value: "(GMT -04:00) America, Port of Spain",
  },
  {
    value: "America/Porto_Velho",
    text_value: "(GMT -04:00) America, Porto Velho",
  },
  {
    value: "America/Puerto_Rico",
    text_value: "(GMT -04:00) America, Puerto Rico",
  },
  {
    value: "America/Punta_Arenas",
    text_value: "(GMT -03:00) America, Punta Arenas",
  },
  {
    value: "America/Rainy_River",
    text_value: "(GMT -05:00) America, Rainy River",
  },
  {
    value: "America/Rankin_Inlet",
    text_value: "(GMT -05:00) America, Rankin Inlet",
  },
  { value: "America/Recife", text_value: "(GMT -03:00) America, Recife" },
  { value: "America/Regina", text_value: "(GMT -06:00) America, Regina" },
  { value: "America/Resolute", text_value: "(GMT -05:00) America, Resolute" },
  {
    value: "America/Rio_Branco",
    text_value: "(GMT -05:00) America, Rio Branco",
  },
  { value: "America/Santarem", text_value: "(GMT -03:00) America, Santarem" },
  { value: "America/Santiago", text_value: "(GMT -03:00) America, Santiago" },
  {
    value: "America/Santo_Domingo",
    text_value: "(GMT -04:00) America, Santo Domingo",
  },
  { value: "America/Sao_Paulo", text_value: "(GMT -03:00) America, Sao Paulo" },
  {
    value: "America/Scoresbysund",
    text_value: "(GMT -01:00) America, Scoresbysund",
  },
  { value: "America/Sitka", text_value: "(GMT -08:00) America, Sitka" },
  {
    value: "America/St_Barthelemy",
    text_value: "(GMT -04:00) America, St. Barthelemy",
  },
  { value: "America/St_Johns", text_value: "(GMT -02:30) America, St. Johns" },
  { value: "America/St_Kitts", text_value: "(GMT -04:00) America, St. Kitts" },
  { value: "America/St_Lucia", text_value: "(GMT -04:00) America, St. Lucia" },
  {
    value: "America/St_Thomas",
    text_value: "(GMT -04:00) America, St. Thomas",
  },
  {
    value: "America/St_Vincent",
    text_value: "(GMT -04:00) America, St. Vincent",
  },
  {
    value: "America/Swift_Current",
    text_value: "(GMT -06:00) America, Swift Current",
  },
  {
    value: "America/Tegucigalpa",
    text_value: "(GMT -06:00) America, Tegucigalpa",
  },
  { value: "America/Thule", text_value: "(GMT -03:00) America, Thule" },
  {
    value: "America/Thunder_Bay",
    text_value: "(GMT -04:00) America, Thunder Bay",
  },
  { value: "America/Tijuana", text_value: "(GMT -07:00) America, Tijuana" },
  { value: "America/Toronto", text_value: "(GMT -04:00) America, Toronto" },
  { value: "America/Tortola", text_value: "(GMT -04:00) America, Tortola" },
  { value: "America/Vancouver", text_value: "(GMT -07:00) America, Vancouver" },
  {
    value: "America/Whitehorse",
    text_value: "(GMT -07:00) America, Whitehorse",
  },
  { value: "America/Winnipeg", text_value: "(GMT -05:00) America, Winnipeg" },
  { value: "America/Yakutat", text_value: "(GMT -08:00) America, Yakutat" },
  {
    value: "America/Yellowknife",
    text_value: "(GMT -06:00) America, Yellowknife",
  },
  { value: "Antarctica/Casey", text_value: "(GMT +11:00) Antarctica, Casey" },
  { value: "Antarctica/Davis", text_value: "(GMT +07:00) Antarctica, Davis" },
  {
    value: "Antarctica/DumontDUrville",
    text_value: "(GMT +10:00) Antarctica, DumontDUrville",
  },
  {
    value: "Antarctica/Macquarie",
    text_value: "(GMT +11:00) Antarctica, Macquarie",
  },
  { value: "Antarctica/Mawson", text_value: "(GMT +05:00) Antarctica, Mawson" },
  {
    value: "Antarctica/McMurdo",
    text_value: "(GMT +13:00) Antarctica, McMurdo",
  },
  { value: "Antarctica/Palmer", text_value: "(GMT -03:00) Antarctica, Palmer" },
  {
    value: "Antarctica/Rothera",
    text_value: "(GMT -03:00) Antarctica, Rothera",
  },
  { value: "Antarctica/Syowa", text_value: "(GMT +03:00) Antarctica, Syowa" },
  { value: "Antarctica/Troll", text_value: "(GMT -00:00) Antarctica, Troll" },
  { value: "Antarctica/Vostok", text_value: "(GMT +06:00) Antarctica, Vostok" },
  {
    value: "Arctic/Longyearbyen",
    text_value: "(GMT +01:00) Arctic, Longyearbyen",
  },
  { value: "Asia/Aden", text_value: "(GMT +03:00) Asia, Aden" },
  { value: "Asia/Almaty", text_value: "(GMT +06:00) Asia, Almaty" },
  { value: "Asia/Amman", text_value: "(GMT +02:00) Asia, Amman" },
  { value: "Asia/Anadyr", text_value: "(GMT +12:00) Asia, Anadyr" },
  { value: "Asia/Aqtau", text_value: "(GMT +05:00) Asia, Aqtau" },
  { value: "Asia/Aqtobe", text_value: "(GMT +05:00) Asia, Aqtobe" },
  { value: "Asia/Ashgabat", text_value: "(GMT +05:00) Asia, Ashgabat" },
  { value: "Asia/Atyrau", text_value: "(GMT +05:00) Asia, Atyrau" },
  { value: "Asia/Baghdad", text_value: "(GMT +03:00) Asia, Baghdad" },
  { value: "Asia/Bahrain", text_value: "(GMT +03:00) Asia, Bahrain" },
  { value: "Asia/Baku", text_value: "(GMT +04:00) Asia, Baku" },
  { value: "Asia/Bangkok", text_value: "(GMT +07:00) Asia, Bangkok" },
  { value: "Asia/Barnaul", text_value: "(GMT +07:00) Asia, Barnaul" },
  { value: "Asia/Beirut", text_value: "(GMT +02:00) Asia, Beirut" },
  { value: "Asia/Bishkek", text_value: "(GMT +06:00) Asia, Bishkek" },
  { value: "Asia/Brunei", text_value: "(GMT +08:00) Asia, Brunei" },
  { value: "Asia/Chita", text_value: "(GMT +09:00) Asia, Chita" },
  { value: "Asia/Choibalsan", text_value: "(GMT +08:00) Asia, Choibalsan" },
  { value: "Asia/Colombo", text_value: "(GMT +05:30) Asia, Colombo" },
  { value: "Asia/Damascus", text_value: "(GMT +02:00) Asia, Damascus" },
  { value: "Asia/Dhaka", text_value: "(GMT +06:00) Asia, Dhaka" },
  { value: "Asia/Dili", text_value: "(GMT +09:00) Asia, Dili" },
  { value: "Asia/Dubai", text_value: "(GMT +04:00) Asia, Dubai" },
  { value: "Asia/Dushanbe", text_value: "(GMT +05:00) Asia, Dushanbe" },
  { value: "Asia/Famagusta", text_value: "(GMT +02:00) Asia, Famagusta" },
  { value: "Asia/Gaza", text_value: "(GMT +02:00) Asia, Gaza" },
  { value: "Asia/Hebron", text_value: "(GMT +02:00) Asia, Hebron" },
  { value: "Asia/Ho_Chi_Minh", text_value: "(GMT +07:00) Asia, Ho Chi Minh" },
  { value: "Asia/Hong_Kong", text_value: "(GMT +08:00) Asia, Hong Kong" },
  { value: "Asia/Hovd", text_value: "(GMT +07:00) Asia, Hovd" },
  { value: "Asia/Irkutsk", text_value: "(GMT +08:00) Asia, Irkutsk" },
  { value: "Asia/Jakarta", text_value: "(GMT +07:00) Asia, Jakarta" },
  { value: "Asia/Jayapura", text_value: "(GMT +09:00) Asia, Jayapura" },
  { value: "Asia/Jerusalem", text_value: "(GMT +02:00) Asia, Jerusalem" },
  { value: "Asia/Kabul", text_value: "(GMT +04:30) Asia, Kabul" },
  { value: "Asia/Kamchatka", text_value: "(GMT +12:00) Asia, Kamchatka" },
  { value: "Asia/Karachi", text_value: "(GMT +05:00) Asia, Karachi" },
  { value: "Asia/Kathmandu", text_value: "(GMT +05:45) Asia, Kathmandu" },
  { value: "Asia/Khandyga", text_value: "(GMT +09:00) Asia, Khandyga" },
  { value: "Asia/Kolkata", text_value: "(GMT +05:30) Asia, Kolkata" },
  { value: "Asia/Krasnoyarsk", text_value: "(GMT +07:00) Asia, Krasnoyarsk" },
  { value: "Asia/Kuala_Lumpur", text_value: "(GMT +08:00) Asia, Kuala Lumpur" },
  { value: "Asia/Kuching", text_value: "(GMT +08:00) Asia, Kuching" },
  { value: "Asia/Kuwait", text_value: "(GMT +03:00) Asia, Kuwait" },
  { value: "Asia/Macau", text_value: "(GMT +08:00) Asia, Macau" },
  { value: "Asia/Magadan", text_value: "(GMT +11:00) Asia, Magadan" },
  { value: "Asia/Makassar", text_value: "(GMT +08:00) Asia, Makassar" },
  { value: "Asia/Manila", text_value: "(GMT +08:00) Asia, Manila" },
  { value: "Asia/Muscat", text_value: "(GMT +04:00) Asia, Muscat" },
  { value: "Asia/Nicosia", text_value: "(GMT +02:00) Asia, Nicosia" },
  { value: "Asia/Novokuznetsk", text_value: "(GMT +07:00) Asia, Novokuznetsk" },
  { value: "Asia/Novosibirsk", text_value: "(GMT +07:00) Asia, Novosibirsk" },
  { value: "Asia/Omsk", text_value: "(GMT +06:00) Asia, Omsk" },
  { value: "Asia/Oral", text_value: "(GMT +05:00) Asia, Oral" },
  { value: "Asia/Phnom_Penh", text_value: "(GMT +07:00) Asia, Phnom Penh" },
  { value: "Asia/Pontianak", text_value: "(GMT +07:00) Asia, Pontianak" },
  { value: "Asia/Pyongyang", text_value: "(GMT +09:00) Asia, Pyongyang" },
  { value: "Asia/Qatar", text_value: "(GMT +03:00) Asia, Qatar" },
  { value: "Asia/Qostanay", text_value: "(GMT +06:00) Asia, Qostanay" },
  { value: "Asia/Qyzylorda", text_value: "(GMT +05:00) Asia, Qyzylorda" },
  { value: "Asia/Riyadh", text_value: "(GMT +03:00) Asia, Riyadh" },
  { value: "Asia/Sakhalin", text_value: "(GMT +11:00) Asia, Sakhalin" },
  { value: "Asia/Samarkand", text_value: "(GMT +05:00) Asia, Samarkand" },
  { value: "Asia/Seoul", text_value: "(GMT +09:00) Asia, Seoul" },
  { value: "Asia/Shanghai", text_value: "(GMT +08:00) Asia, Shanghai" },
  { value: "Asia/Singapore", text_value: "(GMT +08:00) Asia, Singapore" },
  {
    value: "Asia/Srednekolymsk",
    text_value: "(GMT +11:00) Asia, Srednekolymsk",
  },
  { value: "Asia/Taipei", text_value: "(GMT +08:00) Asia, Taipei" },
  { value: "Asia/Tashkent", text_value: "(GMT +05:00) Asia, Tashkent" },
  { value: "Asia/Tbilisi", text_value: "(GMT +04:00) Asia, Tbilisi" },
  { value: "Asia/Tehran", text_value: "(GMT +03:30) Asia, Tehran" },
  { value: "Asia/Thimphu", text_value: "(GMT +06:00) Asia, Thimphu" },
  { value: "Asia/Tokyo", text_value: "(GMT +09:00) Asia, Tokyo" },
  { value: "Asia/Tomsk", text_value: "(GMT +07:00) Asia, Tomsk" },
  { value: "Asia/Ulaanbaatar", text_value: "(GMT +08:00) Asia, Ulaanbaatar" },
  { value: "Asia/Urumqi", text_value: "(GMT +06:00) Asia, Urumqi" },
  { value: "Asia/Ust-Nera", text_value: "(GMT +10:00) Asia, Ust-Nera" },
  { value: "Asia/Vientiane", text_value: "(GMT +07:00) Asia, Vientiane" },
  { value: "Asia/Vladivostok", text_value: "(GMT +10:00) Asia, Vladivostok" },
  { value: "Asia/Yakutsk", text_value: "(GMT +09:00) Asia, Yakutsk" },
  { value: "Asia/Yangon", text_value: "(GMT +06:30) Asia, Yangon" },
  {
    value: "Asia/Yekaterinburg",
    text_value: "(GMT +05:00) Asia, Yekaterinburg",
  },
  { value: "Asia/Yerevan", text_value: "(GMT +04:00) Asia, Yerevan" },
  { value: "Atlantic/Azores", text_value: "(GMT -01:00) Atlantic, Azores" },
  { value: "Atlantic/Bermuda", text_value: "(GMT -03:00) Atlantic, Bermuda" },
  { value: "Atlantic/Canary", text_value: "(GMT -00:00) Atlantic, Canary" },
  {
    value: "Atlantic/Cape_Verde",
    text_value: "(GMT -01:00) Atlantic, Cape Verde",
  },
  { value: "Atlantic/Faroe", text_value: "(GMT -00:00) Atlantic, Faroe" },
  { value: "Atlantic/Madeira", text_value: "(GMT -00:00) Atlantic, Madeira" },
  {
    value: "Atlantic/Reykjavik",
    text_value: "(GMT -00:00) Atlantic, Reykjavik",
  },
  {
    value: "Atlantic/South_Georgia",
    text_value: "(GMT -02:00) Atlantic, South Georgia",
  },
  {
    value: "Atlantic/St_Helena",
    text_value: "(GMT -00:00) Atlantic, St. Helena",
  },
  { value: "Atlantic/Stanley", text_value: "(GMT -03:00) Atlantic, Stanley" },
  {
    value: "Australia/Adelaide",
    text_value: "(GMT +10:30) Australia, Adelaide",
  },
  {
    value: "Australia/Brisbane",
    text_value: "(GMT +10:00) Australia, Brisbane",
  },
  {
    value: "Australia/Broken_Hill",
    text_value: "(GMT +10:30) Australia, Broken Hill",
  },
  { value: "Australia/Currie", text_value: "(GMT +11:00) Australia, Currie" },
  { value: "Australia/Darwin", text_value: "(GMT +09:30) Australia, Darwin" },
  { value: "Australia/Eucla", text_value: "(GMT +08:45) Australia, Eucla" },
  { value: "Australia/Hobart", text_value: "(GMT +11:00) Australia, Hobart" },
  {
    value: "Australia/Lindeman",
    text_value: "(GMT +10:00) Australia, Lindeman",
  },
  {
    value: "Australia/Lord_Howe",
    text_value: "(GMT +11:00) Australia, Lord Howe",
  },
  {
    value: "Australia/Melbourne",
    text_value: "(GMT +11:00) Australia, Melbourne",
  },
  { value: "Australia/Perth", text_value: "(GMT +08:00) Australia, Perth" },
  { value: "Australia/Sydney", text_value: "(GMT +11:00) Australia, Sydney" },
  { value: "Europe/Amsterdam", text_value: "(GMT +01:00) Europe, Amsterdam" },
  { value: "Europe/Andorra", text_value: "(GMT +01:00) Europe, Andorra" },
  { value: "Europe/Astrakhan", text_value: "(GMT +04:00) Europe, Astrakhan" },
  { value: "Europe/Athens", text_value: "(GMT +02:00) Europe, Athens" },
  { value: "Europe/Belgrade", text_value: "(GMT +01:00) Europe, Belgrade" },
  { value: "Europe/Berlin", text_value: "(GMT +01:00) Europe, Berlin" },
  { value: "Europe/Bratislava", text_value: "(GMT +01:00) Europe, Bratislava" },
  { value: "Europe/Brussels", text_value: "(GMT +01:00) Europe, Brussels" },
  { value: "Europe/Bucharest", text_value: "(GMT +02:00) Europe, Bucharest" },
  { value: "Europe/Budapest", text_value: "(GMT +01:00) Europe, Budapest" },
  { value: "Europe/Busingen", text_value: "(GMT +01:00) Europe, Busingen" },
  { value: "Europe/Chisinau", text_value: "(GMT +02:00) Europe, Chisinau" },
  { value: "Europe/Copenhagen", text_value: "(GMT +01:00) Europe, Copenhagen" },
  { value: "Europe/Dublin", text_value: "(GMT -00:00) Europe, Dublin" },
  { value: "Europe/Gibraltar", text_value: "(GMT +01:00) Europe, Gibraltar" },
  { value: "Europe/Guernsey", text_value: "(GMT -00:00) Europe, Guernsey" },
  { value: "Europe/Helsinki", text_value: "(GMT +02:00) Europe, Helsinki" },
  {
    value: "Europe/Isle_of_Man",
    text_value: "(GMT -00:00) Europe, Isle of Man",
  },
  { value: "Europe/Istanbul", text_value: "(GMT +03:00) Europe, Istanbul" },
  { value: "Europe/Jersey", text_value: "(GMT -00:00) Europe, Jersey" },
  {
    value: "Europe/Kaliningrad",
    text_value: "(GMT +02:00) Europe, Kaliningrad",
  },
  { value: "Europe/Kiev", text_value: "(GMT +02:00) Europe, Kiev" },
  { value: "Europe/Kirov", text_value: "(GMT +03:00) Europe, Kirov" },
  { value: "Europe/Lisbon", text_value: "(GMT -00:00) Europe, Lisbon" },
  { value: "Europe/Ljubljana", text_value: "(GMT +01:00) Europe, Ljubljana" },
  { value: "Europe/London", text_value: "(GMT -00:00) Europe, London" },
  { value: "Europe/Luxembourg", text_value: "(GMT +01:00) Europe, Luxembourg" },
  { value: "Europe/Madrid", text_value: "(GMT +01:00) Europe, Madrid" },
  { value: "Europe/Malta", text_value: "(GMT +01:00) Europe, Malta" },
  { value: "Europe/Mariehamn", text_value: "(GMT +02:00) Europe, Mariehamn" },
  { value: "Europe/Minsk", text_value: "(GMT +03:00) Europe, Minsk" },
  { value: "Europe/Monaco", text_value: "(GMT +01:00) Europe, Monaco" },
  { value: "Europe/Moscow", text_value: "(GMT +03:00) Europe, Moscow" },
  { value: "Europe/Oslo", text_value: "(GMT +01:00) Europe, Oslo" },
  { value: "Europe/Paris", text_value: "(GMT +01:00) Europe, Paris" },
  { value: "Europe/Podgorica", text_value: "(GMT +01:00) Europe, Podgorica" },
  { value: "Europe/Prague", text_value: "(GMT +01:00) Europe, Prague" },
  { value: "Europe/Riga", text_value: "(GMT +02:00) Europe, Riga" },
  { value: "Europe/Rome", text_value: "(GMT +01:00) Europe, Rome" },
  { value: "Europe/Samara", text_value: "(GMT +04:00) Europe, Samara" },
  { value: "Europe/San_Marino", text_value: "(GMT +01:00) Europe, San Marino" },
  { value: "Europe/Sarajevo", text_value: "(GMT +01:00) Europe, Sarajevo" },
  { value: "Europe/Saratov", text_value: "(GMT +04:00) Europe, Saratov" },
  { value: "Europe/Simferopol", text_value: "(GMT +03:00) Europe, Simferopol" },
  { value: "Europe/Skopje", text_value: "(GMT +01:00) Europe, Skopje" },
  { value: "Europe/Sofia", text_value: "(GMT +02:00) Europe, Sofia" },
  { value: "Europe/Stockholm", text_value: "(GMT +01:00) Europe, Stockholm" },
  { value: "Europe/Tallinn", text_value: "(GMT +02:00) Europe, Tallinn" },
  { value: "Europe/Tirane", text_value: "(GMT +01:00) Europe, Tirane" },
  { value: "Europe/Ulyanovsk", text_value: "(GMT +04:00) Europe, Ulyanovsk" },
  { value: "Europe/Uzhgorod", text_value: "(GMT +02:00) Europe, Uzhgorod" },
  { value: "Europe/Vaduz", text_value: "(GMT +01:00) Europe, Vaduz" },
  { value: "Europe/Vatican", text_value: "(GMT +01:00) Europe, Vatican" },
  { value: "Europe/Vienna", text_value: "(GMT +01:00) Europe, Vienna" },
  { value: "Europe/Vilnius", text_value: "(GMT +02:00) Europe, Vilnius" },
  { value: "Europe/Volgograd", text_value: "(GMT +04:00) Europe, Volgograd" },
  { value: "Europe/Warsaw", text_value: "(GMT +01:00) Europe, Warsaw" },
  { value: "Europe/Zagreb", text_value: "(GMT +01:00) Europe, Zagreb" },
  { value: "Europe/Zaporozhye", text_value: "(GMT +02:00) Europe, Zaporozhye" },
  { value: "Europe/Zurich", text_value: "(GMT +01:00) Europe, Zurich" },
  {
    value: "Indian/Antananarivo",
    text_value: "(GMT +03:00) Indian, Antananarivo",
  },
  { value: "Indian/Chagos", text_value: "(GMT +06:00) Indian, Chagos" },
  { value: "Indian/Christmas", text_value: "(GMT +07:00) Indian, Christmas" },
  { value: "Indian/Cocos", text_value: "(GMT +06:30) Indian, Cocos" },
  { value: "Indian/Comoro", text_value: "(GMT +03:00) Indian, Comoro" },
  { value: "Indian/Kerguelen", text_value: "(GMT +05:00) Indian, Kerguelen" },
  { value: "Indian/Mahe", text_value: "(GMT +04:00) Indian, Mahe" },
  { value: "Indian/Maldives", text_value: "(GMT +05:00) Indian, Maldives" },
  { value: "Indian/Mauritius", text_value: "(GMT +04:00) Indian, Mauritius" },
  { value: "Indian/Mayotte", text_value: "(GMT +03:00) Indian, Mayotte" },
  { value: "Indian/Reunion", text_value: "(GMT +04:00) Indian, Reunion" },
  { value: "Pacific/Apia", text_value: "(GMT +14:00) Pacific, Apia" },
  { value: "Pacific/Auckland", text_value: "(GMT +13:00) Pacific, Auckland" },
  {
    value: "Pacific/Bougainville",
    text_value: "(GMT +11:00) Pacific, Bougainville",
  },
  { value: "Pacific/Chatham", text_value: "(GMT +13:45) Pacific, Chatham" },
  { value: "Pacific/Chuuk", text_value: "(GMT +10:00) Pacific, Chuuk" },
  { value: "Pacific/Easter", text_value: "(GMT -05:00) Pacific, Easter" },
  { value: "Pacific/Efate", text_value: "(GMT +11:00) Pacific, Efate" },
  { value: "Pacific/Enderbury", text_value: "(GMT +13:00) Pacific, Enderbury" },
  { value: "Pacific/Fakaofo", text_value: "(GMT +13:00) Pacific, Fakaofo" },
  { value: "Pacific/Fiji", text_value: "(GMT +12:00) Pacific, Fiji" },
  { value: "Pacific/Funafuti", text_value: "(GMT +12:00) Pacific, Funafuti" },
  { value: "Pacific/Galapagos", text_value: "(GMT -06:00) Pacific, Galapagos" },
  { value: "Pacific/Gambier", text_value: "(GMT -09:00) Pacific, Gambier" },
  {
    value: "Pacific/Guadalcanal",
    text_value: "(GMT +11:00) Pacific, Guadalcanal",
  },
  { value: "Pacific/Guam", text_value: "(GMT +10:00) Pacific, Guam" },
  { value: "Pacific/Honolulu", text_value: "(GMT -10:00) Pacific, Honolulu" },
  {
    value: "Pacific/Kiritimati",
    text_value: "(GMT +14:00) Pacific, Kiritimati",
  },
  { value: "Pacific/Kosrae", text_value: "(GMT +11:00) Pacific, Kosrae" },
  { value: "Pacific/Kwajalein", text_value: "(GMT +12:00) Pacific, Kwajalein" },
  { value: "Pacific/Majuro", text_value: "(GMT +12:00) Pacific, Majuro" },
  { value: "Pacific/Marquesas", text_value: "(GMT -09:30) Pacific, Marquesas" },
  { value: "Pacific/Midway", text_value: "(GMT -11:00) Pacific, Midway" },
  { value: "Pacific/Nauru", text_value: "(GMT +12:00) Pacific, Nauru" },
  { value: "Pacific/Niue", text_value: "(GMT -11:00) Pacific, Niue" },
  { value: "Pacific/Norfolk", text_value: "(GMT +12:00) Pacific, Norfolk" },
  { value: "Pacific/Noumea", text_value: "(GMT +11:00) Pacific, Noumea" },
  { value: "Pacific/Pago_Pago", text_value: "(GMT -11:00) Pacific, Pago Pago" },
  { value: "Pacific/Palau", text_value: "(GMT +09:00) Pacific, Palau" },
  { value: "Pacific/Pitcairn", text_value: "(GMT -08:00) Pacific, Pitcairn" },
  { value: "Pacific/Pohnpei", text_value: "(GMT +11:00) Pacific, Pohnpei" },
  {
    value: "Pacific/Port_Moresby",
    text_value: "(GMT +10:00) Pacific, Port Moresby",
  },
  { value: "Pacific/Rarotonga", text_value: "(GMT -10:00) Pacific, Rarotonga" },
  { value: "Pacific/Saipan", text_value: "(GMT +10:00) Pacific, Saipan" },
  { value: "Pacific/Tahiti", text_value: "(GMT -10:00) Pacific, Tahiti" },
  { value: "Pacific/Tarawa", text_value: "(GMT +12:00) Pacific, Tarawa" },
  { value: "Pacific/Tongatapu", text_value: "(GMT +13:00) Pacific, Tongatapu" },
  { value: "Pacific/Wake", text_value: "(GMT +12:00) Pacific, Wake" },
  { value: "Pacific/Wallis", text_value: "(GMT +12:00) Pacific, Wallis" },
  { value: "UTC", text_value: "(GMT -00:00) UTC  " },
];

export const convertSecondsToTime = secondsTotal => {
  if (typeof secondsTotal != "number") return secondsTotal;

  let hours = Math.floor(secondsTotal / 3600);
  let minutes = Math.floor((secondsTotal - hours * 3600) / 60);
  let seconds = secondsTotal - hours * 3600 - minutes * 60;

  let timeString =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0");

  return timeString;
};

/**
 *
 */
export const AgentStatus = Object.freeze({
  OFFLINE: 0,
  IDLE: 1,
  ON_CALL: 2,
  ACW: 3,
  ON_MANUAL_CALL: 4,
  ON_MANUAL_CALL_CONNECTED: 5,
  ON_WORK_BREAK: 6,
  ON_CONSULT: 7,
  ONLINE: 10,
  ON_MANUAL_CALL_ACW: 21,
  MANUAL_CALL_CONNECTED: 22,
  CONSULT_HOLD: 111,
  CONSULT_CONNECTED: 222,
  MANUAL_MODE: "manual",
});

export const CallStatusWithName = Object.freeze(
  {
    7: "Finalizadas",
    8: "Falha",
    5: "Não atendidas",
    9: "CP - Pós",
    15: "CP - Pré",
    6: "Abandonadas",
  },
);

export const CallStatus = Object.freeze(
  {
    DIALING: 1,
    ANSWERED: 2,
    CONNECTED: 3,
    HANGUP: 4,
    NOT_ANSWERED: 5,
    ABANDONED: 6,
    ENDED: 7,
    FAILED: 8,
    ABANDONED_DUE_AMD: 9,
    CONSULT_HOLD: 10,
    CONSULT_CONNECTED: 11,
    CONSULT_EXITED: 12,
    CONSULT_TRANSFERRED: 13,
    ABANDONED_OUT_OF_SERVICE: 14,
    NOT_ANSWERED_DUE_PROGRESS_AMD: 15,

    // TEMPORÁRIO
    RECEIVING_EXTERNAL_CALL: 1001,
    RECEIVING_INTERNAL_CALL: 1002,
    RECEIVING_TRANSFERENCE: 1003,
    TRYING_INTERNAL_CALL: 1004,

    INTERNAL_CALL_ANSWERED: 1020,
    INTERNAL_CALL_FAILED: 1021,
    CONSULT_ANSWERED: 1030,
    TRANSFERENCE_CONNECTED: 1031,
    CONSULT_TRYING: 1032,
  },
);

export const AMDConsts = Object.freeze({
  NOT_ENABLED: -1,
  MAILBOX: 0,
  HUMAN: 1,
  SILENCE: 2,
  ERROR: 10,
});

export const SystemQualifications = Object.freeze({
  // OverwrittenCall: -1, // The end customer will have doubts about this status, so we removed temporarily
  NotQualified: -2,
  VoiceMessage: -3,
  MuteCall: -4,
  AcwTimeout: -5,
});

export const translateSystemQualificationNames = Object.freeze({
  // OverwrittenCall: '',
  NotQualified: "Não qualificado",
  VoiceMessage: "Caixa-Postal",
  MuteCall: "Chamada Muda",
  AcwTimeout: "Tempo limite de pós-atendimento atingido",
});

export const CallMode = Object.freeze({
  MANUAL: "manual",
  MANUAL_ACW: "acw_manual",
  DIALER: "dialer",
  RECEPTIVE: "receptive",
  CONSULT: "consult",
});

export const ReceptiveStatus = Object.freeze({
  IN_QUEUE: "in_queue",
  ABANDONED: "abandoned",
  CONNECTED: "connected",
  ENDED: "ended",
});

export const IntervalsReturnType = Object.freeze({
  FLEXIBLE_RETURN: 1,
  AUTOMATIC_RETURN: 2,
  REQUEST_RETURN: 3,
});

export function initiateCollapses() {
  const collapses = document.querySelectorAll(".navbar-nav .collapse");

  collapses.forEach(collapse => {
    const collapseInstance = new Collapse(collapse, {
      toggle: false,
    });

    collapse.addEventListener("show.bs.collapse", e => {
      e.stopPropagation();

      const closestCollapse = collapse.parentElement.closest(".collapse");
      const siblingCollapses = closestCollapse.querySelectorAll(".collapse");

      siblingCollapses.forEach(siblingCollapse => {
        const siblingCollapseInstance = Collapse.getInstance(siblingCollapse);

        if (siblingCollapseInstance === collapseInstance) {
          return;
        }

        siblingCollapseInstance.hide();
      });
    });

    collapse.addEventListener("hide.bs.collapse", e => {
      e.stopPropagation();

      const childCollapses = collapse.querySelectorAll(".collapse");

      childCollapses.forEach(childCollapse => {
        const childCollapseInstance = Collapse.getInstance(childCollapse);

        childCollapseInstance.hide();
      });
    });
  });
}

export function initiateDropdowns() {
  const selectors =
    ".navbar .dropup, .navbar .dropend, .navbar .dropdown, .navbar .dropstart";
  const dropdowns = document.querySelectorAll(selectors);
  const NAVBAR_BREAKPOINT = 767;

  if (window.innerWidth > NAVBAR_BREAKPOINT) {
    dropdowns.forEach(dropdown => {
      const toggle = dropdown.querySelector("[data-bs-toggle=\"dropdown\"]");
      const instance = new Dropdown(toggle);

      dropdown.addEventListener("mouseenter", () => {
        instance.show();
      });

      dropdown.addEventListener("mouseleave", () => {
        instance.hide();
      });
    });
  }
}

export function formatSeconds(
  seconds,
  enableSeconds = true,
  format = "h[h] m[m] s[s]",
) {
  const duration = moment.duration(seconds, "seconds");

  if (enableSeconds) {
    return duration.format(format, { trim: "both" });
  }
  return duration.format("h[h] m[m]", { trim: "both" });
}

export function formatSecondsDateForTables(date) {
  const duration = moment.duration(date, "seconds");
  const timeUnits = {
    d: Math.floor(duration.asDays()),
    h: duration.hours(),
    m: duration.minutes(),
    s: duration.seconds(),
  };

  let formattedTime = "";
  for (const [unit, value] of Object.entries(timeUnits)) {
    if (value <= 0) continue;
    formattedTime += `${value}${unit} `;
  }

  if (!formattedTime) return "-";
  return formattedTime.trim();
}

export function formatSecondsToMMSS(seconds, showHours = false) {
  const duration = moment.duration(seconds, "seconds");
  const hours = duration.hours();
  const minutes = duration.minutes();
  const secondsRemaining = duration.seconds();
  let formattedTimeStamped = "";

  if (hours > 0 || showHours) {
    formattedTimeStamped = "HH:mm:ss";
  } else {
    formattedTimeStamped = "mm:ss";
  }
  const formattedTime = moment()
    .hours(hours)
    .minutes(minutes)
    .seconds(secondsRemaining)
    .format(formattedTimeStamped);

  return formattedTime;
}

export function formatPhoneNumber(phoneNumber) {
  if (isNaN(parseInt(phoneNumber))) return phoneNumber;
  const phoneStr = phoneNumber.toString();
  if (phoneStr.startsWith("55") && phoneStr.slice(2, 6) === "0800") {
    if (phoneStr.length === 13) return `0800 ${phoneStr.slice(6, 9)} ${phoneStr.slice(9, 13)}`;
    return `0800 ${phoneStr.slice(6, 10)} ${phoneStr.slice(10, 14)}`;
  }
  if (phoneStr.startsWith("55") && phoneStr.slice(2, 5) === "800") {
    if (phoneStr.length === 12) return `0800 ${phoneStr.slice(5, 8)} ${phoneStr.slice(8, 12)}`;
    return `0800 ${phoneStr.slice(5, 9)} ${phoneStr.slice(9, 13)}`;
  }
  const mask = new Mask({
    mask: [
      "(##) ####-####",
      "(##) #####-####",
      "+## (##) ####-####",
      "+## (##) #####-####",
    ],
  });

  return mask.masked(phoneNumber);
}

export function generateTooltip(innerHTML, element, placement = "top", config) {
  let tooltip = document.createElement("div");
  tooltip.innerHTML = innerHTML;
  return new Tooltip(element, {
    html: true,
    placement,
    title: innerHTML,
    ...config,
  });
}

export function numberValidation(number) {
  number = number.replace(/\D/g, "");

  //valids DDD
  var allValidDDDs = [
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    21,
    22,
    24,
    27,
    28,
    31,
    32,
    33,
    34,
    35,
    37,
    38,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    51,
    53,
    54,
    55,
    61,
    62,
    64,
    63,
    65,
    66,
    67,
    68,
    69,
    71,
    73,
    74,
    75,
    77,
    79,
    81,
    82,
    83,
    84,
    85,
    86,
    87,
    88,
    89,
    91,
    92,
    93,
    94,
    95,
    96,
    97,
    98,
    99,
  ];

  //verify if has right amount of numbers
  if (!(number.length >= 10 && number.length <= 13)) {
    return "O número precisa conter pelo menos 10 dígitos.";
  }

  //verify if is a wrong number
  if (number.match(/\b(\d)\1+\b/)) {
    return "Por favor, digite um número válido.";
  }

  //If has 11 digits, verify if starts with 9
  if (
    (number.length == 11 && parseInt(number.substring(2, 3)) != 9) ||
    (number.length == 13 && parseInt(number.substring(4, 5)) != 9)
  ) {
    return "O número precisa iniciar com o dígito 9.";
  }

  //verify if is a valid DDD
  if (!allValidDDDs.includes(parseInt(number[0] + number[1]))) {
    return "Por favor, digite um DDD válido.";
  }

  return "";
}

const hangupCauses = [];
hangupCauses[0] = "Encerramento normal";
hangupCauses[1] = "Número não existe";
hangupCauses[3] = "Recusada pela operadora";
hangupCauses[4] = "Recusada pela operadora";
hangupCauses[16] = "Encerramento normal";
hangupCauses[17] = "Número ocupado";
hangupCauses[18] = "Tempo limite atingido";
hangupCauses[19] = "Número não atende";
hangupCauses[20] = "Recusada pela operadora";
hangupCauses[21] = "Recusada pela operadora";
hangupCauses[22] = "Recusada pela operadora";
hangupCauses[24] = "Recusada pela operadora";
hangupCauses[25] = "Recusada pela operadora";
hangupCauses[27] = "Recusada pela operadora";
hangupCauses[28] = "Número inválido";
hangupCauses[29] = "Recusada pela operadora";
hangupCauses[31] = "Encerramento normal";
hangupCauses[34] = "Recusada pela operadora";
hangupCauses[38] = "Recusada pela operadora";
hangupCauses[41] = "Recusada pela operadora";
hangupCauses[42] = "Recusada pela operadora";
hangupCauses[44] = "Recusada pela operadora";
hangupCauses[47] = "Recusada pela operadora";
hangupCauses[52] = "Caixa postal descartada pré atendimento 3C+";
hangupCauses[55] = "Recusada pela operadora";
hangupCauses[57] = "Recusada pela operadora";
hangupCauses[58] = "Recusada pela operadora";
hangupCauses[63] = "Recusada pela operadora";
hangupCauses[65] = "Recusada pela operadora";
hangupCauses[66] = "Recusada pela operadora";
hangupCauses[70] = "Recusada pela operadora";
hangupCauses[79] = "Recusada pela operadora";
hangupCauses[88] = "Recusada pela operadora";
hangupCauses[100] = "Recusada pela operadora";
hangupCauses[102] = "Tempo limite atingido";
hangupCauses[108] = "Recusada pela operadora";
hangupCauses[111] = "Recusada pela operadora";
hangupCauses[121] = "Recusada pela operadora";
hangupCauses[124] = "Recusada pela operadora";
hangupCauses[126] = "Recusada pela operadora";
hangupCauses[127] = "Recusada pela operadora";
hangupCauses[130] = "Ligação não realizada - Filtro inteligente 3CPlus";

export default hangupCauses;

export const WhatsappMediaTypes = Object.freeze({
  audio: ["audio/aac", "audio/mp4", "audio/amr", "audio/mpeg", "audio/ogg"],
  image: ["image/jpeg", "image/png"],
  // sticker: ["image/webp"]
  video: ["video/mp4", "video/3gpp"],
});

export function track(event, companyDomain, companyId, data) {
  try {
    const key = process.env.VUE_APP_AUTNND23;

    if (key === undefined || key.length === 0) {
      return;
    }

    fetch("https://app.3c.plus/event-ingestor", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": key,
      },
      body: JSON.stringify(data),
    }).then(response => {console.log(response.body)});
  } catch (e) {
    console.log("[TRACK] error", e);
  }
}

export function formatDate(date, format) {
  return moment(date).format(format);
}

export function diffDate(startDate, format, endDate = null) {
  const date = endDate ? endDate : moment();
  return moment(startDate).diff(date, format);
}

export function formatTimestamp(timestamp, format) {
  return moment.unix(timestamp).format(format);
}

export function applyDrag(arr, dragResult) {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
}

export function generateItems(count, creator) {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
}

export function makeLinkTag(value, target = "_blank") {
  let patternStr = /^(https?:\/\/[^\s]+)/;
  let pattern = new RegExp(patternStr, "i");
  if (pattern.test(value)) {
    return `<a href="${value}" target="${target}">${value}</a>`;
  }
  return value;
}

export function countAgentsByStatus(agentsData, status) {
  return Object.values(agentsData).reduce((acc, curr) => {
    if (curr.status === status) acc++;

    return acc;
  }, 0);
}

export function mailingMessageCounter(string) {
  let count = 0;

  for (let i = 0; i < string.length; i++) {
    const caractere = string[i];

    if (/[a-zA-Z0-9\s,.\-!?;:]/.test(caractere)) {
      count += 1;
    } else {
      count += 2;
    }
  }

  return count;
}

export function cutMailingPreviewMessage(string) {
  let count = 0;
  let resultString = "";

  for (const character of string) {
    count += /[a-zA-Z0-9\s,.\-!?;:]/.test(character) ? 1 : 2;

    if (count <= 160) {
      resultString += character;
    } else {
      break;
    }
  }
  return resultString;
}

export function isValidImageUrl(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      img.src = url;
      resolve(img);
    };
    img.onerror = () => {
      reject(false);
    };
  });
}

export function handleImageError(event) {
  event.target.src = require("@assets/img/avatars/profiles/nome-user.png");
}

export function createAndDownloadFileFromBlob(blob, type, fileName) {
  const url = window.URL.createObjectURL(new Blob([blob], { type: type }));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export function formatInternationalPhone(number) {
  let phoneNumber = number;

  if (!phoneNumber.startsWith("+")) {
    if (phoneNumber.length === 10) {
      phoneNumber = "+55429" + phoneNumber.slice(2);
    } else if (phoneNumber.length === 11) {
      phoneNumber = "+55" + phoneNumber;
    }
  }

  try {
    const parsedPhone = parsePhoneNumber(phoneNumber);
    const internationalPhone = parsedPhone.formatInternational();
    let phoneParts = internationalPhone.split(" ")
    phoneParts[1] = `(${phoneParts[1]})`

    return phoneParts.join(" ")
  } catch (error) {
    return number;
  }
}

export function formatInternationalPhoneMask(number) {
  if (!number) return
  try {
    if (number.startsWith("55")) {
      const phoneNumber = `+${number}`;
      const phone = parsePhoneNumber(phoneNumber);

      if (phone.country === "BR") {
        const ddd = phone.nationalNumber.slice(0, 2);
        const nineDigitNumber = phone.nationalNumber.slice(2);

        if (nineDigitNumber.length === 8) {
          return `(${ddd}) 9 ${nineDigitNumber.slice(0, 4)}-${nineDigitNumber.slice(4)}`;
        }
        return `(${ddd}) ${nineDigitNumber.slice(0,1)} ${nineDigitNumber.slice(1,5)}-${nineDigitNumber.slice(5)}`;
      }
    }
    return parsePhoneNumber(`+${number}`).formatInternational();
  } catch {
    return number;
  }
}

export function checkScrollForPagination(event) {
  const scrollContainer = event.target;
  const scrollHeight = scrollContainer.scrollHeight;
  const scrollTop = scrollContainer.scrollTop;

  if (scrollHeight - scrollTop - scrollContainer.clientHeight < 1) {
    return true
  }
  return false
}

export function mockRequest(data, options, sleep = 200) {
  const response = new Response(JSON.stringify(data), options);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      options.status >= 400 ? reject(response) : resolve(response);
    }, sleep)
  });
}

export function playMediaStreamAudio(stream) {
  let audio = document.createElement("audio");
  audio.srcObject = stream;
  audio.play();
}

export function autoGrowTextArea(element) {
  element.target.style.height = "auto";
  element.target.style.height = (element.target.scrollHeight) + "px";
}

export function formatLocaleMoney(value, currency = "BRL", options = {}) {
  const availableLocales = [
    { value: "pt-BR", text: "Português", abbr: "BRL" },
    { value: "en-US", text: "Dolar", abbr: "USD" },
    { value: "en-ES", text: "Euro", abbr: "EUR" },
  ]

  const locale = availableLocales.find(locale => locale.abbr === currency).value

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    ...options,
  }).format(value)
}

export function switchLocale(locale) {
  switch (locale) {
    case "pt":
      moment.locale("pt-br");
      break;
    case "en":
      moment.locale("en-us");
      break;
    case "es":
      moment.locale("es");
      break;
    default:
      break;
  }
}

export function isValidCPF(cpf) {
  cpf = cpf.replace(/\D/g, "");

  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  for (let t = 9; t < 11; t++) {
    let d = 0;
    for (let c = 0; c < t; c++) {
      d += parseInt(cpf[c]) * ((t + 1) - c);
    }
    d = ((10 * d) % 11) % 10;
    if (parseInt(cpf[t]) !== d) {
      return false;
    }
  }

  return true;
}

function getOrCreateTooltip(chart) {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "#FFF";
    tooltipEl.style.borderRadius = "12px";
    tooltipEl.style.color = "#1B1B2B";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, -125%)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.zIndex = 1;
    tooltipEl.style.boxShadow = "0px 4px 18px 0px #22364D1F";
    tooltipEl.style.minWidth = "216px";
    tooltipEl.style.lineHeight = "1.1";

    const table = document.createElement("table");
    table.style.margin = "0px";
    table.style.width = "100%";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
}

export function chartJsTooltip(context, title, previousLabel, previousColor, actualLabel, actualColor, useCurrency = false, cursorX, locale = "pt-BR", currency = "BRL") {
  const { tooltip, chart } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map(b => b.lines);
    const tableHead = document.createElement("thead");
    const tableBody = document.createElement("tbody");

    const trTimeLabel = document.createElement("tr");
    const tdTimeLabel = document.createElement("td");
    const timeLabelValue = document.createElement("span");
    tdTimeLabel.innerText = `${title} `;
    tdTimeLabel.style.color = "#888888";
    tdTimeLabel.style.paddingBottom = "8px";
    tdTimeLabel.style.borderBottom = "1px solid #E1E9F4";
    let auxLabel = tooltip.dataPoints[0].label;
    auxLabel = auxLabel.charAt(0).toUpperCase() + auxLabel.slice(1);
    timeLabelValue.innerText = auxLabel;
    timeLabelValue.style.color = "#1B1B2B";

    tdTimeLabel.appendChild(timeLabelValue);
    trTimeLabel.appendChild(tdTimeLabel);
    tableBody.appendChild(trTimeLabel);

    bodyLines.forEach((body) => {
      let [label, value] = body.toString().trim().split(" ");

      // If there's no data today, show only the data from yesterday
      if (value === undefined) {
        value = label;
        label = previousLabel
      }

      const span = document.createElement("span");

      if (label.toLowerCase() === previousLabel.toLowerCase())
        span.style.borderBottom = "3px dashed #AFAFAF";
      else
        span.style.background = previousColor;

      span.style.marginRight = "8px";
      span.style.height = "3px";
      span.style.width = "24px";
      span.style.borderRadius = "8px"
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;
      td.style.display = "flex";
      td.style.alignItems = "center";
      td.style.whiteSpace = "nowrap";
      td.style.paddingTop = "8px"


      const labelTextNode = document.createElement("span");
      labelTextNode.innerText = label;
      labelTextNode.style.marginRight = "0.8rem";
      labelTextNode.style.fontSize = "12px"
      labelTextNode.style.fontWeight = "500";
      labelTextNode.style.color = "#677C92"
      const spanValue = document.createElement("span");

      const formatNumber = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });


      if (useCurrency) {
        value = parseFloat(value) * 1000
        spanValue.innerText = formatNumber.format(value)
      } else {
        value = value.toString()
        spanValue.innerText = value;
      }

      console.log(spanValue.innerText)
      console.log(value)
      console.log(formatNumber.format(value))

      spanValue.style.textAlign = "right";
      spanValue.style.marginLeft = "auto";
      spanValue.style.fontSize = "14px";
      spanValue.style.fontWeight = "500";
      spanValue.style.color = "#373753";

      td.appendChild(span);
      td.appendChild(labelTextNode);
      td.appendChild(spanValue);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  if (tooltipEl.clientWidth + cursorX > window.innerWidth) {
    // If tooltip will overflow the screen change its position to avoid it
    tooltipEl.style.left = (context.chart.width * 0.85) - (tooltipEl.clientWidth - 150) + "px";
  } else {
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
  }
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = "16px";
}

export function isVisible(el) {
  let top = el?.offsetTop;
  let left = el?.offsetLeft;
  let width = el?.offsetWidth;
  let height = el?.offsetHeight;

  while (el?.offsetParent) {
    el = el?.offsetParent;
    top += el?.offsetTop;
    left += el?.offsetLeft;
  }

  return (
    top < (window.pageYOffset + window.innerHeight) &&
    left < (window.pageXOffset + window.innerWidth) &&
    (top + height) > window.pageYOffset &&
    (left + width) > window.pageXOffset
  );
}

export function elementObserverById(selector) {
  return new Promise(resolve => {
    if (document.getElementById(selector)) {
      return resolve(document.getElementById(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.getElementById(selector)) {
        observer.disconnect();
        resolve(document.getElementById(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function getMessageDay(messageTime) {
  const date = new Date(messageTime * 1000);
  return date.toISOString().split("T")[0];
}

export function formatMessageDay(messageDay) {
  const [year, month, day] = messageDay.split("-").map(Number);
  const inputDate = new Date(year, month - 1, day);
  const currentDate = new Date();
  const diffTime = currentDate - inputDate;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return "today";
  }
  if (diffDays === 1) {
    return "yesterday";
  }
  if (diffDays <= 7) {
    const daysOfWeek = [
      "days_of_week.sunday",
      "days_of_week.monday",
      "days_of_week.tuesday",
      "days_of_week.wednesday",
      "days_of_week.thursday",
      "days_of_week.friday",
      "days_of_week.saturday",
    ];
    return daysOfWeek[inputDate.getDay()];
  }
  if (currentDate.getFullYear() === inputDate.getFullYear()) {
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    return `${day}/${month}`;
  }
  const dayStr = String(inputDate.getDate()).padStart(2, "0");
  const monthStr = String(inputDate.getMonth() + 1).padStart(2, "0");
  const yearStr = inputDate.getFullYear();
  return `${dayStr}/${monthStr}/${yearStr}`;
}

