<template>
  <div
    v-if="isLoading"
    class="card"
  >
    <div class="card-body">
      <card-loading
        :height="203"
        margin-bottom="0"
      />
    </div>
  </div>
  <div
    v-else-if="opportunity?.status !== 'lost'"
    class="card"
  >
    <div class="card-header">
      <div class="d-flex align-items-center text-muted">
        <i class="fa-regular fa-magnifying-glass" />
        <input
          v-model="term"
          :placeholder="$t('search_in_pending_activities')"
          class="ms-3 form-control form-control-flush"
          type="text"
          @keyup="search"
        >
      </div>
    </div>
    <div
      class="card-body p-0"
      style="min-height: 189px"
    >
      <div
        v-if="!loadingFlags.fetchPendingActivities && !activities.length || $route.params.opportunityId === undefined"
        class="d-flex justify-content-center gap-4 p-5"
      >
        <img src="@assets/img/aloisio/new.svg">
        <div class="justify-content-center align-items-center gap-2">
          <h5>{{ $t('no_activity') }}</h5>
          <p class="fs-6">
            {{ $t('click_to_add_activity') }}
          </p>
          <app-button
            :disabled="$route.params.opportunityId === undefined"
            class="new-activity-button"
            style="min-width: 200px"
            @click="showActivityModal = true"
          >
            {{ $t('new_activity') }}
          </app-button>
        </div>
      </div>
      <div
        v-else
        class="tab-content"
      >
        <div
          id="actives"
          class="table-responsive tab-pane active"
          role="tabpanel"
        >
          <table
            class="table table-hover table-nowrap card-table"
            style="padding: 0 !important;"
          >
            <thead>
              <tr>
                <th class="col-1">
                  <a
                    class="list-sort text-muted fw-normal"
                    href="#"
                  >{{ $t('type_general') }}</a>
                </th>
                <th class="col-3">
                  <a
                    class="list-sort text-muted fw-normal"
                    href="#"
                  >{{ $t('title') }}</a>
                </th>
                <th class="col-3">
                  <a
                    class="list-sort text-muted fw-normal"
                    href="#"
                  >{{ $t('date_capitalize') }}</a>
                </th>
                <th class="col-1">
                  <span class="visually-hidden">//</span>
                </th>
                <th class="col-4">
                  <span class="visually-hidden">//</span>
                </th>
              </tr>
            </thead>
            <tbody
              class="list"
            >
              <loading-table-component
                v-if="loadingFlags.fetchActivities && !loadingFlags.completeActivity && activities.length"
                :columns="5"
                :lines="5"
                :list-width="[null, 100, 120, null, 150]"
                :text-center="false"
                height="75px"
              />
              <template v-else>
                <tr
                  v-for="(item, index) in activities"
                  :key="index"
                  class="col-12 cursor-pointer activity-tr"
                  @click="openEditActivityModal(item)"
                >
                  <td class="col-1">
                    <div class="avatar avatar-sm">
                      <div class="avatar-title fs-lg bg-primary-subtle rounded-circle text-primary">
                        <i
                          :class="getActivityIcon(item.type)"
                          class="fa-regular"
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    :title="item.title"
                    class="col-3 fw-medium text-truncate"
                    style="max-width: 220px"
                  >
                    {{ item.title }}
                  </td>
                  <td
                    :class="{'text-danger': item.is_expired }"
                    class="col-3 fw-medium"
                  >
                    <div class="d-flex align-items-center gap-2 my-auto">
                      <i
                        :class="{
                          'fa-regular fa-triangle-exclamation': item.is_expired
                        }"
                      />
                      <span class="d-flex align-items-center gap-2">
                        {{ getDate(item.due_date) }}
                        <dot-status
                          :color="item.is_expired ? 'red' : 'black'"
                          class="my-2"
                          size="0.25rem"
                        />
                        {{ getTime(item.due_date) }}
                      </span>
                    </div>
                  </td>
                  <td class="col-1">
                    <tooltip-component
                      v-if="item.description"
                      :title="item.description"
                      color="text-secondary"
                      icon="fa-regular fa-memo"
                      placement="left"
                    />
                  </td>
                  <td
                    class="col-4 px-0"
                  >
                    <complete-button
                      :activity="item"
                      @complete="complete"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <modal-activity
    :show-activity-modal="showActivityModal"
    @close="showActivityModal = false"
  />
  <modal-edit-activity
    :activity="currentActivity"
    :show-edit-activity-modal="showEditActivityModal"
    @close="showEditActivityModal = false"
  />
</template>

<script>
import moment from "moment";
import {getActivityIcon} from "@crm/utills";
import _ from "lodash";
import {mapActions, mapGetters, useStore} from "vuex";
import DotStatus from "@components/dot-status.vue";
import AppButton from "@/components/app-button.vue";
import ModalActivity from "@crm/components/activity/modals/modal-activity.vue";
import ModalEditActivity from "@crm/components/activity/modals/modal-edit-activity.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import TooltipComponent from "@components/tooltip-component.vue";
import CompleteButton from '@crm/components/activity/list/complete-button.vue'
import CardLoading from "@components/card-loading.vue";

export default {
  name: "PendingActivitiesList",

  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      term: "",
      showActivityModal: false,
      showEditActivityModal: false,
      unsubscribe: () => {
      },
      currentActivity: null
    };
  },

  components: {
    CardLoading,
    AppButton,
    TooltipComponent,
    DotStatus,
    LoadingTableComponent,
    ModalActivity,
    ModalEditActivity,
    CompleteButton
  },

  computed: {
    ...mapGetters('crm/activities', {
      activities: 'getPendingActivities',
      pagination: 'getPendingActivitiesPagination',
      loadingFlags: 'getLoadingFlags',
    }),
    ...mapGetters('crm/opportunities', {
      currentTab: 'getCurrentTabTimeline',
      opportunity: 'getOpportunity'
    })
  },

  mounted() {
    this.setPendingActivitiesFilter({
      opportunity_id: this.$route.params.opportunityId,
      funnel_id: this.$route.params.id,
    })
    this.fetchActivities();
    this.unsubscribe = useStore().subscribeAction((action) => {
      if (action.type === "crm/activities/closeModal") {
        this.showActivityModal = false
        this.showEditActivityModal = false
      }
    })
  },

  beforeUnmount() {
    this.unsubscribe()
  },

  methods: {
    getActivityIcon,

    ...mapActions('crm/activities', {
      setPendingActivitiesFilter: 'setPendingActivitiesFilter',
      setPendingActivitiesCurrentPage: 'setPendingActivitiesCurrentPage',
      fetchActivities: 'fetchActivities',
      completeActivity: 'completeActivity',
    }),

    openEditActivityModal(activity) {
      this.currentActivity = activity;
      this.showEditActivityModal = true
    },

    getDate(date) {
      const momentDate = moment(date, 'YYYY-MM-DD HH:mm:ss');

      const today = moment();
      if (momentDate.isSame(today, "day")) {
        return this.$t('today_capitalize');
      }

      const tomorrow = moment().add(1, 'day');
      if (momentDate.isSame(tomorrow, "day")) {
        return this.$t('tomorrow');
      }

      const yesterday = moment().subtract(1, 'day');
      if (momentDate.isSame(yesterday, "day")) {
        return this.$t('yesterday_capitalize');
      }

      return momentDate.format("DD/MM");
    },

    getTime(date) {
      return moment(date).format("HH:mm");
    },

    search: _.debounce(function () {
      this.setPendingActivitiesFilter({
        name: 'search',
        value: this.term
      });
      this.fetchActivities();
    }, 500),

    changePage(page) {
      this.setPendingActivitiesCurrentPage(page);
      this.fetchActivities();
    },

    complete(activity) {
      const payload = {
        activityId: activity.id,
        activityType: activity.type,
        opportunityId: this.$route.params.opportunityId,
        currentTab: this.currentTab
      }
      this.completeActivity(payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-primary-subtle {
  background-color: $color-gray-blue-300;
}

// custom-tooltip only in no scoped style
.custom-tooltip {
  max-width: 303.5px;
}

.custom-tooltip .tooltip-inner {
  padding: 8px;
}

.new-activity-button {
  &:disabled {
    background-color: $color-gray-blue-300;
    color: $color-text-gray;
    border: none;
  }
}
.activity-tr{
  &:hover{
    .complete-button{
      background-color: $color-blue-300;
      color: white !important;
    }
  }
}
</style>
