<template>
  <nav
    class="sidebar-whatsapp vh-100 flex-column d-flex position-fixed"
    data-test="whatsappSidebar-nav"
    :style="sidebarStyle"
  >
    <div
      ref="logo"
      :class="{
        'new-agent': true,
        ...getBackgroundColor
      }"
      class="logo d-flex align-items-center justify-content-center"
    >
      <img src="@assets/img/logo.svg">
    </div>
    <div class="border-end h-100 flex-column d-flex sidebar-navigation">
      <router-link
        to="/agent"
      >
        <div class="item-box d-flex align-items-center justify-content-center">
          <span class="fe fe-phone icon">
            <span
              :class="getBackgroundColor"
              class="position-absolute translate-middle rounded-circle mt-1"
              style="padding: .25rem"
            />
          </span>
        </div>
      </router-link>
      <router-link
        v-if="isWhatsappAllowed"
        :to="lastVisitedWhatsAppRoute? lastVisitedWhatsAppRoute : '/whatsapp'"
        class="icon-sidebar"
        @click="trackEvents(`[Agent] Click on Mobile (WhatsApp 3C)`)"
      >
        <div
          class="item-box d-flex align-items-center justify-content-center whatsapp"
        >
          <img
            src="@assets/img/icons/whatsapp.svg"
            width="16"
          >
        </div>
      </router-link>
      <router-link
        v-if="funnels.length > 0"
        to="/crm"
      >
        <div class="item-box d-flex align-items-center justify-content-center">
          <i class="fa-light fa-square-kanban icon fw-normal" />
        </div>
      </router-link>
      <div
        ref="crm"
        :class="{'d-none': funnels.length > 0}"
        class="item-box d-flex align-items-center justify-content-center"
        data-bs-placement="right"
        :data-bs-title="$t('not_any_funnel')"
        data-bs-toggle="tooltip"
      >
        <i class="fa-light fa-square-kanban icon fw-normal" />
      </div>
      <div class="col mb-auto" />
      <div class="dropup d-flex justify-content-center align-items-center">
        <button
          class="btn exit-button"
          data-bs-auto-close="false"
          data-bs-toggle="dropdown"
          @click="exitClicked = !exitClicked"
        >
          <!-- TODO: make this dot variable depending on agent status on future change -->
          <!-- <span
            v-if="!exitClicked"
            class="button-dot bg-green-300"
          /> -->
          <span
            :class="exitClicked ? 'fe fe-x' : 'fa-regular fa-user'"
            class="exit-icon"
          />
        </button>
        <div
          class="dropdown-menu text-center mb-2 shadow"
          style="width: 200px"
        >
          <span class="fw-normal">{{ $t("hello") }}, {{ user?.name }}!</span>
          <div class="tx-text-gray">
            {{ $t("your_ip") }}: {{ userIp }}
          </div>
          <hr class="mx-4 my-3">
          <div class="d-flex justify-content-center align-items-center col-12">
            <button
              class="btn mx-4 col button-exit d-flex justify-content-start align-items-start ps-4 text-muted"
              @click="_logout()"
            >
              <i class="fe fe-log-out me-3" />
              {{ $t("log_out_omni") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { AgentStatus, CallStatus } from "@/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Tooltip } from "bootstrap"


export default {
  data() {
    return {
      exitClicked: false,
      selectedItem: null,
      tooltip: null,
      AgentStatus,
      CallStatus,
    };
  },

  mounted() {
    if (!this.socketIOActive) this.setSocketIOActive(true);

    this.tooltip = new Tooltip(this.$refs.crm)
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isWhatsappAllowed: "isWhatsappAllowed",
      showPaymentModal: "showPaymentModal",
    }),

    ...mapGetters("system", {
      status: "getStatus",
      mode: "getMode",
      socketIOActive: "getSocketIOActive",
      socketIOStatus: "getSocketIOStatus",
      userIp: "getUserIp",
      isConsultHold: "isConsultHold",
    }),

    ...mapGetters("agent/call", {
      call: "getCall",
      manualCallAcw: "getManualCallACW",
    }),

    ...mapGetters("whatsapp/chat", {
      lastVisitedWhatsAppRoute: "getLastVisitedWhatsAppRoute",
    }),

    ...mapGetters("crm/funnels", {
      funnels: "getFunnels",
      loadingFlags: "getLoadingFlags",
    }),

    ...mapGetters("agent/ring_group", {
      isRingGroupPermissionEnabled: "isRingGroupPermissionEnabled",
      isRingGroupActive: "isRingGroupActive",
      callStatus: "getCallStatus",
      callPABX: "getCallPABX",
    }),

    getBackgroundColor() {
      return {
        offline:
            ([AgentStatus.OFFLINE].includes(this.status)) ||
            [
              CallStatus.RECEIVING_INTERNAL_CALL,
              this.CallStatus.RECEIVING_EXTERNAL_CALL,
              CallStatus.RECEIVING_TRANSFERENCE,
            ].includes(this.callStatus),
        "bg-idle": this.status == AgentStatus.IDLE && this.mode == "dialer",
        "bg-manual": this.status == AgentStatus.IDLE && this.mode == "manual" && (!this.call || this.call.status != CallStatus.FAILED),
        "bg-interval": this.status == AgentStatus.ON_WORK_BREAK,
        "bg-tpa": this.status == AgentStatus.ACW,
        "bg-mtpa": this.status == AgentStatus.ON_MANUAL_CALL_ACW,
        "bg-call":
            this.status === AgentStatus.ON_CALL &&
            (this.mode === "dialer" && (!this.manualCallAcw || this.manualCallAcw?.status != CallStatus.FAILED) ||
                this.mode === "manual" ||
                (this.callStatus === CallStatus.CONNECTED && this.callPABX.call_mode === "manual")),
        "bg-receptive":
            (this.status == AgentStatus.ON_CALL &&
                this.call &&
                this.call.call_mode == "receptive" &&
                !this.isConsultHold) ||
            (this.status === AgentStatus.ON_CALL &&
                [CallStatus.INTERNAL_CALL_ANSWERED, CallStatus.CONSULT_ANSWERED, CallStatus.CONSULT_CONNECTED].includes(
                    this.callStatus,
                )) ||
            !this.call && this.status === AgentStatus.CONSULT_CONNECTED,
        "bg-calling":
            this.status === AgentStatus.ON_CALL &&
            ((this.call && this.call.call_mode === "receptive" && this.isConsultHold) ||
                [CallStatus.DIALING, CallStatus.TRYING_INTERNAL_CALL, CallStatus.CONSULT_TRYING].includes(this.callStatus)),
        "bg-green-300":
            this.status === AgentStatus.ON_CALL &&
            this.callStatus === CallStatus.CONNECTED &&
            this.callPABX.call_mode === "receptive",
        "bg-danger":
            this.status === AgentStatus.ON_CALL && [CallStatus.INTERNAL_CALL_FAILED].includes(this.callStatus) ||
            this.status === AgentStatus.ON_CALL && this.call && this.call.status == CallStatus.FAILED ||
            this.status === AgentStatus.ON_CALL && this.manualCallAcw?.status == CallStatus.FAILED ||
            this.callPABX?.status == CallStatus.FAILED,
      };
    },
    sidebarStyle() {
      return {
        zIndex: this.showPaymentModal ? 1068 : 2,
      };
    },
  },

  methods: {
    ...mapActions("auth", ["logout"]),

    ...mapActions("agent/agent", {
      exitCampaign: "logout",
    }),

    ...mapMutations("system", ["setSocketIOActive"]),
    ...mapMutations("auth", ["trackEvents"]),

    async _logout() {
      if (this.status != AgentStatus.OFFLINE) await this.exitCampaign();
      this.logout().then(() => {
        this.$router.push({
          name: "login",
        });
      });
    },
  }
};
</script>
<style
    lang="scss"
    scoped
>
.item-box {
  height: 1.25rem;
}

.logo {
  min-height: 3.5rem;
}

.logo img {
  height: 1.5rem;
}

.sidebar-whatsapp {
  width: 64px !important;
  background: white;
}

.sidebar-navigation {
  padding-top: 2rem;
  border-color: $color-gray-blue-300;
  gap: 1.25rem;
}

.button-dot {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.exit-button {
  color: $color-gray-500;
  border-radius: 10px;
  margin-bottom: $whatsapp-spacing-3;
  height: 40px;
  width: 40px;
  position: relative;
  background-color: $color-gray-blue-300;

  &.show {
    background-color: #2C7BE5 !important;
    color: white !important;
  }
}

.offline {
  background-color: var(--color-yellow-300);
}

.icon {
  color: $color-text-gray;
  margin-left: 2px;
  height: 24px;
}

.whatsapp img {
  margin-left: 2px;
}

.router-link-active {
  border-left: 2px solid var(--color-yellow-300);

  .icon {
    color: #1b1b2b !important;
    margin-left: unset;
  }

  .whatsapp img {
    content: url("~@assets/img/icons/whatsapp-active.svg");
    margin-left: 0;
  }
}

.button-exit {
  &:hover {
    background-color: $color-gray-blue-300;
  }
}

.new-agent {
  &.bg-receptive,
  &.bg-consult,
  &.bg-mtpa,
  &.bg-call,
  &.bg-manual,
  &.bg-tpa,
  &.bg-danger {
    img {
      content: url("~@assets/img/logo-light.svg") !important;
    }
  }
}
</style>
