import { AgentStatus } from "@/utils";
import { ref } from "vue";

export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setAllAgentsOnline: (state, allAgentsOnline) => {
    state.allAgentsOnline = allAgentsOnline.reduce((allAgentsOnlineById, agent) => {
      allAgentsOnlineById[agent.id] = agent;

      return allAgentsOnlineById;
    }, {});
  },

  updateAgentStatus: (state, event) => {
    const { agent } = event;

    state.allAgentsOnline[agent.id].status =
      agent.status || agent.agent_status.manual_call_status || agent.agent_status.status;
  },

  createAgentOnline: (state, event) => {
    const { agent } = event;

    state.allAgentsOnline[agent.id] = {
      id: agent.id,
      status: agent.status || agent.agent_status.status
    }
  },

  updateAgentStatusInCampaign: (state, event) => {
    const { agent, campaign, call } = event;
    const { id, timer, is_manual_call_qualified } = state.agents[agent.id];

    const newStatus =
      agent.status || agent.agent_status.manual_call_status || agent.agent_status.status;

    state.agents[id].status = newStatus;

    if (call) {
      state.agents[id].call_mode = call.call_mode;
    }

    if (timer) {
      clearInterval(timer);
    }

    if ([AgentStatus.OFFLINE, AgentStatus.IDLE].includes(newStatus)) {
      state.agents[id].work_break_exit_pending = false;
    }

    state.agents[id].counter = 0;

    // Start new timer
    state.agents[id].timer = setInterval(() => {
      state.agents[id].counter++;
      if(
        state.agents[id].call_mode === "manual" &&
        state.agents[id].status === AgentStatus.ACW &&
        campaign.acw_timeout &&
        !is_manual_call_qualified
      ) {
        if (state.agents[id].counter >= campaign.acw_timeout) {
          state.agents[id].status = AgentStatus.ON_MANUAL_CALL;
          state.agents[id].counter = 0;
        }
      }
    }, 1000);
  },

  setAgentWorkBreak: (state, event) => {
    const { agent, work_break } = event;

    state.agents[agent.id].work_break = work_break;
    state.agents[agent.id].work_break.minutes = work_break.minutes * 60;
  },

  setWorkBreakExitPending: (state, { id, work_break_exit_pending }) => {
    state.agents[id].work_break_exit_pending = work_break_exit_pending;
  },

  setAgentStatusInCampaignAsOffline: (state, event)  => {
    const { agent } = event;
    state.agents[agent.id].status = 0;
  },

  removeAgentOnline: (state, event) => {
    const { agent } = event;
    delete state.allAgentsOnline[agent.id];
  },

  setAgents: (state, agents) => {
    state.agents = agents.reduce((agentsById, agent) => {
      const agentData = ref(agent);

      agentData.value.counter = agent.count_status_start_time;
      agentData.value.is_manual_call_qualified = false;

      if (agent.manual_call_status) {
        agentData.value.status = agent.manual_call_status;
      }

      if(agent.status !== AgentStatus.OFFLINE) {
        agentData.value.timer = setInterval(() => {
          agentData.value.counter++;
        }, 1000);
      } else {
        agentData.value.timer = null;
      }
      agentsById[agent.id] = agentData;

      return agentsById;
    }, {});
  },

  clearAgentsData: state => {
    state.allAgentsOnline = [];

    Object.values(state.agents).forEach(agent => {
      if(agent.timer) {
        clearInterval(agent.timer);
        agent.timer = null;
      }
    })

    state.agents = {};
  },

  setIsManualCallQualified: (state, {agent, isManualCallQualified}) => {
    state.agents[agent.id].is_manual_call_qualified = isManualCallQualified;
  }
}
