<template>
  <div
    class="position-relative h-40p"
    :style="{ 'z-index': open ? 1 : ''}"
  >
    <div
      class="card card-select-message position-absolute w-100"
      :class="{'border-error': error}"
      ref="selectMessageContainer"
    >
      <div
        class="card-header border-0 justify-content-between cursor-pointer h-40p py-05-px-1r"
        @click="toggleOpen"
        aria-expanded="false"
        aria-controls="selectMessage"
      >
        <div v-if="!open">
          <p
            v-if="modelValue"
            class="mb-0 text-truncate"
          >
            {{ $t("template") + " " + modelValue.name }}
          </p>
          <p
            v-else
            class="m-0 tx-text-gray"
          >
            {{ $t("select") }}
          </p>
        </div>
        <div
          v-else
          class="d-flex align-items-center gap-2 tx-text-gray"
        >
          <span class="far fa-search" />
          <input
            class="form-control border-0 text-medium"
            :placeholder="$t('placeholders.search_capitalize')"
            type="text"
            v-model="search"
            maxlength="50"
          >
        </div>
        <span
          class="far fa-chevron-down"
          id="chevron"
          :class="open? 'chevron-open' : 'chevron-closed'"
        />
      </div>
      <div
        class="card-body collapse py-0-px-1r"
        id="selectMessage"
        ref="selectMessage"
      >
        <ul class="list-unstyled pb-0 mb-0 overflow-scroll mh-314p">
          <li
            v-if="list.length === 0"
            class="text-center"
          >
            {{ $t("no_templates_found") }}
          </li>
          <li
            v-for="message in list"
            :key="message.id"
            class="text-truncate list"
            @click="setSelectedMessage(message)"
          >
            {{ $t("template") + " " + message.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="pt-40p">
      <small class="text-danger"> {{ error }}</small>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Collapse } from "bootstrap";

export default {
  name: "SelectWabaTemplate",

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    error: {
      type: String,
      default: "",
    },
    instanceId: {
      type: String,
      required: true,
    },
    initialMessage: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      search: "",
      open: false,
      collapse: null,
    }
  },

  created() {
    this.fetchTemplates(this.instanceId).then(() => {
      if (this.initialMessage) {
        const message = this.templates.find(template => template.id === this.initialMessage.toString())
        this.$emit("update:modelValue", message)
      }
    })
  },

  computed: {
    ...mapGetters("manager/ivrs", {
      errors: "getErrors",
      templates: "getTemplates",
    }),

    list() {
      return this.templates.filter(message => message.name.toLowerCase().includes(this.search.toLowerCase()))
    },
  },

  mounted() {
    this.collapse = new Collapse(this.$refs.selectMessage, { toggle: false })
    this.$refs.selectMessage.addEventListener("hide.bs.collapse", this.hideHandler)
    this.$refs.selectMessage.addEventListener("show.bs.collapse", this.showHandler)

    document.addEventListener("click", this.closeDropdown);
  },

  beforeUnmount() {
    this.$refs.selectMessage.removeEventListener("hide.bs.collapse", this.hideHandler)
    this.$refs.selectMessage.removeEventListener("show.bs.collapse", this.showHandler)

    document.removeEventListener("click", this.closeDropdown);
  },

  methods: {
    ...mapActions("manager/ivrs", ["fetchTemplates"]),

    toggleOpen(e) {
      if (this.open && e.target.id !== "chevron") {
        return
      }
      this.collapse.toggle()
    },

    showHandler(e) {
      if (e.target.id === "selectMessage") {
        this.open = true
      }
    },

    hideHandler(e) {
      if (e.target.id === "selectMessage") {
        setTimeout(() => {
          this.open = false
        }, 300)
      }
    },

    closeDropdown(event) {
      if (!this.$refs.selectMessageContainer.contains(event.target)) {
        this.collapse.hide()
      }
    },

    setSelectedMessage(message) {
      this.$emit("update:modelValue", message)
      this.collapse.hide()
    },
  },
}
</script>

<style scoped lang="scss">
.h-40p {
  height: 40px;
}

.card-select-message {
  min-width: 331px;
  max-height: 354px;
  border-color: #d2ddec
}

.py-05-px-1r {
  padding: 0.5rem 1rem;
}

.py-0-px-1r {
  padding: 0 1rem;
}

.mh-314p {
  max-height: 314px
}

.list {
  height: 32px;
  padding: 0 16px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-blue-300;
  }
}

.pt-40p {
  padding-top: 40px
}

.chevron-open {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.chevron-closed {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.border-error {
  border-color: $color-red-300 !important;
}
</style>