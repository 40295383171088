<template>
  <div
    class="card doughnut-chart"
    :class="{'card-fill': !chartData.length}"
  >
    <div class="card-header">
      <h4 class="card-header-title fw-normal">
        {{ title }}
      </h4>
      <span>{{ chartData.reduce((a, b) => a + b, 0) > 0 ? formatTooltip(chartData.reduce((a, b) => a + b, 0)) : ""
      }}</span>
    </div>
    <div
      v-show="chartData.reduce((a, b) => a + b, 0)"
      class="card-body"
    >
      <div class="chart chart-appended">
        <canvas ref="chart" />
      </div>
      <div class="chart-legend">
        <div>
          <span
            class="chart-legend-item"
            v-for="(item, i) in labels"
            :key="i"
          ><span
             class="chart-legend-indicator"
             :style="'background-color:' + colors[i]"
           />
            {{ formatTooltip(labels[i]) }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="!chartData.reduce((a, b) => a + b, 0)"
      class="card-body"
    >
      <slot name="empty-state" />
    </div>
  </div>
</template>
<script>
import { Chart } from "chart.js";

export default {
  /**
   *
   */
  mounted() {
    this.options.data.datasets[0]["backgroundColor"] = this.colors;
    this.options.data.datasets[0]["data"] = this.chartData;
    this.options.data["labels"] = this.labels;
    new Chart(this.$refs.chart, this.options);
  },

  /**
   *
   */
  data() {
    return {
      options: {
        type: "doughnut",
        data: {
          labels: [],
          datasets: [{}],
        },
        options: {
          plugins: {
            tooltip: {
              intersect: true,
              callbacks: {
                label: (context) => {
                  return this.formatTooltip(context.parsed);
                },
              },
            },
          },
        },
      },
    };
  },

  /**
   *
   */
  props: {
    /**
     * Callback change tooltip format
     */
    formatTooltip: {
      type: Function,
      default: (val) => val.toString(),
    },

    /**
     *
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * chart colors
     */
    colors: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     * chart label
     */
    labels: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     * chart data
     */
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  /**
   *
   */
  methods: {
    /**
     * FIX recursive bug
     */
    getChartIntance() {
      return Chart.getChart(this.$refs.chart);
    },
  },

  /**
   *
   */
  watch: {
    /**
     *
     */
    chartData(val) {
      let chart = this.getChartIntance();
      if (!chart) return;
      chart.data.datasets[0].data = val;
      chart.update();
    },

    /**
     *
     */
    colors(val) {
      let chart = this.getChartIntance();
      if (!chart) return;
      chart.data.datasets[0]["backgroundColor"] = val;
    },

    /**
     *
     */
    labels(val) {
      let chart = this.getChartIntance();
      if (!chart) return;
      chart.data["labels"] = val;
    },
  },
};
</script>

<style lang="scss">
.doughnut-chart {
  //fix height card
  .chart-legend {
    div {
      margin-top: -20px;
      min-height: 39px;
    }
  }
}
</style>
