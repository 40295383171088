<template>
  <div
    class="offcanvas-body d-flex flex-column gap-1rem"
  >
    <action-component
      icon-class="fa-regular fa-arrow-right-arrow-left"
      icon-color="#3057F2"
      :name="$t('transfer_to_wait_queue')"
    />

    <div class="col-auto">
      <label
        class="form-label"
        for="groupChannel"
      >{{ $t('select_channel_group') }}</label>
      <select
        id="groupChannel"
        v-model="groupChannel"
        :class="{'is-invalid': !groupChannel}"
        class="form-select"
      >
        <option value="">
          {{ $t('select_group_channels') }}
        </option>
        <option
          v-for="groupChannelItem in groupChannels"
          :key="groupChannelItem.id"
          :value="groupChannelItem.id"
        >
          {{ groupChannelItem.name }}
        </option>
      </select>
    </div>
    <div class="mt-auto" />
    <app-button
      :disabled="!groupChannel"
      @click="editAction"
    >
      {{ $t('capitalizes.save') }}
    </app-button>
    <app-button
      color="text-danger"
      @click="deleteAction"
    >
      <span class="fa-regular fa-trash" />
      {{ $t('capitalizes.exclude') }}
    </app-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "EditTransfer",

  components: {
    AppButton,
    ActionComponent
  },

  data() {
    return {
      groupChannel: ""
    }
  },

  mounted() {
    this.groupChannel = this.actionToEdit.transfer_to_group_channel_id
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      actionToEdit: "getActionToEdit",
      groupChannels: "getGroupChannels"
    })
  },

  methods: {
    ...mapActions("chatbot/chatbots", ["fetchChatbot"]),
    ...mapActions("chatbot/actions", [
      "updateAction",
      "deleteAction"
    ]),
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit"
    ]),

    editAction() {
      this.updateAction({
        actionId: this.actionToEdit.parentId,
        payload: {
          transfer_to_group_channel_id: this.groupChannel
        }
      }).then(() => {
        this.setOffcanvasEditActionIsOpen(false)
      })
    },

    deleteAction() {
      this.updateAction({
        actionId: this.actionToEdit.parentId,
        payload: {
          transfer_to_group_channel_id: ""
        }
      }).then(() => {
        this.setOffcanvasEditActionIsOpen(false)
      })
    }
  },
  watch: {
    actionToEdit(action) {
      if (action.type === "transfer") {
        this.groupChannel = action.transfer_to_group_channel_id;
      }
    }
  }
}
</script>
<style scoped>
.gap-1rem {
  gap: 1rem;
}
</style>