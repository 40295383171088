<template>
  <div class="col-5 d-flex gap-5 align-items-center container py-2 px-4 text-center">
    <img
      v-if="img && isValidImageUrl(img)"
      :alt="alt"
      :src="img"
      @error="handleImageError"
    >
    <div class="col text-start">
      <div
        v-if="title"
        v-html="title"
        class="fw-medium fs-4"
      />
      <p
        v-if="externaLink"
        class="m-0 fw-normal text-medium p-0"
      >
        <a
          :class="externaLink.custom_class"
          :href="externaLink.url || '#'"
          target="_blank"
          class="text-dark"
        >
          {{ externaLink.text }}
        </a>
        {{ subtitle }}
      </p>
      <p
        v-else-if="subtitle"
        class="m-0 fw-normal text-small p-0"
      >
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>
<script>
import {handleImageError, isValidImageUrl} from "@/utils";

export default {
  props: {
    title: {type: String, default: null},
    subtitle: {type: String, default: null},
    img: {type: String, default: null},
    alt: {type: String, default: 'img'},
    externaLink: {type: Object, default: null}
  },

  methods: {
    isValidImageUrl, handleImageError,
  }
}
</script>
<style lang="scss" scoped>
.container {
  background-color: #FFFFFF;
  border-radius: 0.625rem !important;
  padding: 1rem 2rem !important;

  img {
    width: 92px;
  }

  h4 {
    color: #1B1B2B;
  }

  p {
    color: #373753;
  }
}
</style>
