<template>
  <div class="d-flex align-items-center w-100">
    <div
      class="d-flex align-items-center justify-content-center action-type"
      :style="{ backgroundColor: iconBg, color: iconColor}"
    >
      <span
        style="font-size: 1.125rem"
        :class="iconClass"
      />
    </div>
    <p class="m-0">
      {{ name }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ActionComponent",

  props:{
    name:{
      type: String,
      required: true
    },
    iconBg:{
      type: String,
      default: "#FFFFFF"
    },
    iconColor:{
      type: String,
      required: true
    },
    iconClass:{
      type: String,
      required: true
    },
  }
}
</script>

<style scoped lang="scss">
.action-type{
  height: 32px;
  width: 32px;
  border-radius: 8px;
  margin-right: 0.5rem
}
</style>
