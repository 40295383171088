<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <p class="card-header-title">
            {{ $t('chats_reports') }}
          </p>
        </div>
        <div class="col-auto d-flex">
          <app-button
            v-if="chatsReportList.length > 0"
            class="btn-sm btn-export tx-text-gray col-12"
            @click="show = true"
          >
            {{ $t('download_CSV') }}
          </app-button>
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-nowrap my-0">
          <thead>
            <tr>
              <th scope="col">
                {{ $t('group_channel') }}
              </th>
              <th scope="col">
                {{ $t('agent_capitalize') }}
              </th>
              <th scope="col">
                {{ $t('number') }}
              </th>
              <th scope="col">
                {{ $t('start') }}
              </th>
              <th scope="col">
                {{ $t('end') }}
              </th>
              <th scope="col">
                Status
              </th>
              <th scope="col">
                {{ $t('qualification') }}
              </th>
              <th scope="col">
                {{ $t('details') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loadingFlags.fetchChatsReportList">
              <loading-table-component
                :lines="5"
                :columns="8"
              />
            </template>

            <template
              v-else
              v-for="(item, id) in chatsReportList"
              :key="id"
            >
              <tr @click="indexOpennedRow = (indexOpennedRow === id) ? -1 : id">
                <td>
                  {{ item.group_channel ? item.group_channel : '-' }}
                </td>
                <td>
                  {{ item.agent ? item.agent : '-' }}
                </td>
                <td>
                  {{ item.number ? formatPhoneNumber(item.number) : '-' }}
                </td>
                <td>
                  {{ item.start ? formatDate(item.start) : '-' }}
                </td>
                <td>
                  {{ item.end ? formatDate(item.end) : '-' }}
                </td>
                <td
                  v-if="item?.status"
                  :class="getStatusColorClass(item.status)"
                >
                  {{ getStatusName(item.status) }}
                </td>
                <td>
                  {{ item.qualification ? item.qualification : '-' }}
                </td>
                <td>
                  <div class="d-flex justify-content-around">
                    <i
                      @click="openOffCanvas(item)"
                      class="fa-regular fa-file-lines cursor-pointer tx-text-gray"
                    />
                    <i
                      class="tx-blue-300 cursor-pointer"
                      :class="indexOpennedRow === id ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up'"
                    />
                  </div>
                </td>
              </tr>

              <tr
                v-if="indexOpennedRow === id"
                style="background-color: #F0F4FA;"
              >
                <td colspan="2">
                  <div class="d-flex flex-column">
                    <span class="text-muted text-truncate">{{ $t('protocol') }}</span>
                    <span>
                      {{ item.protocol ? item.protocol : '-' }}
                      <icon-copy-text :text="item.protocol" />
                    </span>
                  </div>
                </td>
                <td colspan="1">
                  <div class="d-flex flex-column">
                    <span class="text-muted text-truncate">{{ $t('client_name') }}</span>
                    <span> {{ item.name }}</span>
                  </div>
                </td>
                <td colspan="1">
                  <div class="d-flex flex-column">
                    <span class="text-muted text-truncate">{{ $t('transfers') }}</span>
                    <strong class="text-truncate">{{ getTransfers(item) }}</strong>
                  </div>
                </td>
                <td
                  v-if="false"
                  colspan="1"
                >
                  <div class="d-flex flex-column">
                    <span class="text-muted text-truncate">Tags</span>
                    <span>
                      {{ item.tags ? item.tags : '-' }}
                    </span>
                  </div>
                </td>
                <td colspan="4">
                  <div class="d-flex justify-content-around gap-4">
                    <app-button
                      :loading="loadingFlags.fetchChatPdf"
                      :disabled="loadingFlags.fetchChatPdf"
                      class="btn-export tx-text-gray col py-0"
                      style="height: 32px"
                      @click="exportReport(item)"
                    >
                      {{ $t('export') }}
                    </app-button>
                    <app-button
                      class="col py-0"
                      style="height: 32px"
                      @click="spy(item)"
                    >
                      <i class="fa-regular fa-eye" /> {{ $t('see_chat') }}
                    </app-button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <table-empty v-if="!chatsReportList.length && !loadingFlags.fetchChatsReportList" />
    <div class="card-footer">
      <div class="col-lg-12">
        <app-paginator
          :pagination="pagination"
          @change-page="changePage"
        />
      </div>
    </div>
  </div>
  <modal-download-csv
    :show="show"
    @close="show = false"
  />
</template>

<script>
import AppPaginator from "@components/app-paginator.vue";
import ModalDownloadCsv from "./modal-download-csv.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import AppButton from "@components/app-button.vue";
import IconCopyText from "@components/icon-copy-text.vue";

import {mapActions, mapGetters, mapMutations} from "vuex";
import { formatPhoneNumber } from "@/utils";
import moment from "moment/moment";
import TableEmpty from "@dialer/manager/pages/reports/components/table-empty.vue";

export default {
  name: "TableChatsReport",

  emits: ["openOffcanvas"],

  components: {
    IconCopyText,
    TableEmpty,
    AppButton,
    LoadingTableComponent,
    ModalDownloadCsv,
    AppPaginator,
  },

  data() {
    return {
      show: false,
      indexOpennedRow: -1,
    }
  },

  computed: {
    ...mapGetters("manager/chats_report", {
      loadingFlags: "getLoadingFlags",
      chatsReportList: "getChatsReportList",
      pagination: "getPagination"
    }),
  },

  methods: {
    formatPhoneNumber,
    ...mapMutations("manager/chats_report", ['setCurrentPage']),
    ...mapActions("whatsapp/chat", ["findChat"]),
    ...mapActions("manager/chats_report", [
      "fetchChatsReportList",
      "fetchChatPdf"
    ]),
    ...mapMutations("whatsapp/chat", ["setCurrentChat"]),

    formatDate(date) {
      return moment(date).format("DD/MM [às] HH[h]mm[m]");
    },

    getStatusColorClass(status) {
      switch(status){
        case "IN_PROGRESS":
          return "tx-green-300"
        case "IN_QUEUE":
          return "tx-blue-300"
        case "IN_SNOOZE":
          return "tx-orange-300"
        case "FINISHED":
          return ""
        default:
          return ""
      }
    },

    getStatusName(status) {
      switch(status){
        case "IN_PROGRESS":
          return this.$t('in_progress')
        case "IN_QUEUE":
          return this.$t('capitalizes.queue')
        case "IN_SNOOZE":
          return this.$t('list_metrics_on_hold')
        case "FINISHED":
          return this.$t('finalized_f')
        default:
          return ""
      }
    },

    getTransfers(item) {
      if (item.transfers > 0){
        if (item.transfers === 1){
          return `1 ${this.$t('transfer_capitalize')}`
        }
        return `${item.transfers} ${this.$t('transfers')}`
      }
      return "-"
    },

    openOffCanvas(data) {
      this.indexOpennedRow = data.id
      this.$emit('openOffcanvas', data)
    },

    exportReport(item) {
      const chatData = {
        id: item.chat_id,
        instance_id: item.instance_id,
        number: item.number
      }
      this.fetchChatPdf(chatData)
    },

    spy(item) {
      const chatId = item.number + item.instance_id

      this.findChat(chatId).then(chat => {
        this.setCurrentChat(chat);
        this.$router.push("/whatsapp/" + chat.id);
      })
    },

    changePage(page) {
      this.setCurrentPage(page);
      this.fetchChatsReportList();
    },
  }
}
</script>

<style lang="scss" scoped>
.btn-export {
  background: #E1E9F4;
  border: none;
  &:hover{
    background-color: #D2DDEC;
  }
}
</style>
