<template>
  <div
    class="d-flex align-items-center"
  >
    <div
      class="card mb-0 card-transfer"
      @click="addOrEdit"
    >
      <div
        class="card-body d-flex  flex-column padding-1rem"
      >
        <action-component
          icon-class="fa-regular fa-arrow-right-arrow-left"
          icon-color="#3057F2"
          :name="$t('transfer_to_wait_queue')"
        />
        <div v-if="action?.transfer_to_group_channel_id">
          <hr class="w-100">
          <div>
            <p class="m-0 tx-text-gray">
              {{ $t("queue") }}
            </p>
            <p class="m-0">
              <strong>{{ groupChannel?.name }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";

export default {
  name: "CardTransfer",

  props: {
    action: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      groupChannel: null
    }
  },

  components: {
    ActionComponent
  },
  mounted() {
    if(this.action?.transfer_to_group_channel_id) {
      this.findGroupChannelById({id: this.action.transfer_to_group_channel_id}).then(()=>{
        this.groupChannel = this.getGroupChannel
      })
    }
  },
  computed:{
    ...mapGetters("manager/whatsapp_group_channel", {getGroupChannel:"getGroupChannel"}),
  },
  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit",
        "setSelectedAction",
        "setOffcanvasNewActionIsOpen",
        "setPrevActionId"
    ]),
    ...mapActions("manager/whatsapp_group_channel", ["findGroupChannelById"]),

    addOrEdit(){
      if(!this.action?.transfer_to_group_channel_id){
        this.addText()
      }else{
        this.editAction()
      }
    },
    editAction() {
      this.setSelectedAction(this.action)
      this.setActionToEdit(this.action)
      this.setOffcanvasEditActionIsOpen(true)
    },
    addText(){
      this.setPrevActionId(this.action.previous_action_id)
      this.setOffcanvasNewActionIsOpen(true)
      this.setSelectedAction({
        id: this.action.id,
        type: "transfer",
        position: this.action.position
      });
    }
  },
  watch: {
    action(value){
      if(value.transfer_to_group_channel_id){
        this.findGroupChannelById({id: value.transfer_to_group_channel_id}).then(()=>{
          this.groupChannel = this.getGroupChannel
        })
      }
    }
  }
}
</script>
<style scoped>
.card-transfer{
  min-width: 400px;
}
.padding-1rem{
  padding: 1rem;
}
</style>
