<template>
  <div class="header p-0 m-0">
    <div class="header-body border-0">
      <div class="row align-items-start">
        <button
          v-if="!(redirectLink === '')"
          class="btn btn-rounded-circle btn-white float-start ms-3"
          @click="$router.push(redirectLink)"
        >
          <i class="fa-regular fa-arrow-left" />
        </button>
        <div class="col">
          <small
            v-if="!(pretitle === '')"
            class="header-pretitle"
            :class="[textColorClass]"
            :style="{ color: colorPretitle }"
          >
            {{ pretitle }}
          </small>
          <h1 class="header-title text-break mt-1 lh-lg">
            {{ title }}
          </h1>
          <slot name="options" />
        </div>
        <div class="col-auto">
          <slot name="buttons" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    redirectLink: {
      type: String,
      default: ""
    },
    pretitle: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "",
      required: true
    },
    textColorClass: {
      type: String,
      default: ""
    },
    colorPretitle: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-rounded-circle {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}
</style>
