<template>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasSelectChannel"
    aria-labelledby="offcanvasSelectChannelLabel"
    ref="offcanvas"
  >
    <div class="offcanvas-header">
      <h5
        class="offcanvas-title"
        id="offcanvasSelectChannelLabel"
      >
        {{ $t('edit_step') }}
      </h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="offcanvas.hide()"
      />
    </div>
    <div class="offcanvas-body d-flex flex-column">
      <div
        class="col-auto badge-blue"
      >
        {{ $t('start') }}
      </div>
      <div class="col-auto">
        <label
          for="groupChannel"
          class="form-label"
        >{{ $t('select_channel_group') }}</label>
        <select
          v-model="groupChannel"
          class="form-select"
          id="groupChannel"
        >
          <option value="">
            {{ $t('select_channel') }}
          </option>
          <option
            v-for="groupChannelItem in groupChannels"
            :key="groupChannelItem.id"
            :value="groupChannelItem.id"
          >
            {{ groupChannelItem.name }}
          </option>
        </select>
      </div>
      <div class="col" />
      <app-button
        :disabled="!groupChannel"
        @click="saveGroupChannel"
        class="w-100"
      >
        {{ $t('capitalizes.save') }}
      </app-button>
    </div>
  </div>
</template>

<script>
import AppButton from "@components/app-button.vue";
import { Offcanvas } from "bootstrap";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "OffcanvasSelectChannel",

  emits:["close"],

  components: {
    AppButton
  },

  props:{
    show: {
      type: Boolean,
      default: false,
    }
  },

  data(){
    return{
      offcanvas: null,
      groupChannel: ""
    }
  },

  mounted(){
    this.offcanvas = new Offcanvas(this.$refs.offcanvas)
    this.$refs.offcanvas.addEventListener("hide.bs.offcanvas", this.onHideOffcanvas);
  },

  computed:{
    ...mapGetters("chatbot/actions", {
      actions: "getActions",
      groupChannels: "getGroupChannels"
    })
  },

  methods:{
    ...mapActions("chatbot/actions", [
      "createStarterAction"
    ]),
    ...mapActions("chatbot/chatbots", [
      "fetchChatbot"
    ]),
    ...mapMutations("chatbot/actions", ["setOffcanvasSelectChannelIsOpen"]),

    onHideOffcanvas() {
      this.setOffcanvasSelectChannelIsOpen(false)
      this.$emit("close");
    },

    saveGroupChannel(){
      let payload = this.actions[0]
      payload.group_channel_id = Number(this.groupChannel)

      this.createStarterAction(payload).then(()=>{
        this.offcanvas.hide()
        this.fetchChatbot(this.$route.params.id);
      })
    }
  },

  watch: {
    show(value){
      value? this.offcanvas.show() : this.offcanvas.hide()
    }
  }
}
</script>

<style scoped lang="scss">
.badge-blue{
  width: 68px;
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #E1E9F4;
  color: #1A4A89;
  margin-bottom: 16px
}
</style>
