import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      inImpersonate: "inImpersonate",
      isSupervisorOrManager: "isSupervisorOrManager",
      isManager: "isManager"
    })
  },

  mounted() {
    this.installIntercom();
  },

  watch: {
    "$route.path": {
      handler(path) {
        if (this.isManager && window?.Intercom) {
          if (path.startsWith("/whatsapp")) {
            const clientHeight = window.innerHeight;
            const vertical_padding = clientHeight / 2;
            window.Intercom("update", {
              vertical_padding: vertical_padding
            });

            return;
          }
          window.Intercom("update", {
            vertical_padding: 73
          });
        }
      }
    },

    user: {
      handler() {
        this.launchIntercom();
      },
      deep: true
    }
  },

  methods: {
    ...mapMutations("auth", ["trackEvents"]),
    installIntercom() {
      (function() {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function() {
            i.c(arguments);
          };
          i.q = [];
          i.c = function(args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function() {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + process.env.VUE_APP_AB01CD;
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    },

    launchIntercom() {
      if (!window.Intercom) return;
      if (this.user && this.isSupervisorOrManager && !this.inImpersonate) {
        let tags = [];
        for (let item of this.user.company.tags) tags.push(item.name);
        window.Intercom("boot", {
          app_id: process.env.VUE_APP_AB01CD,
          name: this.user.name + " (Novo front-end)",
          email: this.user.email,
          config: this.user.extension.extension_number,
          user_id: this.user.id,
          company: {
            id: this.user.company.id,
            name: this.user.company.name,
            domain: this.user.company.domain,
            created_at: this.user.company.created_at,
            suite_users: this.user.company.max_agents_login,
            "3c-tag": tags.join(","),
            "3c-plan": ["Ilimitado", "Minutagem", "Telefonia Externa"][this.user.company.plan - 1]
          }
        });

        window.Intercom("onShow", () => {
          this.trackEvents(`[Manager] Intercom Click`);
        });
      } else {
        window.Intercom("shutdown");
      }
    }
  }
};
