<template>
  <nav
    ref="sidebar"
    :class="{
      'sidebar navbar navbar-vertical navbar-expand-md navbar-light': true,
      expand: expand,
      'fixed-start': !expand,
      'navbar-vertical-sm': expand && !expanded
    }"
    style="padding-top: 1.5rem; padding-bottom: 1rem;"
    @mouseleave="expanded = keepSidebarOpen"
    @mouseover="expanded = true"
    :style="sidebarStyle"
  >
    <div class="container-fluid position-relative">
      <div v-show="expand && !expanded">
        <router-link
          class="navbar-brand"
          style="padding: 0.5rem 0 !important;"
          to="/manager"
        >
          <img
            class="navbar-brand-img mx-auto"
            src="@assets/img/logo.svg"
          >
        </router-link>
        <ul
          ref="expansive-sidebar"
          class="navbar-nav"
        >
          <template
            v-for="(item, i) in menu.top"
            :key="i"
          >
            <li
              v-if="item.type === 'link' || item.type === 'group'"
              class="nav-item"
            >
              <a
                v-if="user && !item.hide && item.roles.includes(user.role.name)"
                :class="{
                  active: item.active_name == getActiveRouteName()
                }"
                class="nav-link"
              >
                <i :class="item.icon" />
              </a>
            </li>
            <li
              v-else-if="
                item.type == 'link' &&
                  isSupervisor &&
                  item.id == 'whatsapp' &&
                  user.teams?.find(element => element.whatsapp == true)
              "
              :data-test="`${item.title}`.replaceAll(' ', '').toLowerCase() + `-link`"
              class="nav-item"
            >
              <a
                v-if="user && !item.hide && item.roles.includes(user.role.name)"
                :class="{
                  'nav-link': true,
                  active: item.active_name == getActiveRouteName()
                }"
              >
                <i :class="item.icon" />
              </a>
            </li>
            <hr
              v-if="item.type == 'separator' && isManager"
              class="navbar-divider"
              style="margin: 0.25rem 1.5rem;"
            >
          </template>
        </ul>
        <div class="mt-auto" />
        <ul class="navbar-nav position-absolute bottom-0">
          <li
            v-for="(item, i) in menu.bottom"
            :key="i"
            class="nav-item"
          >
            <a
              v-if="user && !item.hide && item.roles.includes(user.role.name)"
              :class="{ 'tx-blue-300': item.active_name.includes(getActiveRouteName()) }"
              class="nav-link"
            >
              <i :class="item.icon" />
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link dropdown-wallet">
              <div
                class="w-100 item-wallet"
                style="padding: 8px"
              >
                <i class="fal fa-wallet fw-medium" />
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div
        :class="{ 'displayed-none': expand && !expanded }"
        class="collapse navbar-collapse"
      >
        <div v-show="expanded || !expand">
          <router-link
            class="navbar-brand"
            style="padding: 0.5rem !important;"
            to="/manager"
          >
            <img
              class="navbar-brand-img mx-auto"
              src="@assets/img/logo.svg"
            >
          </router-link>
          <ul
            ref="expansive-sidebar"
            class="navbar-nav"
          >
            <template
              v-for="(item, i) in menu.top"
              :key="i"
            >
              <li
                v-if="item.type === 'link'"
                class="nav-item"
              >
                <router-link
                  v-if="user && !item.hide && item.roles.includes(user.role.name)"
                  :class="{
                    active: item.active_name == getActiveRouteName()
                  }"
                  :to="item.path"
                  class="nav-link"
                  @click="handlerAmplitude(item.title)"
                >
                  <i :class="item.icon" />
                  <template v-if="!(expand && !expanded)">
                    <span>{{ $t(item.title) }}</span>
                    <div
                      v-if="item.title == '3C Voice' && telephonyRouteStatus != 'exclusive3cVoice'"
                      class="text-small fw-medium nav-badge-blue ms-auto"
                    >
                      R$ 0,06 min
                    </div>
                    <div
                      v-if="item.id == 'crm'"
                      class="text-small fw-medium nav-badge-blue ms-auto"
                    >
                      {{ $t("free_capitalize") }}
                    </div>
                    <div
                      v-if="soonProductsArray.includes(item.title)"
                      class="text-small fw-medium nav-badge-gray ms-auto"
                    >
                      {{ $t("sooner") }}
                    </div>
                    <div
                      v-if="newProductsArray.includes(item.title)"
                      class="text-small fw-medium nav-badge-blue ms-auto"
                    >
                      Novo
                    </div>
                  </template>
                </router-link>
              </li>
              <li
                v-if="item.type === 'redirect'"
                class="nav-item"
              >
                <a
                  v-if="user && !item.hide && item.roles.includes(user.role.name)"
                  class="nav-link"
                  style="color: #3057f2"
                  @click="handlerAmplitude(item.title)"
                  :href="item.path"
                  target="_blank"
                >
                  <i :class="item.icon" />
                  <template v-if="!(expand && !expanded)">
                    <span>{{ $t(item.title) }}</span>
                  </template>
                </a>
              </li>
              <li
                v-else-if="item.type == 'group'"
                :data-test="`${item.id}`.replaceAll(' ', '').toLowerCase() + `-group`"
                class="nav-item"
              >
                <template v-if="user && item.roles.includes(user.role.name)">
                  <a
                    v-if="!item.hide"
                    :class="{ active: item.active_name.includes(getActiveRouteName()) }"
                    :href="'#' + item.id"
                    aria-controls="sidebarPages"
                    aria-expanded="false"
                    class="nav-link collapsed"
                    data-bs-toggle="collapse"
                    role="button"
                    @click="navigateToPath(item.path)"
                  >
                    <i :class="item.icon" />
                    <template v-if="!(expand && !expanded)">
                      <span
                        class="text-capitalize"
                      >
                        {{ $t(item.title) }}
                      </span>
                    </template>
                  </a>
                  <div
                    :id="item.id"
                    class="collapse"
                  >
                    <ul class="nav nav-md flex-column overflow-ellipsis">
                      <li class="nav-item">
                        <template
                          v-for="(subitem, j) of item.children"
                          :key="j"
                        >
                          <router-link
                            v-if="user && subitem.roles.includes(user.role.name)"
                            :class="{
                              'tx-text-200': subitem.active_name == getActiveRouteName()
                            }"
                            :to="subitem.path"
                            class="nav-link"
                            @click="handleIntercom(subitem.title)"
                          >
                            {{ $t(subitem.title) }}
                          </router-link>
                        </template>
                      </li>
                    </ul>
                  </div>
                </template>
              </li>
              <hr
                v-if="item.type == 'separator' && isManager"
                class="navbar-divider"
                style="margin: 0.25rem 1.5rem;"
              >
            </template>
          </ul>
        </div>
        <div
          v-show="expanded || !expand"
          class="mt-auto"
        />

        <invoice-notification
          v-if="user && user.company"
          v-show="expanded || !expand"
        />
        <ul v-show="expanded || !expand" />
        <ul
          class="navbar-nav"
          v-show="expanded || !expand"
        >
          <li
            v-for="(item, i) in menu.bottom"
            :key="i"
            class="nav-item"
            @click="toggle(i)"
          >
            <router-link
              v-if="item.title === 'reports_capitalize'"
              class="menu-bottom-router"
              :class="{ 'tx-blue-300': item.active_name.includes(getActiveRouteName()) }"
              to="/manager/reports"
            >
              <i :class="item.icon" />
              <span v-if="!(expand && !expanded)">
                {{ $t(item.title) }}
              </span>
            </router-link>
            <div
              class="dropup"
              v-if="user && !item.hide && item.roles.includes(user.role.name) && item.title === 'settings_omni'"
            >
              <a
                :ref="`dropdownMenu${i}`"
                :class="{ 'tx-blue-300': item.active_name.includes(getActiveRouteName()) }"
                aria-expanded="false"
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                role="button"
              >
                <i :class="item.icon" />
                <span
                  v-if="!(expand && !expanded)"
                  class="w-100"
                >
                  {{ $t(item.title) }}
                </span>
              </a>
              <ul
                :style="{
                  bottom: 148 - 40 * i + 'px'
                }"
                class="dropdown-menu position-fixed"
                style="left: 240px"
              >
                <li
                  v-for="(subitem, j) in item.children"
                  :key="j"
                >
                  <router-link
                    v-if="user && subitem.roles.includes(user.role.name)"
                    :class="{
                      'tx-text-200': subitem.active_name == getActiveRouteName()
                    }"
                    :to="subitem.path"
                    class="dropdown-item"
                    @click="handleIntercom(subitem.title, i)"
                  >
                    {{ $tc(subitem.title, 2) }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <account-balance
              v-if="user && user.company"
              @click="toggle('balance')"
            />
          </li>
          <li>
            <user-profile
              v-if="!isAdmin"
              v-show="!expand || expanded"
              @click="toggle('profile')"
            />
          </li>
        </ul>
        <div
          v-if="inImpersonate"
          v-show="!expand || expanded"
        >
          <button
            v-if="!expand || (expanded && inImpersonate)"
            :disabled="loadingFlags.updateUser"
            class="btn w-100 btn-primary my-3 btn-sm"
            @click="leftImpersonate()"
          >
            {{ $t("get_back_as_admin") }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { Collapse, Dropdown } from "bootstrap";
import { mapActions, mapGetters, mapMutations } from "vuex";

import menu from "@dialer/manager/menu-config.js";
import UserProfile from "./user-profile/user-profile.vue";
import InvoiceNotification from "./user-profile/invoice-notification.vue";
import AccountBalance from "./account-balance.vue";

export default {
  data() {
    return {
      expanded: false,
      menu: JSON.parse(JSON.stringify(menu)),
      collapses: {},
      compressedMenuStatus: false,
      compressedMenu: false,
      filteredMenu: [],
      keepSidebarOpen: false,
      dropdown: [],
      showSidebar: null,
      newProductsArray: ["Chatbot"],
      soonProductsArray: ["Públicos"],
    };
  },

  props: {
    expand: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    InvoiceNotification,
    UserProfile,
    AccountBalance,
  },
  mounted() {
    this.initCollapses();
    if (this.telephonyRouteStatus == "exclusiveBefore3cVoice" || this.telephonyRouteStatus == "") {
      let voice3CIndex = this.menu.top.findIndex(route => route.title === "3c_voice");
      if (voice3CIndex !== -1) {
        this.menu.top.splice(voice3CIndex, 1);
      }
    }

    if (!this.isBrazil) {
      this.menu.top = this.menu.top.filter(
          route => !(route.title === "sms" || route.title === "3c_voice"),
      );

      let separatorIndex = this.menu.top.findIndex(route => route.type === "separator");
      if (separatorIndex !== -1) {
        this.menu.top.splice(separatorIndex, 1);
      }
    }

    if (this.user && this.user.company.pabx && this.user.company.ring_group_licenses) {
      this.newProductsArray.push("PABX");
    } else {
      this.soonProductsArray.push("PABX");
    }

    if (
        this.user &&
        this.user.company.enabled_recordings_in_stereo &&
        this.user.company.ai_call_evaluation &&
        this.user.company.ai_settings_configured
    ) {
      this.newProductsArray.push("Insights IA");
    } else {
      this.soonProductsArray.push("Insights IA");
    }

    window.addEventListener("click", element => {
      const sidebar = this.$refs.sidebar;
      if (sidebar && !sidebar.contains(element.target)) {
        this.expanded = false;
        this.keepSidebarOpen = false;
      }
    });

    this.menu.bottom.forEach((item, index) => {
      if (item.children) {
        const dropdownMenu = this.$refs[`dropdownMenu${index}`];
        if (dropdownMenu) {
          const dropdown = new Dropdown(dropdownMenu);
          this.dropdown.push(dropdown);
          dropdownMenu.addEventListener("show.bs.dropdown", () => {
            this.keepSidebarOpen = true;
          });
          dropdownMenu.addEventListener("hidden.bs.dropdown", () => {
            this.keepSidebarOpen = false;
          });
        }
      }
    });
  },
  beforeUnmount() {
    this.menu.bottom.forEach((item, index) => {
      if (this.$refs[`dropdownMenu${index}`]) {
        this.$refs[`dropdownMenu${index}`].removeEventListener("show.bs.dropdown", () => {});
        this.$refs[`dropdownMenu${index}`].removeEventListener("hidden.bs.dropdown", () => {});
      }
    });
    if (this.expand && !this.expanded) {
      this.removeDropdownShow();
    }
  },
  computed: {
    ...mapGetters("manager/voice_3c", {
      telephonyRouteStatus: "getTelephonyRouteStatus",
    }),
    ...mapGetters("auth", {
      isManager: "isManager",
      isSupervisor: "isSupervisor",
      user: "getUser",
      inImpersonate: "inImpersonate",
      isAdmin: "isAdmin",
      loadingFlags: "getLoadingFlags",
      showPaymentModal: "showPaymentModal",
      isBrazil: "isBrazil",
    }),
    sidebarStyle() {
      return {
        zIndex: this.showPaymentModal ? 1068 : 1000,
      };
    },
  },

  methods: {
    ...mapActions("auth", ["logout", "leftImpersonateMode", "me"]),
    ...mapMutations("auth", ["trackEvents"]),

    handleIntercom(item, i) {
      if (i === 1) {
        this.dropdown[i - 1].hide()
      }

      if (["Agentes", "Ligações", "Feedbacks", "Intervalos", "Qualificações", "Aproveitamento"].includes(item)) {
        this.trackEvents(`[Manager] Relatório de ${item}`);
      }
    },

    initCollapses() {
      let elements = this.$refs["expansive-sidebar"].querySelectorAll(".navbar-nav .collapse");
      for (let element of elements) {
        this.collapses[element.id] = new Collapse(element, {
          toggle: false,
        });
        element.addEventListener("show.bs.collapse", e => {
          for (let id in this.collapses) if (id != e.target.id) this.collapses[id].hide();
        });
      }
    },

    handlerAmplitude(title) {
      if (["Públicos", "Insights IA", "PABX"].includes(title)) {
        this.trackEvents(`[Manager] Access warmup ${title}`);
      }
    },

    leftImpersonate() {
      this.trackEvents("[Admin] Left Impersonate");
      this.leftImpersonateMode()
          .then(() => {
            this.$router.push("/admin/companies");
          });
    },

    getActiveRouteName() {
      return this.$route.matched && this.$route.matched[1] ? this.$route.matched[1].name : "";
    },
    toggle(index) {
      if (this.showSidebar === index) {
        this.showSidebar = null;
        this.keepSidebarOpen = false;
      } else {
        this.keepSidebarOpen = true;
      }
    },

    removeDropdownShow() {
      const dropdowns = document.querySelectorAll(".dropdown-menu.show");

      dropdowns.forEach(dropdown => {
        dropdown.classList.remove("show");
      });
    },

    navigateToPath(path) {
      if (path) {
        this.$router.push({ path });
      }
    },
  },

  watch: {
    telephonyRouteStatus(telephonyRouteStatus) {
      this.telephonyRouteStatus = telephonyRouteStatus;
    },
  },
};
</script>

<style lang="scss">
.dropdown-manager-sidebar {
  position: fixed !important;
}

.sidebar.navbar {

  &.expand {
    cursor: pointer;
    border-width: 0 1px 0 0 !important;
    border-top-width: 0px !important;
    border-right-width: 1px !important;
    border-bottom-width: 0px !important;
    border-left-width: 0px !important;

    &.navbar-vertical .navbar-brand {
      text-align: start;
      padding: 0.5rem !important;
      margin-bottom: 1.5rem !important;

      .navbar-brand-img {
        min-height: 35px !important;
      }
    }

    & ~ .main-content {
      padding-left: 66px;
    }
  }

  &.navbar-vertical.navbar-expand-md {
    overflow: hidden !important;
    transition: max-width 0.1s !important;
  }

  &.navbar-vertical.navbar-expand-md:hover {
    overflow-y: auto !important;
  }

  overflow: hidden !important;

  &.navbar-vertical .navbar-brand {
    text-align: start;
    padding: 0.5rem !important;
    margin-bottom: 1.5rem !important;

    .navbar-brand-img {
      height: 35px !important;
    }
  }
}

.overflow-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.disable-text-select {
  user-select: none;
}

.navbar-nav .nav-link > .far,
.fal {
  display: inline-block;
  width: 1.5rem;
  line-height: 1.5rem;
  font-size: 1.125rem;
  text-align: center;
  margin-right: 0.5rem;
}

.nav-badge-blue {
  height: 20px;
  border-radius: 6px;
  padding: 0 6px;
  background-color: #e1e9f4;
  color: #3057f2;
  line-height: 20px;
}

.nav-badge-gray {
  height: 20px;
  border-radius: 6px;
  padding: 0 6px;
  background-color: #f4f4f4;
  color: #677c92;
  line-height: 20px;
}

.nav-item {
  .dropup {
    .nav-link {
      &.show {
        color: $color-blue-300;
      }

      &:after {
        display: block;
        content: "\e92e";
        font-family: "Feather";
        margin-left: auto;
        transition: transform 0.2s;
        transform: rotate(-90deg);
      }

      // Expanded
      &[aria-expanded="true"] {
        &:after {
          transform: rotate(-270deg);
        }
      }
    }
  }
}

.dropdown-wallet {
  padding: 0.5rem 1rem !important;

  &:after {
    display: none !important;
  }

  .item-wallet {
    background-color: $color-gray-blue-300;
    border-radius: 10px;
    padding: 0.5rem 0.75rem;

    .far,
    .fal {
      width: 1rem;
      line-height: 1rem;
    }
  }
}

.menu-bottom-router {
  padding: 1rem 1.5rem;
  color: #677C92;

  &:hover {
    color: #373753;
  }
}
</style>
