<template>
  <VueFlow
    id="chatbot"
    :nodes="nodes"
    :edges="edges"
    @connect-end="onConnectEnd"
    @connect-start="onConnectStart"
    @edge-update="onEdgeUpdate"
    @connect="onConnect"
    @edge-update-start="onEdgeUpdateStart"
    @nodes-initialized="layoutGraph('LR')"
    @node-drag-stop="onNodeDragStop"
    connection-mode="Strict"
  >
    <template #node-custom="props">
      <starter-node
        v-bind="props"
        :source-position="props.sourcePosition"
        :target-position="props.targetPosition"
      />
    </template>
  </VueFlow>
  <tool-bar />
</template>

<script>
import {VueFlow, MarkerType, useVueFlow, ConnectionMode} from '@vue-flow/core';
import StarterNode from "@chatbot/pages/workflow/components/nodes/starter-node.vue";
import ToolBar from "@chatbot/pages/workflow/components/tool-bar.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Utils} from "@/utils";
import {useLayout} from "@chatbot/Utils";
import {nextTick} from "vue";

const { addEdges, addNodes, fitView , project } = useVueFlow('chatbot' )

export default {
  components: {
    VueFlow,
    StarterNode,
    ToolBar,
  },
  data() {
    return {
      draggingEdgeSource: null,
      connected: false,
      handleId: null,
      organized: false,
      edgeHover: null,
    };
  },
  methods: {
    ...mapActions("chatbot/chatbots", ["fetchChatbot"]),
    ...mapActions("chatbot/actions", ["updateAction", "updateActionEdge"]),
    ...mapMutations("chatbot/actions", ["setPrevActionId", "setNodesPositions", "addAction", "removeAction", "setActions"]),

    onNodeDragStop({node}) {
      const updatedNodePositions = this.nodesPositions.map((itemNode) => {
        if (itemNode.id === node.id) {
          return {
            id: node.id,
            position: node.position,
          };
        }
        return itemNode;
      });
      this.setNodesPositions(updatedNodePositions);
    },

    onEdgeUpdateStart() {
      this.updatingEdge = true
    },

    onEdgeUpdate({connection}) {
      this.connected = true;
      let payload = {
        source: connection.source,
        target: connection.target,
      }
      this.updateActionEdge(payload).then(() => {
        this.updateAction({actionId: connection.source, payload: {next_action_id: connection.target}})
        this.updatingEdge = false
      })
    },

    onConnectStart(event) {
      this.handleId = event.handleId;
      this.draggingEdgeSource = event.nodeId;
      this.connected = false;
    },
    onConnect(event) {
      this.connected = true;
      const { source, target, } = event;
      if (!source || !target) {
        return;
      }
      if(event.source.split('-')[0] === event.target){
        return;
      }
      this.updateAction({actionId: event.source.split('-')[0], payload: {next_action_id: event.target}})
    },

    onConnectEnd(event) {

      if (this.draggingEdgeSource && !this.connected && !this.updatingEdge) {
        const newNodeId = Utils.generateUniqueID();
        const newNodePosition = project({ x: event.clientX, y: event.clientY });

        let updatedNodePositions = this.nodesPositions || [];

        updatedNodePositions.push({ id: newNodeId, position: newNodePosition });

        this.setNodesPositions(updatedNodePositions);
        const newNode = {
          id: newNodeId,
          data: {
            content: {
              id: newNodeId,
              type: 'default',
              previous_action_id: this.handleId ? this.handleId.split('-')[1]:this.draggingEdgeSource,
              position: newNodePosition
            }
          },
          type: 'custom',
          position: newNodePosition,
        };

        this.nodesPositions.push({ id: newNodeId, position: newNodePosition });
        this.addAction(newNode.data.content);
        addNodes(newNode);

        const newEdge = {
          id: `edge-${newNodeId}`,
          source: this.draggingEdgeSource,
          target: newNodeId,
          type: 'default',
          markerEnd: MarkerType.Arrow,
          sourceHandle: this.handleId ? this.handleId : null,
        };
        addEdges(newEdge);
        this.handleId = null;
      }
    },

    layoutGraph(direction) {
      if (!this.organized) {

        const {nodes, edges} = useVueFlow('chatbot');
        const {layout} = useLayout()

        const updatedNodes = layout(nodes.value, edges.value, direction)
        const nodesPositions = updatedNodes.map((node) => {
          return {
            id: node.id,
            position: node.position,
          };
        });
        this.setNodesPositions(nodesPositions)
        nextTick(() => {
          fitView()
        })
        this.organized = true;
      }
    }
  },

  computed: {
    ConnectionMode() {
      return ConnectionMode
    },
    ...mapGetters("chatbot/actions", {
      actions: "getActions",
      prevActionId: "getPrevActionId",
      nodesPositions: "getNodesPositions",
    }),
    nodes() {
      const defaultPosition = {x: 0, y: 0};
      return this.actions.flatMap(action => {
        const nodes = [];

        if (action.type === 'default') {
          nodes.push({
            id: action.id,
            data: {content: action},
            type: 'custom',
            position: action.position,
          });
        }

        if (action.type !== 'multiple_choice_item') {
          const actionId = action.id ? action?.id.toString() : Utils.generateUniqueID();

          const nodePosition = this.nodesPositions.find(pos => pos.id === action?.id?.toString())?.position || defaultPosition;

          nodes.push({
            id: actionId,
            data: {content: action},
            type: 'custom',
            position: nodePosition,
          });
        }

        if(action.transfer_to_group_channel_id){
          const transferId = `${action.id}-transfer`;
          const nodePosition = this.nodesPositions.find(pos => pos.id === transferId)?.position || defaultPosition;
          nodes.push({
            id: transferId,
            data: {content: {parentId: action.id,
                id: transferId,
                type: "transfer",
                transfer_to_group_channel_id: action.transfer_to_group_channel_id,}},
            type: 'custom',
            position: nodePosition,
          });
        }

        if (action.default_next_action) {
          const nextActionId = `${action.id}-${action.default_next_action}`;
          const nodePosition = this.nodesPositions.find(pos => pos.id === nextActionId)?.position || defaultPosition;
          const backId = action.default_next_action === 'back' ? action.next_action_id : null;
          nodes.push({
            id: nextActionId,
            data: {content: {parentId: action.id,
                id: `${action.id}-${action.default_next_action}`,
                type: action.default_next_action,
              nextActionId:backId}},
            type: 'custom',
            position: nodePosition,
          });
        }

        return nodes;
      }).filter(node => node);
    },

    edges() {
      return this.actions
          .filter(action => action.next_action_id || action.default_next_action || action.transfer_to_group_channel_id)
          .flatMap(action => {
            const edges = [];

            const source = action.type === "multiple_choice_item"
                ? action.chatbot_action_id?.toString()
                : action.id?.toString();

            const handleSource = action.type === "multiple_choice_item"
                ? `handle-${action.id}`
                : `source-${action.id}`;

            const nextAction = action.default_next_action === "back"
                ? `${action.id}-back`
                : action.default_next_action
                    ? `${action.id}-${action.default_next_action}`
                    : action.transfer_to_group_channel_id
                        ? `${action.id}-transfer`
                        : action.next_action_id?.toString();
            const updatable = action.default_next_action === null && action.transfer_to_group_channel_id === null;

            if (source && nextAction) {
              edges.push({
                id: `edge-${source}-${nextAction}`,
                source: source,
                target: nextAction,
                sourceHandle: handleSource,
                type: 'default',
                markerEnd: MarkerType.Arrow,
                updatable: updatable
              });
            }

            return edges;
          });
    },
  },
  async created() {
    await this.fetchChatbot(this.$route.params.id);
  },
  unmounted() {
    this.setNodesPositions([])
    this.setActions([])
  }
};
</script>
<style >


</style>