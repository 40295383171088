<template>
  <div
    class="preview-file b5"
    ref="preview-file"
  >
    <div>
      <div
        style="cursor: pointer; right: 15px; position: absolute; top: 0px"
        class="b5"
        @click="$emit('close')"
      >
        <span
          class="fe fe-x text-muted"
          style="font-size: 27px"
        />
      </div>
      <div class="center text-center">
        <div v-if="types.image.includes(file.type)">
          <div class="row b2">
            <div class="col-lg-8 offset-lg-2 b3 text-center px-0">
              <img
                class="img b4"
                :src="createObjectURL(file)"
                style="object-fit: cover !important; border-radius: 10px"
              >
            </div>
            <div class="col-lg-8 offset-lg-2 b3 b8 p-0">
              <div class="d-flex flex-row">
                <textarea
                  cols="30"
                  rows="10"
                  class="col"
                  v-model="text"
                />
                <button
                  class="submit"
                  @click="emitImage()"
                >
                  <span class="fe fe-send h1 b3" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="types.audio.includes(file.type)">
          <audio
            class="w-50"
            controls
          >
            <source :src="createObjectURL(file)">
          </audio>
          <h1 class="text-muted b8 m-0 py-3">
            {{ file.name }}
          </h1>
          <small class="text-muted b2 m-0">
            {{ formatBytes(file.size) }}
          </small>
          <br>
          <button
            class="submit mx-0 my-3"
            @click="$emit('sendAudio')"
          >
            <span class="fe fe-send h1 b3" />
          </button>
        </div>
        <div v-else-if="types.video.includes(file.type)">
          <video
            style="min-width: 35rem"
            controls
          >
            <source
              :src="createObjectURL(file)"
              type="video/mp4"
            >
          </video>
          <div
            class="d-flex flex-row"
            style="width: 35rem !important; margin: auto"
          >
            <textarea
              style="width: 320px"
              cols="30"
              rows="10"
              class="col"
              v-model="text"
            />
            <button
              class="submit"
              @click="emitVideo()"
            >
              <span class="fe fe-send h1 b3" />
            </button>
          </div>
        </div>
        <div v-else>
          <file-icon
            :size="150"
            :file-name="file.name"
          />
          <h1 class="text-muted b8 m-0 py-3">
            {{ file.name }}
          </h1>
          <small class="text-muted b2 m-0">
            {{ formatBytes(file.size) }}
          </small>
          <br>
          <button
            class="submit mx-0 my-3"
            @click="emitDocument()"
          >
            <span class="fe fe-send h1 b3" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileIcon from "./file-icon.vue";
import {Utils, WhatsappMediaTypes} from "@/utils";
import {mapMutations} from "vuex";

export default {
  emits: ["close", "sendDocument", "sendImage", "sendAudio", "sendVideo"],

  data() {
    return {
      types: WhatsappMediaTypes,
      text: "",
      notAcceptedFileTypes: ["", "application/octet-stream", "application/x-msdownload", "text/x-sh"],
      fileMaxSize: 16000000
    };
  },

  mounted() {
    window.addEventListener("keydown", this.keydown);
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.keydown);
  },

  components: {
    fileIcon
  },

  props: {
    file: {
      type: File,
      default: null
    }
  },

  methods: {
    ...mapMutations("system", ["setErrorMessage", "setCustomizedTitleMessage"]),

    validate() {
      if (this.file)
        if (this.file.size > this.fileMaxSize) {
          this.$emit("close");
          this.setErrorMessage(this.$t('large_file'));
          this.setCustomizedTitleMessage(this.$t('too_large_file'));
        } else if (this.notAcceptedFileTypes.includes(this.file.type)) {
          this.$emit("close");
          this.setErrorMessage(this.$t('not_supported_format'));
        }
    },

    keydown(e) {
      if (e.keyCode == 13) {
        if (this.types.image.includes(this.file.type)) this.emitImage();
        else if (this.types.audio.includes(this.file.type)) this.$emit("sendAudio");
        else if (this.types.video.includes(this.file.type)) this.emitVideo();
        else this.emitDocument();
      }
    },

    emitImage() {
      this.$emit("sendImage", this.text);
      this.text = "";
    },

    emitVideo() {
      this.$emit("sendVideo", this.text);
      this.text = "";
    },

    emitDocument() {
      this.$emit("sendDocument");
    },

    formatBytes: Utils.formatBytes,

    createObjectURL(file) {
      return URL.createObjectURL(file);
    }
  },

  watch: {
    file: {
      immediate: true,
      handler() {
        this.validate();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.preview-file {
  background-color: #edf2f9;
  padding: 36px 0px;
  overflow: hidden;
  height: calc(100vh - 56px);
  position: relative;
  z-index: 999;

  > div {
    height: 100%;

    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      .img {
        width: 100%;
        background-color: black;
        height: 60vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 100%;
      }

      .submit {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        position: relative;
        margin: 13px;
        margin-right: 0px;
        border: none;
        color: white;
        background-color: #2c7be5;
      }

      textarea {
        width: 100%;
        font-size: 14px;
        padding: 15px !important;
        font-weight: 200;
        margin-top: 13px;
        margin-bottom: 13px;
        padding: 0 !important;
        padding-left: 13px !important;
        padding-right: 13px !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
        height: 46px;
        line-height: 20px;
        overflow: hidden;
        resize: none;
        border: none;
        outline: none;
        border-radius: 26px;
      }
    }
  }
}
</style>
