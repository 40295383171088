import axiosOmni from "@/plugins/axios-omni.js";
import router from "@/router";

export default {
  findChat({ commit, getters }, chatId) {
    commit("startLoading", "findChat");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/whatsapp/chats/" + chatId)
        .then(response => {
          const currentChat = getters.getCurrentChat;
          let chat = response.data.data;
          
          if (!currentChat[chat.id]) commit("setCurrentChat", chat);
          commit("setOpenChat", chat);

          resolve(chat);
        })
        .catch(error => {
          if (error.response.status != 404)
            commit("system/setErrorMessage", error.response.data, {
              root: true
            });
          reject(error.response.status);
        })
        .finally(() => {
          commit("stopLoading", "findChat");
        });
    });
  },

  sendForward({ commit }, payload) {
    commit("startLoading", "sendForward");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/message/forward_message", payload)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          commit("stopLoading", "sendForward");
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "sendForward");
        });
    });
  },

    fetchInProgress({ commit, dispatch, getters }, findForward) {
        if(!findForward) {
            commit('startLoading', 'fetchInProgress');
        }
    const params = getters.getInProgressParams

    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/chats/in_progress', { params })
        .then(response => {
            const chats = response.data.data.chats.data
            if(findForward){
                const groupsChats = getters.getAllowedChatsToForward?.groups || {};
                commit('setAllowedChatsToForward', { inProgress: chats, groups: groupsChats});
                return
            }
            const flag = "inProgress"

          if (params.page === 1 && !getters.getFirstMessagesAreLoaded[flag] ) {
            dispatch("fetchFirstChatsMessages", { chats: chats, flag: flag })
          }
          chats.forEach((chat) =>{
            commit("setCurrentChat", chat)
          })
          commit("addInProgress", chats);
          commit("setInProgressPagination", response.data.data.chats.meta.pagination);
          commit("setUnreadConversations", response.data.data.unread_conversations);
          commit("setHasNotifiedInProgress", response.data.data.has_notified_chats);
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit('stopLoading', 'fetchInProgress');
      });
    })
  },

  fetchQueue({ commit, getters }) {
    commit('startLoading', 'fetchQueue');
    const params = getters.getQueueParams

    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/chats/queue', { params })
        .then(response => {
          const chats = response.data.data.chats.data
          chats.forEach((chat) =>{
            commit("setCurrentChat", chat)
          })
          commit("addQueue", chats);
          commit("setQueuePagination", response.data.data.chats.meta.pagination);
          commit("setHasNotifiedQueue", response.data.data.has_notified_chats);
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit('stopLoading', 'fetchQueue');
      });
    })
  },

  fetchInSnooze({ commit, getters }) {
    commit('startLoading', 'fetchInSnooze');
    const params = getters.getInSnoozeParams

    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/chats/in_snooze', { params })
        .then(response => {
          const chats = response.data.data
          chats.forEach((chat) =>{
            commit("setCurrentChat", chat)
          })
          commit("addInSnooze", chats);
          commit("setInSnoozePagination", response.data.meta.pagination);
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit('stopLoading', 'fetchInSnooze');
      });
    })
  },

  fetchGroups({ commit, getters }, findForward) {
     if(!findForward) {
        commit("startLoading", "fetchGroups");
     }
    const params = getters.getGroupsParams

    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/chats/groups', { params })
        .then(response => {
          const chats = response.data.data.groups.data
            if(findForward){
                const inProgressChats = getters.getAllowedChatsToForward?.inProgress || {};
                commit('setAllowedChatsToForward', { groups: chats, inProgress: inProgressChats});
                return
            }
          chats.forEach((chat) =>{
            commit("setCurrentChat", chat)
          })
          commit("addGroups", chats);
          commit("setGroupsPagination", response.data.data.groups.meta.pagination);
          commit("setUnreadGroupsConversations", response.data.data.unread_groups_conversations);
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit('stopLoading', 'fetchGroups');
      });
    })
  },

  fetchFinished({ commit, getters }) {
    commit('startLoading', 'fetchFinished');
    const params = getters.getFinishedParams

    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/chats/finished/all', { params })
        .then(response => {
          const chats = response.data.data
          chats.forEach((chat) =>{
            commit("setCurrentChat", chat)
          })
          commit("addFinished", chats);
          commit("setFinishedPagination", response.data.meta.pagination);
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit('stopLoading', 'fetchFinished');
      });
    })
  },

  fetchInChatbot({ commit, getters }) {
    commit('startLoading', 'fetchInChatbot');
    const params = getters.getInChatbotParams

    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/chats/in_chatbot', { params })
        .then(response => {
          const chats = response.data.data
          chats.forEach((chat) =>{
            commit("setCurrentChat", chat)
          })
          commit("addInChatbot", chats);
          commit("setInChatbotPagination", response.data.meta.pagination);
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit('stopLoading', 'fetchInChatbot');
      });
    })
  },

  findGroupParticipants({ commit }, payload) {
    commit("startLoading", "findGroupParticipants");
    commit("setGroupParticipants", {});
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/whatsapp/chats/group/participants`, { params: payload.fields })
        .then(response => {
          commit("setGroupParticipants", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findGroupParticipants");
        });
    });
  },

  findGroupAgents({ commit, getters }, payload) {
    commit("startLoading", "findGroupAgents");
    commit("setGroupAgents", {});
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/chats/group/${payload.id}/agents`, { params: getters.getGroupAgentsParams })
        .then(response => {
          commit('setGroupAgents', response.data.data.agents);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findGroupAgents");
        });
    });
  },

  deleteGroup({ commit, dispatch}, payload){
    commit("startLoading", "deleteGroup");
    return new Promise((resolve, reject) => {
      axiosOmni
        .delete(`/whatsapp/chats/group/${payload.chatId}?team_id=${payload.teamId}`)
        .then((response) => {
          commit("system/setSuccessMessage", 'success_message.delete_group_omni', { root: true });
          commit("clearGroups")
          commit("setGroupsPagination", {
            per_page: 25,
            current_page: 1
          })
          dispatch("fetchGroups");
          resolve(response.data);
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "deleteGroup");
        })
    });
  },

  addResponsibleGroup({ commit }, payload) {
    commit("setErrors", {});
    commit("startLoading", "addResponsibleGroup");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post(`/whatsapp/chats/group/${payload.chat.id}/responsible`, payload.agents)
        .then(() => {
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        })
        .finally(() => {
          commit("stopLoading", "addResponsibleGroup");
        });
    });
  },

  createNewChat({ commit }, payload) {
    commit("setErrors", {});
    commit("startLoading", "createNewChat");
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/chats/open_new_chat', payload.fields)
        .then((response) => {
          commit('setCurrentChat', response.data.data)
          commit('setOpenChat', response.data.data)
          commit("setNewChatInfo", response.data.data);
          commit("setFinalizedByManager", false);
          commit("updateInProgressTalkOrder", response.data.data);
          resolve(response.data.data.id);
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "createNewChat");
        });
    });
  },

  clearUnreadMessageCount({ commit }, payload) {
    commit("startLoading", "clearUnreadMessageCount");
    return new Promise((resolve, reject) => {
      axiosOmni
        .put("/whatsapp/chats/" + payload.id + "/unread")
        .then(() => {
          commit("updateTalksInfo", {
            chat_id: payload.id,
            unread: 0
          });
          commit("updateGroupsTalksInfo", {
            chat_id: payload.id,
            unread: 0
          });
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoading", "clearUnreadMessageCount");
        });
    });
  },

  putOnSnooze({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosOmni
        .post(`/whatsapp/chats/${payload.chat_id}/snooze`, payload.fields)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          commit("setChatAsInSnooze", {
            chat_id: payload.chat_id,
            end_snooze: payload.fields.end_snooze
          });
          resolve();
        })
        .catch(() => {
          commit("system/setErrorMessage", "errors_message.put_on_snooze", { root: true });
          reject();
        });
    });
  },

  acceptQueue({ commit }, payload) {
    commit("startLoading", "acceptQueue");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/chats/accept_queue/" + payload.id)
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "acceptQueue");
        });
    });
  },

  transferChat({ commit }, { id, fields }) {
    commit("startLoading", "transferChat");
    commit("setErrors", {});
    return new Promise((resolve, reject) => {
      axiosOmni
        .post(`/whatsapp/chats/${id}/transfer`, fields)
        .then(() => {
           commit("transferChatToQueue", { id: id });

          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading", "transferChat");
        });
    });
  },

  finishChat({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "finishChat");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/chats/" + payload.id + "/finish", payload.fields)
        .then(() => {
          commit("clearFinished");
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading", "finishChat");
        });
    });
  },

  checkContacts({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "checkContacts");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/contacts/check_contacts", payload.fields)
        .then(response => {
          commit("setCheckedContacts", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          error.response.status == 422 && error.response.data.errors && commit("setErrors", error.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading", "checkContacts");
        });
    });
  },

  fetchVerifyNotifiedChats({ commit }, list) {
    commit("startLoading", "fetchVerifyNotifiedChats");
    return new Promise((resolve, reject) => {
      axiosOmni.get("/whatsapp/chats/verify-notified-chats", { params: { list: list } })
        .then(response => {
          if (list == "in_progress") {
            commit("setHasNotifiedInProgress", response.data.has_notified_chats);
          } else if (list == "queue") {
            commit("setHasNotifiedQueue", response.data.has_notified_chats);
          }
          resolve();
        })
        .catch(error => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          error.response.status == 422 && error.response.data.errors && commit('setErrors', error.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading", "fetchVerifyNotifiedChats");
        });
    });
  },

  incrementInProgressCurrentPage({ commit }) {
    commit("incrementInProgressCurrentPage");
  },
  incrementGroupsCurrentPage({ commit }) {
    commit("incrementGroupsCurrentPage");
  },
  incrementQueueCurrentPage({ commit }) {
    commit("incrementQueueCurrentPage");
  },
  incrementInChatbotCurrentPage({ commit }) {
    commit("incrementInChatbotCurrentPage");
  },
  incrementInSnoozeCurrentPage({ commit }) {
    commit("incrementInSnoozeCurrentPage");
  },
  incrementFinishedCurrentPage({ commit }) {
    commit("incrementFinishedCurrentPage");
  },

  resetInProgressCurrentPage({ commit }) {
    commit("setInProgressCurrentPage", 1);
  },
  resetFinishedCurrentPage({ commit }) {
    commit("setFinishedCurrentPage", 1);
  },

  resetChatsPagination({ commit }) {
    commit("setInProgressCurrentPage", 1);
    commit("setGroupsCurrentPage", 1);
    commit("setQueueCurrentPage", 1);
    commit("setInChatbotCurrentPage", 1);
    commit("setInSnoozeCurrentPage", 1);
    commit("setFinishedCurrentPage", 1);
  },

  clearChats({ commit }) {
    commit("clearInProgress");
    commit("clearGroups");
    commit("clearQueue");
    commit("clearInChatbot");
    commit("clearInSnooze");
    commit("clearFinished");
  },

  resetInChatbot({commit}){
    commit("setInChatbotCurrentPage", 1);
    commit("clearInChatbot");
  },
  resetInSnooze({commit}){
    commit("setInSnoozeCurrentPage", 1);
    commit("clearInSnooze");
  },
  resetFinished({commit}){
    commit("setFinishedCurrentPage", 1);
    commit("clearFinished");
  },


  addFilters({ commit }, payload) {
    commit("setFilters", payload);
  },

  updateTalksTab({ commit }, tab) {
    commit("setTalksTab", tab);
  },

  searchMessages({ state, getters, commit }, payload) {
    commit("startLoading", "searchMessages");
    if (payload.page > getters.getSearchedMessagesPagination.last_page) return;
    axiosOmni
      .post("/whatsapp/message/search", payload)
      .then(response => {
        const messagesData = response.data;
        const pagination = response.data.meta.pagination;
        if (state.lastTermSearchedMessage && state.lastTermSearchedMessage !== payload.search)
          commit("clearSetSearchedMessages");
        if (messagesData.meta.pagination.current_page === 1)
          commit("setSearchedMessages", { messages: messagesData.data });
        else commit("setSearchedMessagesNextPage", { messages: messagesData.data });
        commit("setLastTermSearchedMessage", payload.search);
        commit("setSearchedMessagesPagination", pagination);
      })
      .catch(error => {
        commit("system/setErrorMessage", error.response?.data.detail || "errors_message.search_messages", { root: true });
      })
      .finally(() => {
        commit("stopLoading", "searchMessages");
      });
  },

  findAllProtocols({ commit }, payload) {
    commit("startLoading", "findAllProtocols");
    commit("setAllProtocols", {});
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/whatsapp/chats/${payload}/protocols`)
        .then(response => {
          commit("setAllProtocols", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findAllProtocols");
        });
    });
  },

  findProtocol({ commit }, payload) {
    commit("startLoading", "findProtocol");
    commit("setProtocol", {});
    return new Promise((resolve, reject) => {
      axiosOmni
        .post(`/whatsapp/message/search`, {
          search: payload.protocol_number,
          chat_id: payload.chat_id,
          per_page: 150
        })
        .then(response => {
          commit("setProtocolFound", null);
          if (response.data.data.length === 2) {
            commit("setProtocolFound", response.data.data[1]);
          } else if (response.data.data.length === 0) {
            throw new Error()
          } else {
            commit("setProtocolFound", response.data.data[0]);
          }
          resolve();
        })
        .catch(() => {
          commit("system/setErrorMessage", "errors_message.find_protocol", { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findProtocol");
        });
    });
  },

  fetchFirstChatsMessages({ commit, dispatch }, {chats, flag}){
    commit("setFirstMessagesLoadTotal", chats.length);
    const dispatchPromises = chats.map(chat => {
      return dispatch("whatsapp/message/fetchCurrentMessages", {
        chat_id: chat.id,
        instance_id: chat.instance_id,
        page: 1
      }, {root: true}).finally(() => {
        commit("incrementFirstMessagesLoadChats")
      })
    })

    Promise.all(dispatchPromises).finally(() => {
      commit("setFirstMessagesAreLoaded", flag)
    })
  },

  async reconnectWhatsapp({ getters, rootGetters, commit, dispatch }){
    const paginations = rootGetters["whatsapp/message/getAllPaginations"]
    if (Object.keys(paginations).length === 0) {
      return
    }

    commit("startLoading", "reconnectWhatsapp");
    Object.keys(paginations).forEach((chatId) => {
      commit("whatsapp/message/clearChatMessages", chatId, { root: true })
    })

    const openChat = getters.getOpenChat
    commit("resetCurrentChat", openChat?.id)
    if (openChat && router.currentRoute.value.name === "whatsapp-chat" ) {
      await router.push("/whatsapp")
      await router.push(`/whatsapp/${openChat.id}`)
    }

    let dispatchPromises = [
      dispatch("fetchInProgress"),
      dispatch("fetchGroups"),
      dispatch("fetchQueue"),
    ]
    switch (getters.getTalksTab) {
      case "inChatbot":
        dispatchPromises.push(dispatch("fetchInChatbot"))
        break;
      case "snooze":
        dispatchPromises.push(dispatch("fetchInSnooze"))
        break;
      case "finished":
        dispatchPromises.push(dispatch("fetchFinished"))
        break;
    }
    Promise.all(dispatchPromises).finally(() => {
      commit("stopLoading", "reconnectWhatsapp");
    })
  }
};
