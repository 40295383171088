<template>
  <div class="d-flex align-items-center">
    <div class="card mb-0 card-starter">
      <div class="card-body p-0">
        <div
          class="d-flex align-items-center card-starter-body"
        >
          <action-component
            icon-class="fa-regular fa-play"
            icon-color="#3057F2"
            :name="$t('start')"
          />
        </div>
        <hr class="w-100">
        <div
          class="d-flex flex-column justify-content-center card-starter-text"
        >
          <app-button
            v-if="action?.need_channel"
            class="border-0 w-100"
            color="btn-outline-primary"
            @click="openModal"
          >
            + {{ $t('channel_select') }}
          </app-button>
          <div v-else>
            <p class="m-0 tx-text-gray">
              {{ $t("channel") }}
            </p>
            <p class="m-0">
              {{ groupChannel }}
            </p>
          </div>
        </div>
        <div
          class="d-flex w-100 justify-content-end px-4 tx-text-gray"
          v-if="!this.action.need_channel"
        >
          <p class="mb-2">
            {{ $t("step_next") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "CardStarter",


  components: {
    AppButton,
    ActionComponent
  },

  props: {
    action: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters("chatbot/chatbots", {
      chatbot: "getChatbot",
    }),

    groupChannel() {
      return this.chatbot.group_channel_name
    }
  },
  methods: {
    ...mapMutations("chatbot/actions", ["setOffcanvasSelectChannelIsOpen"]),
    openModal(){
      this.setOffcanvasSelectChannelIsOpen(true)
    }
  }
}
</script>
<style scoped>
.card-starter-body {
  padding: 1rem 1rem 0 1rem;
  width: 350px
}
.card-starter-text{
  padding: 0 1rem 1rem 1rem;
  width: 350px
}
.card-starter{
  min-width: 350px;
}
</style>
