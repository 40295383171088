import io from "socket.io-client"
import { AgentStatus, CallMode, CallStatus, Utils } from "@/utils";
import timer from "@/plugins/timer";
import router from "@/router"
import audioPlayer from "@/plugins/audio-player";
import UserSocket from "@/websocket/userSocket";

export default (store) => {

  var socket = null;
  socket = io(process.env.VUE_APP_JK78OP, {
    transports: ["websocket"], query: {
      token:
        store.getters["auth/getUser"] ?
          store.getters["auth/getUser"].api_token :
          "",
    },
    forceNew: true,
    reconnection: true,
  });

  const agentSocket = new UserSocket(
    store.getters["auth/getUser"],
    socket,
  );

  socket.on("disconnect", () => {
    console.log("Disconnect");
    store.commit("system/setSocketIOStatus", "disconnected");
  });

  const setStatus = (status = "") => {
    store.commit("system/setStatus", status);
  }

  socket.on("connect_error", (error) => {
    console.log("[socket.io] connect error");
    const { message } = error;
    if (message.startsWith("Authentication error: cannot authenticate within the application with token")) {
      store.dispatch("auth/logout");
      router.push({
        name: "login",
      });
    }
  });

  const startTimer = (seconds, countdown = false, target = undefined) => {
    let options = {
      startValues: {
        seconds,
      },
      countdown,
    };

    if (target !== undefined) {
      store.commit("system/setTimerTargetAchieved", false);
      options.target = { seconds: target }
    }

    store.commit("system/setTimeAsString", Utils.timeFormat(seconds));
    timer.stop();
    timer.start(options)
  }

  const isRingGroupCall = (event) => {
    const { campaign, campaignId } = event;
    if (campaign === null || campaignId === 0 || campaignId === null) {
      return true;
    }
    return false;
  }

  const handleCampaign = campaign => {
    const call = store.getters["agent/call/getCall"];
    const isCallQualified = store.getters["agent/qualification/getCallQualificated"];
    const isManualTimeLimited = store.getters["system/isManualTimeLimited"];

    if (isCallQualified || !call) {
      if (isManualTimeLimited) {
        store.commit("system/setManualTimeLimited", true);
        store.commit("system/setManualTimeout", campaign.exit_manual_mode);
        startTimer(campaign.exit_manual_mode, true, 0);
      }

      store.commit("system/setConsulting", false);
      store.commit("agent/call/setManualCallACW", null);
      store.commit("agent/call/setMailing", null);
      store.commit("agent/call/setCall", null);
      setStatus(AgentStatus.IDLE);
    } else {
      if (campaign.acw_timeout > 0) {
        startTimer(campaign.acw_timeout, true, 0);
        store.commit("system/setAcwTimeout", campaign.acw_timeout);
      } else {
        startTimer(0);
      }

      setStatus(AgentStatus.ACW);
    }
  };

  const performPostCallActions = seconds => {
    setTimeout(async () => {
      let campaign = store.getters["agent/agent/getCampaign"];
      if (!campaign) {
        await store.dispatch("agent/agent/findLoggedCampaign");
        campaign = store.getters["agent/agent/getCampaign"];
      }
      handleCampaign(campaign);
    }, seconds * 1000);
  };

  socket.on("connect", () => {
    store.commit("system/setSocketIOStatus", "connected");
    console.log("[socket.io] connected!")
    store.dispatch("system/connect")
  });

  socket.io.on("reconnect", (attempt) => {
    store.commit("system/setSocketIOStatus", "connected");
    console.log("[socket.io] reconnected, attemp:" + attempt)
    store.dispatch("whatsapp/chat/reconnectWhatsapp")
  });

  socket.on("agent-login-failed", () => {
    store.commit("agent/campaign/stopLoadingFlag", "fetchCampaigns");
    store.commit("agent/agent/stopLoading");
    store.commit("system/setErrorMessage", "errors_message.join_campaign");
    store.commit("agent/call/setAgentCallStatus", "agent-login-failed");
    store.commit("agent/agent/stopLoadingFlag", "login");
    store.commit("system/setMode", "");
  });

  socket.on("agent-was-logged-out", () => {
    store.commit("agent/agent/stopLoadingFlag", "logout");
    store.commit("agent/agent/setCampaign", null);
    store.commit("agent/ring_group/setConsultedAgentId", null);
    store.commit("system/setJsSipStatus", "registered");
    store.commit("system/setMode", "");
    store.commit("system/setConsulting", false);
    store.commit("agent/call/setManualCallACW", null);
    store.commit("agent/call/setMailing", null);
    store.commit("agent/call/setCall", null);
    setStatus(AgentStatus.OFFLINE);
    timer.stop();
  });

  socket.on("agent-is-connected", (event) => {
    console.log("[agent-is-connected]", event);

    // OFFLINE
    if (Number(event.status) === AgentStatus.OFFLINE) {
      store.commit("agent/agent/stopLoadingFlag", "logout");
      store.commit("agent/agent/setCampaign", null);
      store.commit("agent/ring_group/setConsultedAgentId", null);
      store.commit("system/setJsSipStatus", "registered");
      store.commit("system/setMode", "");
      store.commit("system/setConsulting", false);
      store.commit("agent/call/setManualCallACW", null);
      store.commit("agent/call/setMailing", null);
      store.commit("agent/call/setCall", null);
      store.commit("system/setIntegrationURL", "");
      store.commit("agent/work-break/setIntervalActive", null);
      store.commit("agent/work-break/setScheduledWorkBreak", false);
      store.commit("agent/work-break/setReturnRequested", false);
      setStatus(AgentStatus.OFFLINE);
      timer.stop();
      return;
    }

    // IDLE
    if (event.status === AgentStatus.IDLE) {
      store.commit("system/setMode", "dialer");
      store.commit("system/setConsulting", false);
      store.commit("agent/call/setManualCallACW", null);
      store.commit("agent/call/setMailing", null);
      store.commit("agent/call/setCall", null);
      store.commit("system/setIntegrationURL", "");
      startTimer(event.status_time);
      setStatus(event.status);
      return;
    }

    const isAgentSpeaking =
      event.status === AgentStatus.ON_CALL ||
      event.status === AgentStatus.ON_MANUAL_CALL_CONNECTED ||
      (event.status === AgentStatus.ACW && event.manual_call_acw_status === null);

    // SPEAKING
    if (isAgentSpeaking) {
      const status = event.status === AgentStatus.ON_MANUAL_CALL_CONNECTED ? AgentStatus.ON_CALL : event.status;
      const mode = event.call.call_mode === "receptive" ? "dialer" : event.call.call_mode;
      store.commit("agent/call/stopLoading");
      store.commit("agent/call/stopLoadingFlag", "hangup");
      store.commit("system/setMode", mode);
      store.commit("agent/call/setCall", event.call);
      store.commit("agent/call/setMailing", event.mailing);
      store.commit("agent/qualification/setList", event.qualification.qualifications);
      store.commit("agent/qualification/setCallQualificated", false);
      store.commit("system/setIntegrationURL", event.qualification.url);
      startTimer(event.status_time);
      setStatus(status);

      if (event.schedule) {
        store.commit("agent/call/setMailing", event.schedule.mailing);
      }
    }

    // If agent is in an receptive call
    if (isAgentSpeaking && event.call.call_mode === CallMode.RECEPTIVE) {
      store.commit("agent/agent/stopLoading");
    }

    // Sets the user's schedules if it is in a manual call
    if (isAgentSpeaking && event.call.call_mode === CallMode.MANUAL && event.schedule) {
      store.commit("agent/call/setMailing", event.schedule.mailing);
      return;
    }

    // After call waiting
    const afterCallWaiting = event.status === AgentStatus.ACW && event.manual_call_acw_status === null;
    if (afterCallWaiting) {
      setStatus(event.status);
      store.commit("system/setMode", "dialer");

      store.commit("agent/call/stopLoading");
      store.commit("agent/agent/stopLoading");
      store.commit("system/setConsulting", false);

      let campaign = store.getters["agent/agent/getCampaign"];
      store.commit("system/setAcwTimeout", campaign.acw_timeout);

      if (event.acwTimeout > 0) {

        startTimer(event.acwTimeout, true, event.status_time);
      } else {
        startTimer(event.status_time);
      }
    }


    // Manual
    const manual = event.status === AgentStatus.ON_MANUAL_CALL;
    if (manual) {
      store.commit("system/setMode", "manual");
      setStatus(AgentStatus.IDLE);

      // Improve!
      let campaign = store.getters["agent/agent/getCampaign"];

      if (campaign.exit_manual_mode > 0) {
        store.commit("system/setManualTimeLimited", true);
        store.commit("system/setManualTimeout", campaign.exit_manual_mode);
        startTimer(campaign.exit_manual_mode, true, 0);
      } else {
        store.commit("system/setManualTimeLimited", false);
        startTimer(event.status_time);
      }
    }

    // INTERVAL
    if (event.status === AgentStatus.ON_WORK_BREAK) {
      const isExpired = event.status_time < 0 && event.work_break.minutes > 0;
      const isCountdown = event.work_break.minutes > 0 && event.status_time > 0;
      store.commit("agent/work-break/setIntervalActive", event.work_break);
      store.commit("system/setMode", "dialer");
      store.commit("agent/work-break/stopLoading");
      store.commit("agent/work-break/setScheduledWorkBreak", false);
      store.commit("agent/work-break/setIntervalActive", event.work_break);
      store.commit("agent/work-break/setExpired", isExpired);
      startTimer(Math.abs(event.status_time), isCountdown);
      setStatus(AgentStatus.ON_WORK_BREAK);
    }

    // MANUAL CALL ACW
    const manualCallAcw = event.manual_call_acw_status;
    if (manualCallAcw) {
      store.commit("agent/call/stopLoading");
      store.commit("agent/call/setManualCallACW", event.manual_call_acw_data);
      store.commit("agent/call/setCall", event.call);
      store.commit("system/setMode", CallMode.DIALER);
      store.commit("agent/qualification/setList", event.qualification.qualifications);
      store.commit("agent/qualification/setCallQualificated", false);
      if (event.manual_call_acw_status === AgentStatus.ON_MANUAL_CALL_ACW) {
        setStatus(event.manual_call_acw_status);
      }

      if (event.manual_call_acw_status === AgentStatus.MANUAL_CALL_CONNECTED) {
        setStatus(AgentStatus.ON_CALL);
      }

      if (event.schedule) {
        store.commit("agent/call/setMailing", event.schedule.mailing);
      }
    }
  });

  socket.on("agent-is-idle", async () => {
    let campaign = store.getters["agent/agent/getCampaign"];
    const mode = store.getters["system/getMode"];
    store.dispatch("auth/me");
    store.commit("agent/call/setAgentCallStatus", "agent-is-idle");
    store.commit("agent/call/stopLoading");
    store.commit("agent/call/stopLoadingFlag", "hangup");
    store.commit("agent/agent/stopLoadingFlag", "login");
    store.commit("auth/trackEvents", "[Agent] Agent is idle");
    store.commit("agent/agent/stopLoadingFlag", "exitConsult");
    store.commit("system/stopLoadingFlag", "enterManual");
    store.commit("agent/call/stopLoadingFlag", "startManualCall");

    if (!campaign) {
      await store.dispatch("agent/agent/findLoggedCampaign");
      campaign = store.getters["agent/agent/getCampaign"];
    }

    const handleManualMode = () => {
      const call = store.getters["agent/call/getCall"];
      if (call && call.status == CallStatus.FAILED) return;

      handleCampaign(campaign);
    };

    const handleDialerMode = () => {
      store.commit("system/setConsulting", false);
      store.commit("agent/call/setManualCallACW", null);
      store.commit("agent/call/setMailing", null);
      store.commit("agent/call/setCall", null);
      store.commit("system/setIntegrationURL", "");
      setStatus(AgentStatus.IDLE);
      startTimer(0);
    };

    if (mode == "manual") {
      handleManualMode();
      return;
    }

    handleDialerMode();
  });

  socket.on("call-was-connected", event => {
    if (isRingGroupCall(event)) {
      store.dispatch(
        "agent/ring_group/updateCallStatusAfterCallPABXWasAnswered",
        event,
      );

      return;
    }

    store.commit("agent/call/stopLoading");
    store.commit("agent/qualification/setList", event.qualification.qualifications);
    store.commit("agent/qualification/setCallQualificated", false);
    store.commit("system/setIntegrationURL", event.qualification.url);
    store.commit("agent/agent/setCampaign", event.campaign);

    if (event.call.call_mode === CallMode.MANUAL) {
      store.commit("agent/call/setCall", { ...event.call, status: CallStatus.DIALING });

      if (event.schedule) {
        store.commit("agent/call/setMailing", event.schedule.mailing);
        return;
      }

      store.commit("agent/call/setMailing", event.mailing);
      startTimer(0);
    } else {
      store.commit("agent/call/setCall", event.call);
      setStatus(AgentStatus.ON_CALL);
      startTimer(0);
    }


    if (event.call.call_mode == CallMode.RECEPTIVE) {
      store.commit("agent/agent/stopLoading");
      store.commit("agent/agent/setAgent", event.agent);
      let activeQueue = event.agent.receptive_queues.find((queue) => queue.id == event.call.queue_id);
      store.commit("agent/queue/setActiveQueue", activeQueue);
    }

    store.commit("agent/call/setMailing", event.mailing);
  });

  socket.on("call-was-ended", () => {
    store.commit("system/setConsulting", false);
    store.commit("agent/call/setManualCallACW", null);
    store.commit("agent/call/setMailing", null);
    store.commit("agent/call/setCall", null);
  });

  socket.on("agent-entered-work-break", event => {
    store.commit("agent/work-break/setExpired", false);
    store.commit("agent/work-break/stopLoading");
    store.commit("agent/work-break/setScheduledWorkBreak", false);
    store.commit("agent/work-break/setIntervalActive", event.work_break);
    store.commit("agent/work-break/setReturnRequested", false);
    store.commit("system/setTimerTargetAchieved", false);
    setStatus(AgentStatus.ON_WORK_BREAK);
    if (event.work_break.minutes === 0) {
      startTimer(0);
      return
    }
    startTimer(60 * event.work_break.minutes, true);
  });

  socket.on("agent-enter-work-break-failed", () => {
    store.commit("agent/work-break/setIntervalActive", null);
    store.commit("agent/work-break/setScheduledWorkBreak", false);
    store.commit("system/setErrorMessage", "errors_message.start_interval");
  });

  socket.on("agent-left-work-break", () => {
    store.commit("agent/work-break/setIntervalActive", null);
    store.commit("agent/work-break/setReturnRequested", false);
    store.commit("agent/work-break/stopLoading");
  });

  socket.on("work-break-has-been-scheduled-for-the-agent", () => {
    store.commit("system/setInfoMessage", "work_break_warning_message", { root: true });
  })

  socket.on("agent-in-acw", event => {
    store.commit("agent/call/stopLoading");
    store.commit("agent/call/stopLoadingFlag", "hangup");
    store.commit("agent/agent/stopLoadingFlag", "transferToAgent");
    store.commit("system/setConsulting", false);
    const activeQueue = store.getters["agent/queue/getActiveQueue"];
    let acwTimeout = 0;

    if (activeQueue && activeQueue.acw_timeout) {
      acwTimeout = store.getters["agent/queue/getActiveQueue"].acw_timeout;
      store.commit("agent/agent/setAgent", {});
      store.commit("agent/queue/setActiveQueue", {});
    } else {
      acwTimeout = event.acwTimeout;
    }
    store.commit("system/setAcwTimeout", acwTimeout);
    setStatus(AgentStatus.ACW);
    timer.stop();
    if (acwTimeout > 0)
      startTimer(acwTimeout, true);
    else
      startTimer(0);
  });

  socket.on("agent-entered-manual", async () => {
    store.commit("system/setMode", "manual");
    store.commit("agent/call/setAgentCallStatus", "agent-entered-manual");

    setStatus(AgentStatus.IDLE);
    store.commit("system/stopLoading");
    store.commit("system/stopLoadingFlag", "enterManual");

    let campaign = store.getters["agent/agent/getCampaign"]
    if (!campaign) { // agent is from whatsappManualCall
      await store.dispatch("agent/agent/findLoggedCampaign")
      campaign = store.getters["agent/agent/getCampaign"]
    }
    if (campaign.exit_manual_mode > 0) {
      store.commit("system/setManualTimeLimited", true);
      store.commit("system/setManualTimeout", campaign.exit_manual_mode);
      startTimer(campaign.exit_manual_mode, true, 0);
    } else {
      store.commit("system/setManualTimeLimited", false);
      startTimer(0);
    }
  });

  socket.on("agent-left-manual", () => {
    store.commit("system/setMode", "dialer");
    setStatus(AgentStatus.IDLE);
    store.commit("system/stopLoadingFlag", "exitManual");
    startTimer(0);
  });

  socket.on("agent-leave-work-break-failed", () => {
    store.commit("system/setMode", "dialer");
    setStatus(AgentStatus.IDLE);
    store.commit("system/setErrorMessage", "errors_message.end_interval");
    store.commit("system/stopLoading");
  });

  socket.on("agent-failed-to-enter-manual", () => {
    store.commit("system/setErrorMessage", "errors_message.start_manual_mode");
    store.commit("system/stopLoading");
  });

  socket.on("agent-fail-leave-manual-mode", () => {
    store.commit("system/stopLoading");
    setStatus(AgentStatus.IDLE);
    store.commit("system/setErrorMessage", "errors_message.end_manual_mode");
  });

  socket.on("agent-entered-manual-acw", () => {
    store.commit("system/stopLoadingFlag", "enterManualCallACW");
    setStatus(AgentStatus.ON_MANUAL_CALL_ACW);
    startTimer(0);
  });

  socket.on("agent-left-manual-acw", (event) => {
    store.commit("system/stopLoadingFlag", "exitManualCallACW");
    store.commit("system/setMode", "dialer");
    store.commit("agent/call/stopLoadingFlag", "hangup");
    const { agent: { agent_status: { status } } } = event;
    if (status === AgentStatus.IDLE) return;

    const campaign = store.getters["agent/agent/getCampaign"];
    let acwTimeout = 0;
    if (campaign) {
      acwTimeout = campaign.acw_timeout;
    }

    store.commit("system/setAcwTimeout", acwTimeout);
    setStatus(AgentStatus.ACW);
    timer.stop();
    if (acwTimeout > 0) {
      startTimer(acwTimeout, true);
      return;
    }

    startTimer(acwTimeout);
  });

  socket.on("manual-call-acw-connected", event => {
    store.commit("agent/call/stopLoading");
    store.commit("agent/call/stopLoadingFlag", "hangup");
    store.commit("agent/call/setManualCallACW", event.call);
    startTimer(0);
    setStatus(AgentStatus.ON_CALL);
  });

  socket.on("manual-call-was-answered", (event) => {
    const { call } = event;
    if (isRingGroupCall({ campaignId: call.campaign_id })) {
      store.dispatch(
        "agent/ring_group/updateCallStatusAfterCallPABXWasAnswered",
        event,
      );

      return;
    }
    // improve setStatus and setMode
    store.commit("agent/call/setAgentCallStatus", "manual-call-was-answered");
    if (event.call.call_mode === CallMode.MANUAL) {
      store.commit("agent/call/stopLoadingFlag", "startManualCall");
      store.commit("agent/call/setCall", event.call);
      setStatus(AgentStatus.ON_CALL);
      startTimer(0);
    }
  });

  socket.on("manual-call-was-qualified", () => {
    const manualCallACW = store.getters["agent/call/getManualCallACW"];
    if (manualCallACW) {
      store.commit("agent/qualification/setManualCallACWQualified", true);
      return;
    }

    store.commit("agent/call/stopLoadingFlag", "startManualCall");
    store.commit("agent/qualification/setCallQualificated", true);
  })

  socket.on("manual-call-acw-disconnected", () => {
    store.commit("agent/call/stopLoading");
    store.commit("agent/call/stopLoadingFlag", "hangup");

    const manualCallACW = store.getters["agent/call/getManualCallACW"];
    if (manualCallACW.status == CallStatus.FAILED) return;
    store.commit("agent/call/setManualCallACW", null);
    setStatus(AgentStatus.ON_MANUAL_CALL_ACW);
  });

  socket.on("consult-connected", event => {
    if (isRingGroupCall(event)) {
      const { agent } = event;

      // If the user is the agent consulting
      if (store.getters["auth/getUser"].id !== agent.id) {
        store.commit("agent/ring_group/stopLoadingFlag", "consultAgent");
        store.commit("agent/ring_group/setCallStatus", CallStatus.CONSULT_CONNECTED);
      }
      return;
    }

    store.commit("agent/agent/stopLoadingFlag", "consultAgent");
    store.commit("agent/queue/stopLoadingFlag", "consultQueue");
    store.commit("system/setAgentInConsult", event.agent);
    store.commit("system/setConsultHold", false);

    if (store.getters["auth/getUser"].id !== event.agent.id) {
      store.commit("system/setConsulting", true);
    } else {
      store.commit("system/setConsulting", false);
      store.dispatch("system/setConsultingAgentName", event.call.agent_name);
    }

    setStatus(AgentStatus.CONSULT_CONNECTED);
    startTimer(0);
  });

  socket.on("consult-hold", () => {
    store.commit("agent/agent/stopLoading");
    store.commit("agent/agent/stopLoadingFlag", "consultAgent");
    store.commit("agent/queue/stopLoadingFlag", "consultQueue");
    store.commit("system/setConsultHold", true);
  });


  socket.on("consult-cancel-hold", () => {
    store.commit("agent/agent/stopLoading");
    store.commit("agent/agent/stopLoadingFlag", "consultAgent");
    store.commit("agent/agent/stopLoadingFlag", "exitConsult");
    store.commit("agent/agent/stopLoadingFlag", "cancelConsult");
    store.commit("agent/queue/stopLoadingFlag", "consultQueue");
    store.commit("system/setConsultHold", false);
    store.commit("agent/queue/setQueueInConsult", null);
    store.commit("system/setAgentInConsult", {});
  });

  socket.on("consult-exit", (event) => {
    if (
      isRingGroupCall({ campaignId: event.call.campaign_id }) &&
      store.getters["agent/ring_group/getConsultedAgentId"]
    ) {
      store.commit("agent/ring_group/stopLoadingFlag", "exitConsult");
      store.commit("system/setStatus", AgentStatus.ON_CALL, { root: true });
      store.commit("agent/ring_group/setCallStatus", CallStatus.CONNECTED);
      store.commit("agent/ring_group/setConsultedAgentId", null);
      return;
    }

    store.commit("agent/agent/stopLoadingFlag", "consultAgent");
    store.commit("agent/agent/stopLoadingFlag", "exitConsult");
    store.commit("agent/agent/stopLoadingFlag", "cancelConsult");
    store.commit("agent/agent/stopLoading");
    store.commit("system/setConsulting", false);
    store.commit("agent/queue/setQueueInConsult", null);
    store.commit("system/setAgentInConsult", {});
    if (store.getters["auth/getUser"].name === event.call.agent_name) {
      setStatus(AgentStatus.ON_CALL);
    } else {
      setStatus(AgentStatus.IDLE);
    }
  });

  socket.on("consult-end", () => {
    store.commit("agent/agent/stopLoadingFlag", "consultAgent");
    store.commit("agent/agent/stopLoadingFlag", "exitConsult");
    store.commit("agent/agent/stopLoadingFlag", "cancelConsult");
    store.commit("agent/agent/stopLoading");
    store.commit("system/setConsulting", false);
    store.commit("agent/queue/setQueueInConsult", null);
    store.commit("system/setAgentInConsult", {});
    setStatus(AgentStatus.IDLE);
  });

  socket.on("call-was-finished", () => {
    audioPlayer.play("busy-signal", 0.2);
  })

  socket.on("call-was-failed", (event) => {
    const { call } = event;
    const callPABX = store.getters["agent/ring_group/getCallPABX"];
    const currentCall = store.getters["agent/call/getCall"];
    const seconds = 4;
    if (!callPABX && !currentCall) return;

    if (!!callPABX && isRingGroupCall({ campaignId: call.campaign_id })) {
      store.commit("agent/ring_group/setCallPABX", call);
      startTimer(seconds, true, 0);
      setStatus(AgentStatus.ON_CALL);
      setTimeout(async () => {
        await store.dispatch("agent/ring_group/handlePABXCallEnded");
      }, seconds * 1000);
      return
    }

    store.commit("agent/call/stopLoadingFlag", "startManualCall");
    startTimer(seconds, true, 0);

    if (event.call.call_mode === CallMode.MANUAL) {
      store.commit("agent/call/setCall", event.call);
      setStatus(AgentStatus.ON_CALL);
      performPostCallActions(seconds, event, true);
    }

    if (event.call.call_mode === CallMode.MANUAL_ACW) {
      store.commit("agent/call/setManualCallACW", event.call);

      setTimeout(() => {
        store.commit("agent/call/setManualCallACW", null);
        setStatus(AgentStatus.ON_MANUAL_CALL_ACW);
        startTimer(0);
      }, seconds * 1000);
    }
  })

  socket.on("call-was-not-answered", (event) => {
    const seconds = 5;

    const call = store.getters["agent/call/getCall"];
    if (!call) return;

    store.commit("agent/call/stopLoadingFlag", "startManualCall");
    startTimer(seconds, true, 0);

    if (event.call.call_mode === CallMode.MANUAL) {
      store.commit("agent/call/setCall", { ...event.call, status: CallStatus.FAILED });
      setStatus(AgentStatus.ON_CALL);
      performPostCallActions(seconds, event, true);
    }
  });

  socket.on("internal-call-was-answered", () => {
    store.commit("agent/ring_group/setCallStatus", CallStatus.INTERNAL_CALL_ANSWERED);
  });

  socket.on("internal-call-was-refused", () => {
    store.dispatch("agent/ring_group/handlePABXCallEnded");
    store.dispatch("agent/ring_group/setShowToast", true);
  });

  // Whatsapp
  agentSocket.init();

  socket.on("new-whatsapp-ai-chat-evaluation-message", ({ chat, message }) => {
    store.dispatch("whatsapp/message/addRecentMessage", message)
    store.commit("whatsapp/message/setNewMessage", message)
    store.commit("whatsapp/chat/updateChatMood", { chatId: chat.id, mood: message.body.mood })
  });

  return socket;
}
