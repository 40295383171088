export default {
  clearErrors: state => (state.errors = {}),
  setFilters: (state, filters) => (state.filters = Object.assign({}, state.filters, filters)),

  startLoading: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoading: (state, flag) => (state.loadingFlags[flag] = false),
  incrementFirstMessagesLoadChats: (state) => state.firstMessagesLoad.loaded++,
  setFirstMessagesLoadTotal: (state, total) => state.firstMessagesLoad.total = total,
  setFirstMessagesAreLoaded: (state, flag) => state.firstMessagesAreLoaded[flag] = true,

  setErrors: (state, errors) => (state.errors = errors),
  removeChatById: (state, payload) => {
    if (state.inProgress[payload.id]) delete state.inProgress[payload.id];
    if (state.inSnooze[payload.id]) delete state.inSnooze[payload.id];
    if (state.inChatbot[payload.id]) delete state.inChatbot[payload.id];
    if (state.openChat?.id === payload.id) {
      state.openChat = null
    }
  },

  resetCurrentChat: (state, chatId) => {
    state.currentChat = chatId? { [chatId]: state.currentChat[chatId] } : {}
  },

  transferChatToQueue: (state, payload) => {
    if(state.inProgress[payload.id]) {
      let chat = state.inProgress[payload.id]
      delete state.inProgress[payload.id];
      chat.agent_id = null
      state.queue[payload.id] = chat
    }
    else if (state.inChatbot[payload.id]) {
      let chat = state.inChatbot[payload.id]
      delete state.inChatbot[payload.id];
      state.queue[payload.id] = chat
      state.currentChat[payload.id]['chatbot_active'] = false
    }
  },

  transferChatToInProgress: (state, payload) => {
    if (state.inChatbot[payload.id]) {
      state.inProgressPagination.total++
      let chat = state.inChatbot[payload.id]
      delete state.inChatbot[payload.id];
      state.inProgress[payload.id] = chat
      state.currentChat[payload.id]['chatbot_active'] = false
    }
  },

  setNewChatInfo: (state, newChatInfo) => (state.newChatInfo = newChatInfo),

  setOpenChat: (state, openChat) => {
    state.openChat = openChat
    state.chatChannelTypeSelected = {
      id: openChat.instance_id,
      type: openChat.instance.data?.type || openChat.instance?.type || "ultramsg"
    }
  },

  setGroupParticipants: (state, groupParticipants) => (state.groupParticipants = groupParticipants),

  setGroupAgents: (state, groupAgents) => (state.groupAgents = groupAgents),

  updateInProgressTalkOrder(state, chat) {
    const openChat = state.openChat
    if (openChat?.id === chat.id && !openChat?.hasBottomButton) {
      chat.unread = 0;
    }

    const prevChat = state.inProgress[chat.id]

    if (prevChat) {
      chat.mood = prevChat.mood
      delete state.inProgress[chat.id];
    }


    let chats = [];
    chats = Object.values(JSON.parse(JSON.stringify(state.inProgress)));
    chats.unshift(chat);

    state.inProgress = {};

    for (let item of chats) {
      state.inProgress[item.id] = item;

      if (!state.currentChat[item.id]) {
        item["states"] = {
          scrollY: null,
          text: "",
          tab: "whatsapp"
        };

        state.currentChat[item.id] = item
      }
      else {
        state.currentChat[item.id].agent_id = item.agent_id
        state.currentChat[item.id].last_message_data = item.last_message_data
      }
    }

    if (state.currentChat[chat?.id]) {
      state.currentChat[chat.id].unread = chat.unread;
    }
  },

  updateGroupTalkOrder(state, chat) {
    const openChat = state.openChat
    if (openChat?.id === chat.id && !openChat?.hasBottomButton) {
      chat.unread = 0;
    }
    delete state.groups[chat.id];

    if (chat.id.endsWith("@g.us")) {
      let groupChats = Object.values(JSON.parse(JSON.stringify(state.groups)));
      groupChats.unshift(chat);

      state.groups = {};

      for (let item of groupChats) {
        state.groups[item.id] = item;
        if (!state.currentChat[item.id]) {
          item["states"] = {
            scrollY: null,
            text: "",
            tab: "whatsapp",
            channel: 'ultramsg'
          }
          state.currentChat[item.id] = item;
        } else {
          state.currentChat[item.id].last_message_data = item.last_message_data;
        }
      }
    }

    if (state.currentChat[chat?.id]) {
      state.currentChat[chat.id].unread = chat.unread;
    }
  },

  updateGroupTalkOrderByEvent(state, chat) {
    delete state.groups[chat.id];
    let groupChats = Object.values(JSON.parse(JSON.stringify(state.groups)));
    groupChats.unshift(chat);
    state.groups = {};
    for (let chat of groupChats) {
      state.groups[chat.id] = chat;
      if (!state.currentChat[chat.id]) {
        chat["states"] = {
          scrollY: null,
          text: "",
          tab: "whatsapp",
          channel: 'ultramsg'
        }
        state.currentChat[chat.id] = chat;
      }
    }
  },

  updateQueueTalkOrder(state, chat) {
    delete state.queue[chat.id];
    if (chat.id.endsWith("@c.us")) {
      let queueChats = Object.values(JSON.parse(JSON.stringify(state.queue)));
      queueChats.unshift(chat);

      state.queue = {};

      for (let chat of queueChats) {
        state.queue[chat.id] = chat;
        if (!state.currentChat[chat.id]) {
          chat["states"] = {
            scrollY: null,
            text: "",
            tab: "whatsapp",
            channel: 'ultramsg'
          }
          state.currentChat[chat.id] = chat;
        }
      }
    }
  },

  updateInChatbotTalkOrder(state, chat) {
    delete state.inChatbot[chat.id];
    let chats = Object.values(JSON.parse(JSON.stringify(state.inChatbot)));
    chats.unshift(chat)

    state.inChatbot = {};

    for (let chat of chats) {
      state.inChatbot[chat.id] = chat;
    }
  },

  updateTalksInfo(state, {chat_id, unread}) {
    if (state.inProgress[chat_id]) {
      state.inProgress[chat_id].unread = unread;
    }
    if (state.currentChat[chat_id]) {
      state.currentChat[chat_id].unread = unread;
    }
  },

  updateGroupsTalksInfo(state, payload) {
    if (state.groups[payload.chat_id]) state.groups[payload.chat_id].unread = payload.unread;
  },

  setCurrentChat: (state, currentChat) => {
    currentChat["states"] = {
      scrollY: null,
      text: "",
      tab: "whatsapp",
      channel: 'ultramsg'
    }
    state.currentChat[currentChat.id] = currentChat;
    state.removedFromTheGroup = currentChat?.isReadOnly;
  },

  removeFromCurrentChat: (state, currentChat) => {
    delete state.currentChat[currentChat.id];
  },

  // ==== QUEUE ====
  /**
   * Adds the chat in the queue's array making
   * the chat id as the key to access the value.
   *
   * @param {Object} state
   * @param {Array} queue
   * @returns
   */
  addQueue: (state, queue) => {
    for (let chat of queue) state.queue[chat.id] = chat;
  },

  setQueuePagination: (state, pagination) => (state.queuePagination = pagination),
  setQueueCurrentPage: (state, page) => (state.queuePagination.current_page = page),

  /**
   * Adds a new chat at the end of the waiting queue
   * based on the user's current page.
   *
   * @param {Object} state
   * @param {Object} chat
   */
  addChatToQueue: (state, chat) => {
    if (state.queue[chat.id]) return;

    state.queuePagination.total++;

    let chats = Object.values(JSON.parse(JSON.stringify(state.queue)));
    chats.unshift(chat);
    state.queue = {};
    for (let chat of chats) {
      state.queue[chat.id] = chat;
    }
  },

  addChatToInProgress: (state, { chat, agent_id }) => {
    const agent = {
      id: agent_id,
      name: chat?.agent_name || chat?.agent?.name || chat?.internal_message?.agent_name
    }

    if (chat.id && state.queue[chat.id]) {
      delete state.queue[chat.id];
    }
    if (chat.id && state.inProgress[chat.id]) return;

    state.inProgressPagination.total++;

    let chats = Object.values(JSON.parse(JSON.stringify(state.inProgress)));
    chats.unshift(chat);
    state.inProgress = {};
    for (let item of chats) {
      if (!state.currentChat[chat.id]) {
        item['states'] = {
          channel: 'waba'
        }
      }
      state.inProgress[item.id] = item;
    }
    state.inProgress[chat.id].agent_id = agent.id;
    state.inProgress[chat.id].agent = agent;
    state.inProgress[chat.id].agent_name = agent.name;

    state.currentChat[chat.id] = {...state.currentChat[chat.id], ...state.inProgress[chat.id] };
  },

  addChatToInChatbot: (state, chat) => {
    if (state.inChatbot[chat.id]) return;

    let chats = Object.values(JSON.parse(JSON.stringify(state.inChatbot)));
    chats.unshift(chat);
    state.inChatbot = {};
    for (let item of chats) {
      state.inChatbot[item.id] = item;

      if (!state.currentChat[chat.id]) {
        chat['chatbot_active'] = true
        chat['states'] = {
          scrollY: null,
          text: "",
          tab: "whatsapp",
        }
        state.currentChat[chat.id] = chat
      }
    }
  },

  /**
   * Verifies if the chat really exists and remove it from
   * the queue when the event chat-accepted-whatsapp
   * is triggered by another agent.
   *
   * @param {Object} state
   * @param {Object} chat
   */
  removeChatFromQueue: (state, chat) => {
    if (state.queue[chat.id]) {
      delete state.queue[chat.id];
      let queue = Object.values(state.queue);
      let temp = {};
      for (let chat of queue) {
        temp[chat.id] = chat;
        state.queue = temp;
      }
    }

    if(state.queuePagination.total > 0) {
      state.queuePagination.total--;
    }
  },

  // ==== IN PROGRESS ====
  setInProgressPagination: (state, pagination) => (state.inProgressPagination = pagination),
  setInProgressCurrentPage: (state, page) => (state.inProgressPagination.current_page = page),
  /**
   * Adds the chat in the queue's array making
   * the chat id as the key to access the value.
   *
   * @param {Object} state
   * @param {Array} inProgress
   */
  addInProgress: (state, inProgress) => {
    for (let chat of inProgress) state.inProgress[chat.id] = chat;
  },

  removeInProgress: (state, payload) => {
    delete state.inProgress[payload.id];
  },

  setUnreadConversations: (state, unreadConversations) => (state.unreadConversations = unreadConversations),

  incrementUnreadConversation: state => (state.unreadConversations += 1),

  decrementUnreadConversation: state => {
    if (state.unreadConversations > 0) state.unreadConversations -= 1;
  },

  setUnreadGroupsConversations: (state, unreadGroupsConversations) =>
    (state.unreadGroupsConversations = unreadGroupsConversations),

  incrementUnreadGroupsConversation: state => (state.unreadGroupsConversations += 1),

  decrementUnreadGroupsConversation: state => {
    if (state.unreadGroupsConversations > 0) state.unreadGroupsConversations -= 1;
  },

  setHasNotifiedInProgress: (state, hasNotifiedInProgress) => (state.hasNotifiedInProgress = hasNotifiedInProgress),
  setHasNotifiedQueue: (state, hasNotifiedQueue) => (state.hasNotifiedQueue = hasNotifiedQueue),

  clearInProgress: state => (state.inProgress = {}),
  clearGroups: state => (state.groups = {}),
  clearQueue: state => (state.queue = {}),
  clearInChatbot: state => (state.inChatbot = {}),
  clearInSnooze: state => (state.inSnooze = {}),
  clearFinished: state => (state.finished = {}),

  // ==== SNOOZE ====
  addInSnooze: (state, inSnooze) => {
    for (let chat of inSnooze) state.inSnooze[chat.id] = chat;
  },
  setInSnoozePagination: (state, pagination) => (state.inSnoozePagination = pagination),
  setInSnoozeCurrentPage: (state, page) => (state.inSnoozePagination.current_page = page),
  setChatAsInSnooze: (state, payload) => {
    if (state.inProgress[payload.chat_id]) {
      let snoozeChat = state.inProgress[payload.chat_id];
      delete state.inProgress[payload.chat_id];

      let chats = Object.values(JSON.parse(JSON.stringify(state.inSnooze)));

      if (chats.length < 1) {
        return
      }

      snoozeChat.in_snooze = true;
      snoozeChat.end_snooze = payload.end_snooze;
      chats.unshift(snoozeChat);
      state.inSnooze = {};
      for (let chat of chats) state.inSnooze[chat.id] = chat;
    }
  },

  removeChatFromSnooze: (state, chat) => {
    state.snoozedByManager = false;
    if (state.inSnooze[chat.id]) {
      let savedChat = state.inSnooze[chat.id];
      delete state.inSnooze[chat.id];
      state.inProgress[chat.id] = savedChat;
      state.currentChat[chat.id].in_snooze = false
    }
  },

  setRemovedFromTheGroup: (state, chat) => {
    state.removedFromTheGroup = chat.isReadOnly;
  },

  // ==== FINISHED ====
  addFinished: (state, finished) => {
    for (let chat of finished) state.finished[chat.id] = chat;
  },
  updateFinished: (state, chat) => {
    let chats = Object.values(JSON.parse(JSON.stringify(state.finished)));
    chats.unshift(chat);
    state.finished = {};
    for (let chat of chats) state.finished[chat.id] = chat;
  },
  setFinishedPagination: (state, pagination) => (state.finishedPagination = pagination),
  setFinishedCurrentPage: (state, page) => (state.finishedPagination.current_page = page),

  addInChatbot: (state, inChatbot) => {
    for (let chat of inChatbot) state.inChatbot[chat.id] = chat;
  },
  setInChatbotPagination: (state, pagination) => (state.inChatbotPagination = pagination),
  setInChatbotCurrentPage: (state, page) => (state.inChatbotPagination.current_page = page),

  addGroups: (state, groups) => {
    for (let chat of groups) state.groups[chat.id] = chat;
  },
  addGroupsByNewGroupEvent: (state, group) => {
    state.groups[group.id] = group;
  },
  setGroupsPagination: (state, pagination) => (state.groupsPagination = pagination),
  setGroupsCurrentPage: (state, page) => (state.groupsPagination.current_page = page),
  setCheckedContacts: (state, checkedContacts) => (state.checkedContacts = checkedContacts),

  // ==== SPY ====
  setTransferedByManager: (state, boolean) => (state.transferedByManager = boolean),
  setSnoozedByManager: (state, snoozedByManager) => (state.snoozedByManager = snoozedByManager),
  setFinalizedByManager: (state, finalizedByManager) => (state.finalizedByManager = finalizedByManager),

  updateNewMessageTemplateWhatsapp (state, {chat}){
    state.currentChat[chat.id].waba_message_received = chat.waba_message_received
  },

  incrementInProgressCurrentPage: state => {
    state.inProgressPagination.current_page++;
  },
  incrementGroupsCurrentPage: state => {
    state.groupsPagination.current_page++;
  },
  incrementQueueCurrentPage: state => {
    state.queuePagination.current_page++;
  },
  incrementInChatbotCurrentPage: state => {
    state.inChatbotPagination.current_page++;
  },
  incrementInSnoozeCurrentPage: state => {
    state.inSnoozePagination.current_page++;
  },
  incrementFinishedCurrentPage: state => {
    state.finishedPagination.current_page++;
  },

  setChatLastMessage: (state, { chatType, message }) => {
    const last_message_data = {
      body: message.body,
      type: message.type,
      date: message.time,
      send_by_me: true
    };

    state[chatType][message.chat_id].last_message_data = last_message_data;
  },

  setSearchedMessages(state, { messages }) {
    state.searchedMessages = messages;
  },
  setSearchedMessagesNextPage(state, { messages }) {
    if (messages.length)
      messages.forEach(message => {
        state.searchedMessages.push(message);
      });
    else state.searchedMessages = state.searchedMessages.concat(Object.values(messages));
  },
  clearSetSearchedMessages: state => (state.searchedMessages = []),
  setLastTermSearchedMessage: (state, payload) => (state.lastTermSearchedMessage = payload),
  setSearchedMessagesPagination(state, payload) {
    state.searchedMessagesPagination = payload;
  },
  setMessageFound: (state, messageFound) => (state.messageFound = messageFound),

  setEmptyChatContact: (state, emptyChatContact) => {
    state.emptyChatContact = emptyChatContact
    state.currentChat['empty'] = emptyChatContact
  },

  setLastVisitedWhatsAppRoute: (state, route) => (state.lastVisitedWhatsAppRoute = route),
  setTalksTab: (state, tab) => (state.talksTab = tab),

  setClientResponseLate(state, payload) {
    state.currentChat[payload.chat.id].lag_to_response = payload.chat.lag_to_response;
  },
  setClientResponseLateQueue(state, payload) {
    state.currentChat[payload.chat.id].queue_response_is_late = payload.chat.queue_response_is_late;
  },
  setRemoveUnanswerMark(state, chatId) {
    state.currentChat[chatId].lag_to_response = {
      response_is_late: false,
      late_since: null,
      max_time_to_be_answer: null
    };
    state.currentChat[chatId].queue_response_is_late = {
      response_is_late: false,
      late_since: null,
      max_time_to_be_answer: null
    };
  },

  setAllProtocols: (state, payload) => (state.allProtocols = payload),
  setProtocol: (state, payload) => (state.protocol = payload),
  setProtocolFound: (state, protocolFound) => (state.protocolFound = protocolFound),

  updateChatName(state, { chatId, contact }) {
    state.currentChat[chatId].contact = contact;

    const types = ["inProgress", "queue", "inChatbot", "inSnooze", "finished"];
    types.forEach(type => {
      if (state[type][chatId]) {
        state[type][chatId].contact = contact;
      }
    });
  },

  updateChatMood(state, { chatId, mood }) {
    state.currentChat[chatId].mood = mood;

    const types = ["inProgress", "queue", "inChatbot", "inSnooze", "finished"];
    types.forEach(type => {
      if (state[type][chatId]) {
        state[type][chatId].mood = mood;
      }
    });
  },

  updateChatNoteUnread(state, {chatId, noteUnread}) {
    state.currentChat[chatId].note_unread = noteUnread;
  },

  toggleOpenTransferForm: (state) => state.openTransferForm = !state.openTransferForm,

  updateWabaMessageReceived (state, { chat_id, value }) {
    state.currentChat[chat_id].waba_message_received = value
  },

  setChatState(state, {chatId, chatState, value}){
    state.currentChat[chatId].states[chatState] = value
  },

  updateCurrentChatOwner(state, payload) {
    if(state?.currentChat[payload.currentChatId]) {
      if(state?.currentChat[payload.currentChatId]?.agent_id) {
        state.currentChat[payload.currentChatId].agent_id = payload.userId;
      }
      if(state?.currentChat[payload.currentChatId]?.agent?.id) {
        state.currentChat[payload.currentChatId].agent.id = payload.userId;
      }

      if(state?.currentChat[payload.currentChatId]?.agent_name) {
        state.currentChat[payload.currentChatId].agent_name = payload.userName;
      }
      if(state?.currentChat[payload.currentChatId]?.agent?.name) {
        state.currentChat[payload.currentChatId].agent.name = payload.userName;
      }
    }
  },
  setAllowedChatsToForward(state, allowedChatsToForward) {
    state.allowedChatsToForward = allowedChatsToForward
  },
}

