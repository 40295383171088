<template>
  <div class="dropdown">
    <button
      ref="dropdown"
      :disabled="loading"
      aria-expanded="false"
      class="btn-dropdown-top-bar shadow-none background-loading d-flex justify-content-center align-items-center"
      data-test="transferChat-button"
      @click="handleDropdownClick"
      @mouseleave="buttonHovered = false"
      @mouseover="buttonHovered = true"
    >
      <span
        v-if="opened"
        class="fe fe-x"
      />
      <div
        v-else
      >
        <img
          v-if="buttonHovered"
          src="@assets/img/icons/arrows-white.svg"
        >
        <img
          v-else
          src="@assets/img/icons/arrows.svg"
        >
      </div>
    </button>
    <div class="dropdown-menu border-0">
      <p class="fs-4 tx-text-300 fw-medium mb-4">
        {{ $t('transfer_to') }}
      </p>

      <ul
        style="margin-bottom: 1rem"
        class="nav nav-tabs"
      >
        <li class="nav-item active">
          <a
            class="nav-link p-0 h-100 active text-capitalize"
            data-bs-toggle="tab"
            href="#users"
          >{{ $t('users') }}</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link p-0 h-100"
            data-bs-toggle="tab"
            href="#instances"
          >{{ $t('service_queue') }}</a>
        </li>
      </ul>
      <div>
        <div class="tab-content">
          <div
            id="users"
            class="tab-pane fade active show"
            @scroll="checkScroll"
          >
            <div
              v-if="!agents.length"
              class="p-0"
            >
              {{ $t('no_agent_found') }}
            </div>
            <users-tab
              :key="currentChat[$route.params.id]?.agent?.id || currentChat[$route.params.id]?.agent_id"
              id="transfer-form"
              :current-user-id="user.id"
              :users="agents"
              :current-owner-id="currentChat[$route.params.id]?.agent?.id || currentChat[$route.params.id]?.agent_id"
              @select-item="transferToAgent"
              @search="search"
              @role="updateRoleFilter"
              :roles="[
                'agent',
                'supervisor'
              ]"
            />
            <div
              v-if="loadingFlags.findAllowedAgentsAndInstances"
              class="d-flex justify-content-center align-items-center"
              style="height: 30px"
            >
              <div class="spinner-border spinner-border-sm" />
            </div>
          </div>
          <div
            id="instances"
            class="tab-pane fade"
          >
            <div
              v-if="!groupChannels?.length"
            >
              {{ $t('no_connection_found') }}
            </div>
            <div
              v-else
              class="accordion"
              id="instancesAccordion"
            >
              <instance-item
                accordion-id="instancesAccordion"
                v-for="groupChannel in groupChannels"
                :key="groupChannel.id"
                :group-channel="groupChannel"
                @selected-instance="(instance_id)=>transferToInstance(instance_id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import _ from "lodash";
import {Dropdown} from "bootstrap";

import InstanceItem from "@whatsapp/components/transfer-form/instance-item.vue";
import UsersTab from "@whatsapp/components/transfer-form/users-tab.vue";


export default {
  components: {
    UsersTab,
    InstanceItem,
  },

  data() {
    return {
      buttonHovered: false,
      agents: [],
      groupChannels: [],
      dropdown: null,
      opened: false,
      hovered: {
        agent: null,
        instance: null
      },
      agentsPerPage: 15,
      lastPage: null,
      params: {
        page: 1,
        search: "",
        team: null,
        role: 'agent'
      },
    };
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.setAllowedParams({
      team: this.currentChat[this.$route.params.id].team_id,
      all_company: 1
    })
    this.loadAgentsAndInstances();
    this.dropdown = new Dropdown(this.$refs.dropdown);
  },

  computed: {
    ...mapGetters("whatsapp/instance", {
      allowedAgents: "getAllowedAgents",
      allowedInstances: "getAllowedInstances",
      loadingFlags: "getLoadingFlags",
      allowedParams: "getAllowedParams"
    }),
    ...mapGetters("whatsapp/chat", {
      openTransferForm: "getOpenTransferForm",
      currentChat: "getCurrentChat"
    }),

    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent"
    }),
  },

  methods: {
    ...mapMutations("system", ["setSuccessMessage"]),
    ...mapMutations("auth", ["trackEvents"]),
    ...mapMutations("whatsapp/chat", ["toggleOpenTransferForm", "transferChatToQueue", "transferChatToInProgress", "removeChatFromQueue"]),
    ...mapMutations("whatsapp/message", ["clearChatMessages"]),
    ...mapActions("whatsapp/instance", ["findAllowedAgentsAndInstances"]),
    ...mapActions("whatsapp/chat", ["transferChat"]),
    ...mapMutations("whatsapp/instance", [
      "setAllowedParams"
    ]),

    handleDropdownClick() {
      this.toggleOpenTransferForm()
    },

    search: _.debounce(function (event) {
      const string = event.toLowerCase();
      this.setAllowedParams({
        page: 1,
        search: string,
        all_company: 1
      })

      this.lastPage = null

      this.loadAgents()

    }, 500),

    transferToAgent(agent_id) {
      this.transferChat({
        id: this.$route.params.id,
        fields: {
          user_id: agent_id
        }
      }).then(() => {
        this.trackEvents('[Agent] Tranfer to another Agent')
        this.setSuccessMessage(this.$t('success_transfer_chat'));
        this.loadAgents();
        this.handleDropdownClick();
        if (this.isAgent) {
          this.$router.push("/whatsapp")
          this.clearChatMessages(this.$route.params.id)
        } else {
          this.transferChatToInProgress({id: this.$route.params.id})
          this.dropdown.hide()
        }
      });
    },

    transferToInstance(instance_id) {
      this.transferChat({
        id: this.$route.params.id,
        fields: {
          instance_id: instance_id,
        }
      }).then(() => {
        this.trackEvents('[Agent] Tranfer to another Instance')
        this.setSuccessMessage(this.$t('success_transfer_chat'));
        this.loadAgents();
        this.handleDropdownClick();
        if (this.isAgent) {
          this.$router.push("/whatsapp")
          this.clearChatMessages(this.$route.params.id)
        } else {
          this.transferChatToQueue({id: this.$route.params.id})
          this.dropdown.hide()
        }
      });
    },

    checkScroll(event) {
      if (this.loadingFlags.findAllowedAgentsAndInstances) return
      if (this.allowedParams.page === this.lastPage) return

      const scrollContainer = event.target;
      const scrollHeight = scrollContainer.scrollHeight;
      const scrollTop = scrollContainer.scrollTop;

      if (scrollHeight - scrollTop - scrollContainer.clientHeight < 1) {
        this.setAllowedParams({
          page: this.allowedParams.page + 1,
          all_company: 1
        })
        this.loadAgents()
      }
    },

    loadAgents() {
      this.findAllowedAgentsAndInstances(this.allowedParams).then((agentsLength) => {
        this.agents = this.allowedAgents;

        if (agentsLength.data.length < this.agentsPerPage) {
          this.lastPage = this.allowedParams.page
        }
      });
    },

    loadAgentsAndInstances() {
      this.findAllowedAgentsAndInstances(this.allowedParams).then((agentsResponse) => {
        this.lastPage = agentsResponse.meta.pagination.total_pages;
        this.agents = this.allowedAgents;
        this.groupChannels = this.allowedInstances.map((allowedInstance) => {
          return {
            ...allowedInstance,
            channels: allowedInstance.instances
          };
        });
      });
    },

    updateRoleFilter(role) {
      let params = {
        team: this.currentChat[this.$route.params.id].team_id,
        page: 1,
        role,
        all_company: 1
      }

      if (role === 'manager') {
        params.team = null
      }

      this.setAllowedParams(params);
      this.loadAgentsAndInstances();
    }
  },

  watch: {
    openTransferForm(open) {
      if (open) {
        this.dropdown.show()
        this.opened = true
      } else {
        this.dropdown.hide()
        this.opened = false
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.btn-dropdown-top-bar {
  margin-left: $whatsapp-spacing-2;
  padding: $whatsapp-button-padding !important;
  color: $color-text-gray !important;
  height: $whatsapp-button-size !important;
  width: $whatsapp-button-size !important;
}

.dropdown-menu {
  width: 340px;
  max-height: 600px;
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 1.125rem 0 rgba(34, 54, 77, 0.12);
  padding: 1.5rem;

  .nav {
    height: 30px !important;
  }

  .option {
    padding: 0.5rem;
    line-height: 1.3;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $color-gray-blue-300;
    }
  }

  .tab-pane {
    max-height: 400px !important;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-gray-200 !important;
      border-radius: 50px !important;
    }
  }

  .disabled {
    opacity: 0.6 !important;
  }
}

button, .button-whatsapp {
  min-width: 1.244rem;
  min-height: 1.244rem;
  background-color: #e1e9f4;
  border: none;
  color: #95aac9;
  border-radius: $whatsapp-border-radius;

  &:hover:not(.no-hover), .whatsapp-calling {
    background-color: $color-blue-300 !important;
    color: #e3ebf6 !important;
  }
}
</style>
