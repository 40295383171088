<template>
  <div class="card-body pt-0 activity-body">
    <div class="row">
      <select-opportunity
        v-if="!$route.params.opportunityId && cardOpportunityId === null"
        v-model="activityPayload.opportunity"
        :show-error="showOpportunityError"
      />
      <div
        :class="{
          'col-6': type !== 'meeting',
          'col-12': type === 'meeting'
        }"
        class="mb-3"
      >
        <label
          class="form-label required"
          for="title"
        >{{ $t('title') }}</label>
        <input
          id="title"
          v-model="activityPayload.title"
          :class="{
            'is-invalid': errors.title
          }"
          :placeholder="activityTitles[type]"
          class="form-control"
          type="text"
        >
        <div class="invalid-feedback">
          {{ errors.title && errors.title[0] }}
        </div>
      </div>
      <div
        :class="{
          'col-4': type !== 'meeting',
          'col-7': type === 'meeting'
        }"
        class="mb-3"
      >
        <label class="form-label required">{{ $t('date_capitalize') }}</label>
        <flat-pickr
          v-model="activityPayload.due_date"
          :config="dueDateConfig"
          class="form-control input-date"
          data-input
          data-test="scheduleTime-input"
          placeholder="DD/MM/AAAA"
        />
      </div>
      <div
        v-if="type !== 'meeting'"
        class="col-2 mb-3"
      >
        <label
          class="form-label"
          for="start_time"
        >{{ $t('hour') }}</label>
        <div class="input-group input-group-merge">
          <flat-pickr
            v-model="activityPayload.start_time"
            :config="timeConfig"
            class="form-control input-date"
            data-input
            data-test="scheduleTime-input"
            placeholder="00:00"
          />
          <div class="input-group-text cursor-pointer">
            <span class="fa-regular fa-clock" />
          </div>
        </div>
      </div>
      <div
        v-if="type === 'meeting'"
        class="col-5 d-flex"
      >
        <div>
          <label
            class="form-label"
            for="start_time"
          >{{ $t('start') }}</label>
          <div class="input-group input-group-merge">
            <flat-pickr
              v-model="activityPayload.start_time"
              :config="timeConfig"
              class="form-control input-date"
              data-input
              data-test="scheduleTime-input"
              placeholder="00:00"
            />
            <div class="input-group-text cursor-pointer">
              <span class="fa-regular fa-clock" />
            </div>
          </div>
        </div>
        <span
          class="fa-regular fa-arrow-right tx-text-gray"
          style="padding: 0 1rem; margin-top: 43px"
        />
        <div>
          <label class="form-label">{{ $t('end') }}</label>
          <div class="input-group input-group-merge">
            <flat-pickr
              v-model="activityPayload.end_time"
              :config="timeConfig"
              class="form-control input-date"
              data-input
              data-test="scheduleTime-input"
              placeholder="00:00"
            />
            <div class="input-group-text cursor-pointer">
              <span class="fa-regular fa-clock" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="type === 'meeting'"
        class="col-4"
      >
        <label class="form-label">
          {{ $t('type_general') }}
        </label>
        <div class="d-flex gap-4">
          <div
            v-for="typeMeeting in ['in-person', 'online']"
            :key="typeMeeting"
            class="d-flex"
          >
            <input
              :id="typeMeeting"
              v-model="activityPayload.type_meeting"
              :value="typeMeeting"
              class="form-check-input me-3"
              type="radio"
            >
            <label :for="typeMeeting">{{ typeMeeting === "online" ? "Online" : $t('in_person') }}</label>
          </div>
        </div>
      </div>
      <div
        v-if="type === 'meeting'"
        class="col-8"
      >
        <label
          class="form-label"
          for="local_meeting"
        >
          {{ activityPayload.type_meeting === 'in-person' ? $t('local') : 'Link' }}
        </label>
        <div class="position-relative">
          <input
            id="local_meeting"
            v-model="activityPayload.local_meeting"
            class="form-control"
            :class="{
              'is-invalid': errors.local_meeting
            }"
            :placeholder="activityPayload.type_meeting === 'in-person' ? $t('select') : 'https://link.com/'"
            type="text"
          >
          <div class="position-icon me-3">
            <i
              :class="{
                'fa-link': activityPayload.type_meeting === 'online',
                'fa-location-dot': activityPayload.type_meeting === 'in-person',
              }"
              class="fa-regular"
            />
          </div>
        </div>
        <div class="invalid-feedback">
          {{ errors.local_meeting && errors.local_meeting[0] }}
        </div>
      </div>
      <div
        v-if="type === 'email'"
        class="col-12 mb-3"
      >
        <label
          class="form-label required"
          for="email"
        >E-mail </label>
        <select
          id="email"
          v-model="activityPayload.email"
          :class="{
            'is-invalid': errors.email
          }"
          class="form-select"
        >
          <option
            v-if="
              !opportunityData || opportunityData.contacts.reduce((acc, contact) => acc + contact.emails.length, 0) <= 0
            "
            value=""
          >
            {{ $t('no_registered_mail') }}
          </option>
          <template v-else>
            <optgroup
              v-for="contact in opportunityData.contacts"
              :key="contact.id"
              :label="contact.name"
            >
              <option
                v-for="email in contact.emails"
                :key="email.email"
                :value="email.email"
              >
                {{ email.email }}
              </option>
            </optgroup>
          </template>
        </select>
        <div class="invalid-feedback">
          {{ errors.email && errors.email[0] }}
        </div>
      </div>
      <div
        v-if="['message', 'call'].includes(type)"
        :class="type === 'message' ? 'col-6' : 'col-12'"
        class="mb-3"
      >
        <label
          class="form-label required"
          for="phone"
        >{{ $t('number') }} </label>
        <select
          id="phone"
          v-model="activityPayload.phone"
          :class="{
            'is-invalid': errors.phone
          }"
          class="form-select"
        >
          <option
            v-if="
              !opportunityData || opportunityData.contacts.reduce((acc, contact) => acc + contact.phones.length, 0) <= 0
            "
            value=""
          >
            {{ $t('no_registered_phone') }}
          </option>
          <template v-else>
            <optgroup
              v-for="contact in opportunityData.contacts"
              :key="contact.id"
              :label="contact.name"
            >
              <option
                v-for="phone in contact.phones"
                :key="phone.number"
                :value="phone.number"
              >
                {{ formatPhoneNumber(phone.number) }}
              </option>
            </optgroup>
          </template>
        </select>
        <div class="invalid-feedback">
          {{ errors.phone && errors.phone[0] }}
        </div>
      </div>
      <div
        v-if="type === 'message'"
        class="col-6 mb-3"
      >
        <label class="form-label required">{{ $t('send_from') }} </label>
        <select-channel @select-channel="channel => (activityPayload.instance = channel)" />
      </div>
      <div class="mb-3">
        <label
          class="form-label"
          for="description"
        >{{ $t('description') }}</label>
        <textarea
          id="description"
          ref="description"
          v-model="activityPayload.description"
          :class="{
            'is-invalid': errors.description
          }"
          class="form-control overflow-hidden description-textarea"
          rows="1"
          style="resize: none;"
          @input="autoGrowTextArea"
        />
        <div class="invalid-feedback">
          {{ errors.description && errors.description[0] }}
        </div>
      </div>
      <footer-attachments :open="this.openAttach" />
    </div>
  </div>
  <activity-card-footer
    :due-date="activityPayload.due_date"
    :allow-submit="isSubmitAllowed"
    @submit-activity="submitActivity"
    @open="open"
  />
</template>

<script>
import {autoGrowTextArea, formatPhoneNumber} from "@/utils";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {computed} from "vue";
import {Portuguese} from "flatpickr/dist/l10n/pt.js";
import SelectChannel from "@components/select-channel.vue";
import FlatPickr from "vue-flatpickr-component";
import ActivityCardFooter from "./activity-card-footer.vue";
import SelectOpportunity from "@crm/components/activity/form-activity/select-opportunity.vue";

import {getActivityFormData} from "@crm/utills";
import moment from "moment";
import FooterAttachments from "@crm/components/activity/form-activity/footer-attachments.vue";


export default {
  name: "CreateActivity",

  components: {
    FooterAttachments,
    SelectOpportunity,
    ActivityCardFooter,
    SelectChannel,
    FlatPickr,
  },

  props: {
    type: {
      type: String,
      required: true,
    },

    showModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activityPayload: {
        opportunity: null,
        opportunity_id: null,
        email: "",
        title: "",
        phone: "",
        due_date: moment().format("YYYY-MM-DD"),
        instance: null,
        is_completed: false,
        attachments: [],
        type_meeting: 'in-person',
        local_meeting: null,
        start_time: moment().format("HH:mm"),
        end_time: null,
        description: null
      },
      showOpportunityError: false,
      activityTitles: {
        task: this.$t('task'),
        call: this.$t('call'),
        message: this.$t('message'),
        email: "E-mail",
        meeting: this.$t('meeting')
      },
      dueDateConfig: {
        locale: Portuguese,
        inline: false,
        dateFormat: "d/m/Y",
        wrap: true,
        allowInput: true,
      },
      timeConfig: {
        locale: Portuguese,
        allowInput: true,
        minuteIncrement: 1,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },
      isActivityFormSubmitted: false,
      openAttach: null
    }
  },

  provide() {
    return {
      isActivityFormSubmitted: computed(() => this.isActivityFormSubmitted)
    }
  },

  mounted() {

    if(this.activityCard && this.activityCard?.data?.type_meeting && this.activityCard.opportunity_id === this.opportunity?.id) {
      this.activityPayload = this.activityCard.data;
      this.setActivityCard({})
    }
  },

  computed: {
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
      currentTab: "getCurrentTabTimeline",
      cardOpportunityId: "getCardOpportunityId"
    }),

    ...mapGetters("crm/activities", {
      loadingFlags: "getLoadingFlags",
      errorsData: "getErrors",
      activityCard: "getActivityCard"
    }),

    ...mapGetters("crm/tour", {
      tourSteps: "getTourSteps"
    }),

    ...mapGetters("auth", {
      isManager: "isManager"
    }),

    opportunityData() {
      return this.opportunity ? this.opportunity : this.activityPayload.opportunity;
    },

    errors() {
      return this.errorsData.createActivity
    },
    isSubmitAllowed(){
      return this.allowSubmit()
    }
  },

  inject: ["closeModal"],

  methods: {
    ...mapActions("crm/activities", ["createActivity", "fetchActivities"]),
    ...mapMutations("crm/activities", [
      "clearErrors",
      "setActivityCard"
    ]),
    ...mapActions("crm/tour", ["updateTourSteps"]),
    formatPhoneNumber,
    autoGrowTextArea,

    setOpportunityIdInPayload() {
      if (this.$route.params.opportunityId) {
        this.activityPayload.opportunity_id = Number(this.$route.params.opportunityId);
        return;
      }

      if (this.cardOpportunityId) {
        this.activityPayload.opportunity_id = Number(this.cardOpportunityId);
        return;
      }

      this.activityPayload.opportunity_id = Number(this.activityPayload.opportunity?.id);
    },
    open(type){
      this.openAttach = type
    },

    submitActivity(value) {
      this.showOpportunityError = false;
      this.activityPayload.attachments = value.attachments;
      this.activityPayload.is_completed = Number(value.is_completed);
      this.setOpportunityIdInPayload();

      this.activityPayload.funnel_id = this.$route.params.id
      const formData = getActivityFormData(this.activityPayload, this.type)
      this.createActivity({
        activity: formData,
        opportunityId: this.activityPayload.opportunity_id,
        contactsIds: this.opportunityData.contacts.map(contact => contact.id),
        currentTab: this.currentTab
      })
        .then(() => {
          if( this.isManager && !this.tourSteps?.add_first_activity) {
            this.updateTourSteps({add_first_activity: true})
          }
          this.clearFormFields()
          this.fetchActivities('pendingOfDay');
          this.fetchActivities('future');
          this.isActivityFormSubmitted = false;
          this.closeModal();
        })
    },

    clearFormFields() {
      this.clearErrors()
      this.activityPayload = {
        opportunity: null,
        opportunity_id: null,
        email: "",
        title: "",
        phone: "",
        due_date: moment().format("YYYY-MM-DD"),
        instance: null,
        is_completed: false,
        attachments: [],
        type_meeting: 'in-person',
        local_meeting: null,
        start_time: moment().format("HH:mm"),
        end_time: null,
        description: null
      },

        this.isActivityFormSubmitted = true;
      this.$nextTick(() => {
        this.autoGrowTextArea({target: this.$refs.description});
      })
    },

    allowSubmit(){
      return this.activityPayload.title !== "" &&
          this.activityPayload.due_date &&
          (!['message', 'call'].includes(this.type) || this.activityPayload.phone !== "") &&
          (this.type !== 'email' || this.activityPayload.email !== "")
    },
  },

  watch: {
    type: {
      handler() {
        this.clearFormFields()
      }
    },

    showModal: {
      handler(newVal) {
        if (!newVal) {
          this.clearFormFields();
        }
      },
      immediate: true
    },


    errors: {
      handler(value) {
        if (value.opportunity_id) {
          this.showOpportunityError = true;
        }
      },
      deep: true
    },

    activityPayload: {
      handler(newVal) {
        this.allowSubmit();
        if(this.opportunity?.id) {
          this.setActivityCard({
            opportunity_id: this.opportunity.id,
            data: newVal
          })
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.position-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)
}
.description-textarea{
  max-height: 88px;
}
.activity-body{
  overflow: auto;
}
</style>