<template>
  <div
    ref="canvas"
    data-bs="false"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    class="offcanvas offcanvas-end shadow"
  >
    <div class="offcanvas-header border-0">
      <span class="fs-2 fw-bold">{{ $t('search_messages.title') }}</span>
      <button
        aria-label="Close"
        class="btn-close bg-transparent text-reset fs-2"
        data-bs-dismiss="offcanvas"
        type="button"
      />
    </div>
    <hr class="my-1">
    <div class="offcanvas-body">
      <div class="list-group-item border-0 py-0">
        <div class="row align-items-center py-0 cursor-pointer">
          <div class="col-auto">
            <span class="avatar">
              <img
                v-if="currentContactData && currentContactData.image"
                :src="currentContactData.image"
                class="avatar-img rounded-circle"
              >
              <img
                v-else
                class="avatar-img rounded-circle"
                src="@assets/img/avatars/profiles/nome-user.png"
              >
            </span>
          </div>
          <div
            v-if="currentContactData"
            class="col"
          >
            <span class="fs-5 fw-bold">
              {{ currentContactData.name_alias || currentContactData.name }}
            </span>
            <p
              v-if="currentChat && currentChat.instance"
              class="mb-1 text-small"
            >
              <i class="fa-solid fa-link text-primary mr-2" />
              <span class="mx-1 text-muted">{{ $t('search_messages.connection') }}:</span>
              <span v-if="currentChat.instance.data">{{ currentChat.instance.data.name }}</span>
              <span v-else-if="currentChat.instance.name">{{ currentChat.instance.name }}</span>
            </p>
          </div>
        </div>
      </div>
      <hr>
      <div class="input-group border rounded mb-3">
        <span
          :class="{ 'border-primary border-end-0': inFocus }"
          class="input-group-text"
        >
          <i class="fa-solid fa-magnifying-glass" />
        </span>
        <input
          v-model="search"
          aria-describedby="search"
          aria-label="search"
          class="form-control border-start-0"
          data-test="searchMessages-input"
          :placeholder="$t('search_messages.search')"
          type="text"
          @focus="inFocus = true"
          @focusout="inFocus = false"
          @keyup.enter="submit"
        >
      </div>
      <button
        v-if="(search && search.length > 0)"
        :disabled="loadingMessages.searchMessages"
        class="btn btn-primary col-lg-12 my-3"
        @click="submit()"
      >
        {{ $t('search_messages.search') }}
      </button>
      <div
        v-if="loadingMessages.searchMessages && messages.length === 0"
        class="w-100 d-flex mt-5"
      >
        <div
          class="spinner-border text-primary mx-auto"
          role="status"
        >
          <span class="visually-hidden">{{ $t('loading_capitalize') }}...</span>
        </div>
      </div>
      <div id="panelMessages">
        <div
          v-if="search && messages && messages.length"
          class="my-3"
        >
          <h4>{{ $t('search_messages.results') }}</h4>
          <div class="overflow-scroll pb-5">
            <message-item
              v-for="(item, index) in messages"
              :key="index"
              :message="item"
              :search="search"
              class="my-5 px-0"
              @close-canvas="close"
            />
            <infinity-pagination-observer
              :loading="loadingMessages.searchMessages"
              margin="10px"
              parent-container="panelMessages"
              @reload="nextPage"
            />
          </div>
        </div>
        <div
          v-else-if="!loadingMessages.searchMessages && lastTermUsed && messages.length === 0"
          class="text-center py-5"
        >
          <span>{{ $t('search_messages.results_not_found') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Offcanvas} from "bootstrap";
import {mapActions, mapGetters} from "vuex";
import messageItem from "./message-item.vue";
import InfinityPaginationObserver from "@components/infinity-pagination-observer.vue";

export default {
  components: { messageItem, InfinityPaginationObserver},
  props: {
    modelValue: { type: Boolean, required: true },
    currentChat: { type: Object, required: true },
    contactId: { type: Number, required: true,
    },
  },
  emits: ["update:modelValue", 'close'],
  data () {
    return {
      offCanvas: null,
      inFocus: false,
      search: "",
      currentPage: 1,
      lastTermUsed: null
    };
  },
  computed: {
    ...mapGetters("whatsapp/contact", {
      contact: "getContact",
    }),
    ...mapGetters("whatsapp/chat", {
      messages: "getSearchedMessages",
      pagination: "getSearchedMessagesPagination",
      loadingMessages: "getLoadingFlags",
    }),

    currentContactData() {
      if (this.currentChat.is_group) {
        return this.currentChat.contact
      }
      return this.contact
    }
  },
  watch: {
    modelValue (value) {
      value ? this.open() : this.close();
    },
  },
  mounted () {
    this.offCanvas = new Offcanvas(this.$refs.canvas);
    this.$refs.canvas.addEventListener('hidden.bs.offcanvas', () => this.close())
  },

  methods: {
    ...mapActions("whatsapp/chat", ["searchMessages"]),

    open () {
      if (this.offCanvas)
        this.offCanvas.show();
    },
    async submit () {
      this.lastTermUsed = null
      if (this.search.length > 0) {
        this.lastTermUsed = this.search
        this.searchMessages({
          search: this.lastTermUsed,
          chat_id: this.currentChat.id,
          per_page: 150,
          per_page_search: 40,
          page: this.currentPage,
        })
      }
    },
    async nextPage () {
      if (this.loadingMessages.searchMessages) return
      if (this.currentPage < this.pagination.total_pages) {
        this.currentPage++;
        await this.submit();
      }
    },
    close () {
      if (this.offCanvas)
        this.offCanvas.hide();
      this.$emit('close')
    },
  },
  beforeUnmount () {
    this.currentPage = 1;
    this.search = ''
    this.close();
  },
};
</script>
<style>
</style>
