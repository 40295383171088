<template>
  <div class="dropdown">
    <ul
      class="list-unstyled p-0 m-0 row"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <tag-contact
        class="mb-2 me-2"
        v-for="tag in contactTags"
        :key="tag.id"
        :tag="tag"
      />
      <li
        @click="open"
        class="card-tag tag-blue col-auto mb-2 me-2 cursor-pointer"
      >
        {{ contactTags.length? '+' : $t('add_contact_tag') + ' +' }}
      </li>
    </ul>
    <div class="dropdown-menu dropdown-tags">
      <div class="card m-0">
        <div class="card-header py-3 h-auto d-flex flex-column">
          <ul class="list-unstyled w-100 p-0 m-0 row list-tags">
            <tag-contact
              class="mb-2 me-2"
              v-for="contactTag in contactTags"
              :key="contactTag.id"
              :tag="contactTag"
              :loading="detachingIds.includes(contactTag.tag_id)"
              close-enabled
              @close="detach(contactTag)"
            />
          </ul>
          <input
            class="input-tags w-100"
            :placeholder="contactTags.length? $t('placeholders.search_capitalize') : $t('type_to_create')"
            type="text"
            maxlength="20"
            v-model="fields.name"
          >
        </div>
        <div
          v-if="tags.length"
          class="card-body py-3"
        >
          <h6>{{ $t('select_tag_or_create') }}</h6>
          <ul class="list-unstyled p-0 m-0 d-flex flex-column gap-2 list-tags">
            <tag-contact
              class="mb-2 me-2"
              v-for="tag in filteredTags"
              :key="tag.id"
              :tag="tag"
              :loading="attachingIds.includes(tag.id)"
              click-enabled
              @click="attach"
            />
          </ul>
        </div>
        <div
          v-else
          class="card-body d-flex flex-column align-items-center p-0"
        >
          <img
            class="img-empty-tags"
            src="@/assets/img/aloisio/Pluzinha_busca.svg"
          >
          <h5 class="lh-base">
            {{ $t('empty_filter_3') }}
          </h5>
          <p class="text-medium tx-text-gray mb-4">
            <strong>{{ $t('type_it') }} </strong>{{ $t('create_first_tag') }}
          </p>
        </div>
        <div
          v-if="fields.name"
          class="card-footer card-footer-tags py-3 d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center">
            <button
              class="btn p-0 me-2"
              :disabled="loadingFlags.createTag"
              @click="submit"
            >
              <span
                v-if="loadingFlags.createTag"
                class="spinner-border spinner-border-sm"
              />
              <span v-else>
                {{ $t('create') }}
              </span>
            </button>
            <tag-contact :tag="{ id: 'new', ...fields }" />
          </div>
          <color-picker-tags v-model="fields.color" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {tagBackgrounds} from "@/utils";
import ColorPickerTags from "@whatsapp/components/tags/color-picker-tags.vue";
import {mapActions, mapGetters} from "vuex";
import TagContact from "@whatsapp/components/tags/tag-contact.vue";

export default {
  name: "EditContactTags",
  components: {TagContact, ColorPickerTags},

  props: {
    contact: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      tagBackgrounds: tagBackgrounds,
      fields: {
        name: "",
        color: null
      },
      attachingIds: [],
      detachingIds: [],
    }
  },

  created() {
    this.fetchTags()
    const randomIndex = Math.floor(Math.random() * this.tagBackgrounds.length);
    this.fields.color = this.tagBackgrounds[randomIndex];
  },

  computed: {
    ...mapGetters('crm/tags', {
      tags: "getTags",
      loadingFlags: 'getLoadingFlags'
    }),

    contactTags() {
      return this.contact.contact_tags
    },

    availableTags() {
      return this.tags.filter(tag =>
        !this.contactTags.some(contactTag => contactTag.tag_id === tag.id)
      );
    },

    filteredTags() {
      return this.availableTags.filter(tag =>{
        return tag.name.toLowerCase().includes(this.fields.name.toLowerCase());
      })
    }
  },

  methods: {
    ...mapActions('crm/tags', [
      'fetchTags',
      'createTag',
      'createTagAttach',
      'createTagDetach'
    ]),

    submit() {
      this.createTag(this.fields).then((newTag)=>{
        this.attach(newTag)
      })
    },

    attach(tag) {
      const data = {
        payload: {
          tag_id: tag.id,
          type: 'contact',
          contact_id: this.contact.id
        },
        tag
      }

      this.attachingIds.push(tag.id)
      this.createTagAttach(data).finally(()=>{
        this.attachingIds = this.attachingIds.filter(id => id !== tag.id)
      })
    },

    detach(contactTag) {
      const data = {
        payload: {
          tag_id: contactTag.tag_id,
          type: 'contact',
          contact_id: this.contact.id
        },
        contactTag
      }

      this.detachingIds.push(contactTag.tag_id)
      this.createTagDetach(data).finally(()=>{
        this.detachingIds = this.detachingIds.filter(id => id !== contactTag.tag_id)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-tags {
  transform: translate(0px, 0px) !important;
  padding: 0 !important;
  width: 100%;
  max-width: 350px;
}

.btn-tag, .card-tag {
  width: fit-content;
  height: 1.5rem;
  border-radius: 0.375rem;
  padding: 0 0.5rem;
  font-size: 0.875rem;
}

.tag-blue {
  background-color: #F0F4FA;
  color: #3057F2;
}

.input-tags {
  border: none;
  outline: none;
  color: #677C92;
}

.img-empty-tags {
  height: 64px;
  margin: 1rem 0;
}

.list-tags {
  max-height: 334px;
  overflow-y: scroll;
}

.card-footer-tags {
  background-color: #F0F4FA;
  border: none;
  border-radius: 0.375rem;
}
</style>