<template>
  <div
    class="offcanvas-body d-flex flex-column gap-1rem"
  >
    <action-component
      icon-class="fa-regular fa-arrow-right-arrow-left"
      icon-color="#3057F2"
      :name="$t('transfer_to_wait_queue')"
    />

    <div class="col-auto">
      <label
        class="form-label"
        for="groupChannel"
      >{{ $t('action_after_finalize') }}</label>
      <select
        id="groupChannel"
        v-model="groupChannel"
        class="form-select"
      >
        <option value="">
          {{ $t('select_group_channels') }}
        </option>
        <option
          v-for="groupChannelItem in groupChannels"
          :key="groupChannelItem.id"
          :value="groupChannelItem.id"
        >
          {{ groupChannelItem.name }}
        </option>
      </select>
    </div>
    <div class="mt-auto" />
    <app-button
      :disabled="!groupChannel"
      @click="createTransfer"
    >
      {{ $t('create') }}
    </app-button>
    <app-button
      color="btn-outline-secondary border-0"
      @click="$emit('return')"
    >
      {{ $t('back_capitalize') }}
    </app-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";
import {useVueFlow} from "@vue-flow/core";
const {  removeNodes } = useVueFlow( "chatbot" );
export default {
  name: "CreateTransfer",

  emits: ["return", "close"],

  components: {
    AppButton,
    ActionComponent
  },

  data() {
    return {
      groupChannel: "",
    }
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      prevActionId: "getPrevActionId",
      groupChannels: "getGroupChannels",
      selectedAction: "getSelectedAction",
    })
  },

  methods: {
    ...mapActions("chatbot/actions", ["updateAction"]),
    ...mapMutations("chatbot/actions", ["removeAction", "setSelectedAction","addNodesPosition"]),
    ...mapMutations("auth", ["trackEvents"]),

    createTransfer() {
      const body = {
        payload: {
          transfer_to_group_channel_id: this.groupChannel,
        },
        actionId: this.prevActionId
      }
      if(this.selectedAction) {
        this.removeAction(this.selectedAction.id)
        removeNodes(this.selectedAction.id)
        this.selectedAction = null
      }
      this.updateAction(body).then(() => {
        const newPosition = {
          id: `${this.prevActionId}-transfer`,
          position: {
            x: this.selectedAction?.position?.x || 0,
            y: this.selectedAction?.position?.y || 0
          }
        };

        this.addNodesPosition(newPosition);
        if (this.selectedAction) {
          this.setSelectedAction(null);
        }

        this.$emit("close")
        this.trackEvents("[Manager] Create Chatbot Transfer")
      })
    }
  }
}
</script>
<style scoped>
.gap-1rem {
  gap: 1rem;
}
</style>