export default {
  errors: {},

  firstMessagesAreLoaded: {
    inProgress: false,
  },

  firstMessagesLoad: {
    total: 25,
    loaded: 0
  },

  filters: {
    search: "",
    group_channel_id: null,
    team_id: null,
    agent_id: null,
    tags_ids: [],
  },

  currentChat: {},

  loadingFlags: {
    findAnchor: false,
    findChat: false,
    createNewChat: false,
    putOnSnooze: false,
    acceptQueue: false,
    sendForward: false,
    findGroupAgents: false,
    deleteGroup: false,
    addResponsibleGroup: false,
    fetchVerifyNotifiedChats: false,
    findProtocol: false,
    fetchInProgress: false,
    fetchGroups: false,
    fetchQueue: false,
    fetchInSnooze: false,
    fetchInChatbot: false,
    fetchFinished: false,
    reconnectWhatsapp: false,
  },

  chatChannelTypeSelected: {
    type: "ultramsg",
    id: null
  },

  // Queue
  queue: {},
  queuePagination: {
    per_page: 25,
    current_page: 1
  },

  // In Progress
  inProgress: {},
  inProgressPagination: {
    per_page: 25,
    current_page: 1
  },

  unreadConversations: 0,
  unreadGroupsConversations: 0,
  hasNotifiedInProgress: false,
  hasNotifiedQueue: false,

  // In Snooze
  inSnooze: {},
  inSnoozePagination: {
    per_page: 25,
    current_page: 1
  },
  // Groups
  groups: {},
  groupsPagination: {
    per_page: 25,
    current_page: 1
  },

  finished: {},
  finishedPagination: {
    per_page: 25,
    current_page: 1
  },

  inChatbot: {},
  inChatbotPagination: {
    per_page: 25,
    current_page: 1
  },

  groupParticipants: {},
  groupAgents: {},
  transferedByManager: false,
  snoozedByManager: false,
  finalizedByManager: false,
  openChat: null,
  removedFromTheGroup: false,
  emptyChatContact: null,
  lastVisitedWhatsAppRoute: null,
  talksTab: "my", // groups // queue // inChatbot // snooze // finished
  openTransferForm: false,

  clientResponseLate: {},
  clientResponseLateQueue: {},

  removeUnanswerMark: {},

  searchedMessages: [],
  searchedMessagesPagination: {
    total: 0,
    total_pages: 1,
    current_page: 1,
  },
  lastTermSearchedMessage: null,
  messageFound: null,
  protocolFound: null,
  loadMessagesContext: 'historic',
  allowedChatsToForward: null,
}
