<template>
  <div
    class="card m-0 border-0"
    v-if="text"
  >
    <div
      class="card-body d-flex align-items-center custom-padding"
      :class="{'card-background':!isChoiceItem}"
    >
      <div v-html="text.replace(/\n/g, '<br>')" />
    </div>
  </div>
  <div
    v-else
    class="d-flex justify-content-center align-items-center tx-text-gray add-text-container"
    type="button"
    @click="$emit('add-text')"
  >
    <p class="m-0">
      Adicionar texto
    </p>
  </div>
</template>

<script>
export default {
  name: "CardText",
  emits: ["add-text"],
  props: {
    text: {
      type: String,
      required: true,
    },
    isChoiceItem: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style scoped>
.custom-padding {
  padding: 8px 8px 8px 16px;
  border-radius: 10px;
  border: 1px solid  #E1E9F4;
}

.card-background {
  background: #F9FBFD;
}
.add-text-container {
  padding: 24px 8px;
  width: 318px;
  border-radius: 10px;
  border: 1px solid  #E1E9F4;
}
</style>
