<template>
  <div
    class="offcanvas-body d-flex flex-column gap-1rem"
  >
    <action-component
      icon-class="fa-regular fa-message"
      icon-color="#5CB868"
      :name="$t('send_a_message')"
    />

    <div class="col-auto">
      <label
        class="form-label"
        for="message"
      >{{ $t('message_text') }}</label>
      <textarea-with-count
        id="message"
        v-model="textContent"
        :placeholder="$t('what_is_your_message')"
        :max-length="255"
        :max-height="264"
      />
    </div>
    <div class="mt-auto" />
    <app-button
      :disabled="!textContent"
      @click="createMessage"
    >
      {{ $t('create') }}
    </app-button>
    <app-button
      color="btn-outline-secondary border-0"
      @click="$emit('return')"
    >
      {{ $t('back_capitalize') }}
    </app-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";
import TextareaWithCount from "@components/textarea-with-count.vue";

export default {
  name: "CreateMessage",

  emits: ["return", "close"],

  components: {
    TextareaWithCount,
    AppButton,
    ActionComponent
  },

  data() {
    return {
      textContent: ""
    }
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      prevActionId: "getPrevActionId",
      selectedAction: "getSelectedAction",
    })
  },

  methods: {
    ...mapActions("chatbot/actions", ["createAction"]),
    ...mapMutations("chatbot/actions", ["removeAction", "setSelectedAction","addNodesPosition"]),
    ...mapMutations("auth", ["trackEvents"]),

    createMessage() {
      const { id } = this.$route.params;
      const payload = {
        type: "question",
        need_chat_response: false,
        text_content: this.textContent,
        chatbot_id: id,
        previous_action_id: this.prevActionId || undefined
      };

      this.createAction(payload).then((newAction) => {
        this.textContent = "";

        const newPosition = {
          id: `${newAction.id}`,
          position: {
            x: this.selectedAction?.position?.x || 0,
            y: this.selectedAction?.position?.y || 0
          }
        };

        this.addNodesPosition(newPosition);

        if (this.selectedAction) {
          this.removeAction(this.selectedAction.id);
          this.setSelectedAction(null);
        }

        this.$emit("close");
        this.trackEvents("[Manager] Create Chatbot Message");
      });
    }

  }
}
</script>
<style scoped>
.gap-1rem {
  gap: 1rem;
}
</style>
