<template>
  <img
    data-fancybox
    :src="media"
    class="w-100 whatsapp-image"
    style="cursor: pointer"
    loading="lazy"
  >
  <div
    v-if="!text"
    class="w-100 col-12 d-flex has-no-caption"
  />
  <div
    class="pe-5 b8 b1 caption"
    v-if="text"
  >
    <div
      class="me-3"
      :class="{ 'text-decoration-line-through': isDeleted }"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: { type: String, required: true },
    media: { type: String, required: true },
    isDeleted: { type: Boolean, default: false },
    msg: { type: Object, default: null },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 360px !important;
  height: 202px !important;
  border-radius: ($whatsapp-border-radius - 4px) !important;
  object-fit: cover;
}
.has-no-caption{
  position: absolute;
  pointer-events: none;
  bottom: 3px;
  height: 100%;
  border-radius: ($whatsapp-border-radius - 4px) !important;
  width: 360px !important;
  height: 202px !important;
  object-fit: cover;
  background: linear-gradient(to top, rgba(11, 20, 26, 0.5), rgba(11, 20, 26, 0) 15%)
}
.caption {
  max-width: 360px !important;
}
</style>
