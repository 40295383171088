<template>
  <div
    class="card-header border-0"
    style="padding: 1.5rem; height: min-content"
  >
    <div class="d-flex align-items-center justify-content-between">
      <h4
        v-if="shouldShowNewActivityText"
        class="bold m-0"
      >
        {{ $t('new_activity') }}
      </h4>
      <div class="d-flex gap-2">
        <span
          v-for="activityOption in activityOptions"
          :key="activityOption.value"
        >
          <button
            v-if="!isModal || (isModal && activityOption.value !== 'internal-note')"
            :title="activityOption.label"
            :class="currentTab === activityOption.value ? 'btn-primary' : 'btn-outline-secondary'"
            :data-bs-title="activityOption.label"
            :disabled="disabled"
            class="btn"
            data-bs-placement="bottom"
            data-bs-toggle="tooltip"
            @click="selectActivity(activityOption.value)"
          >
            <i
              :class="getActivityIcon(activityOption.value)"
              class="fa-regular"
            />
            <span
              v-if="currentTab === activityOption.value"
              class="ms-2"
            >{{ activityOption.label }}</span>
          </button>
        </span>
      </div>
    </div>
  </div>
  <div
    v-if="!isModal"
    class="px-4"
  >
    <hr class="card-divider mt-0">
  </div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import {getActivityIcon} from "@crm/utills";

export default {
  name: "ActivityCardHeader",
  emits: ['currentTab'],
  props: {
    isModal: {
      type: Boolean,
      default: false
    },

    initialTab: {
      type: String,
      default: null
    },

    shouldShowNewActivityText: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentTab: null,
      activityOptions: [
        { value: "internal-note", label: this.$t("internal_note") },
        { value: "task", label: this.$t('task') },
        { value: "call", label: this.$t('call') },
        { value: "message", label: this.$t('message') },
        { value: "email", label: "E-mail" },
        { value: "meeting", label: this.$t('meeting') }
      ],
    }
  },

  mounted() {
    if (this.initialTab) {
      this.currentTab = this.initialTab;
    }
  },

  methods: {
    getActivityIcon,
    selectActivity(value) {
      this.currentTab = value;
      this.$emit('currentTab', value);
      if (this.$refs[`btn${value}`]) {
        const tooltipInstance = bootstrap.Tooltip.getInstance(this.$refs[`btn${value}`]);
        if (tooltipInstance) {
          tooltipInstance.hide();
        }
      }
    },
  },

  watch: {
    initialTab(data) {
      this.currentTab = data;
    }
  }
}
</script>
